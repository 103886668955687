import React, { useState, useEffect } from 'react';
import { Box, Select, MenuItem, Checkbox } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useGetUsersQuery, useGetUsersRolesQuery, usePutUserRoleValueMutation } from '../../services/ClientService';
import EmailIcon from '@mui/icons-material/Email';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import LockIcon from '@mui/icons-material/Lock';

const RoleDropdownCell = ({ params, roles, onUpdateRole }) => {
  const [selectedRole, setSelectedRole] = useState(params.value);

  const handleChange = async (event) => {
    const newRoleId = event.target.value;
    setSelectedRole(newRoleId);
    // Call the function to update the user's role
    await onUpdateRole(params.row.id, newRoleId);
  };

  return (
    <Select
      value={selectedRole || ''}
      onChange={handleChange}
      fullWidth
      variant="standard"
      displayEmpty
    >
      <MenuItem value="">
        <em>None</em>
      </MenuItem>
      {roles.map((role) => (
        <MenuItem key={role.id} value={role.id}>
          {role.name}
        </MenuItem>
      ))}
    </Select>
  );
};

const AdminCheckboxCell = ({ params, onUpdateUserRole }) => {
  const [isChecked, setIsChecked] = useState(false);

   // Effect to set the initial checked state based on userManagementRoleId
   useEffect(() => {
    if (params.row.userManagementRoleId !== undefined) {
      setIsChecked(params.row.userManagementRoleId === 1);
    }
  }, [params.row.userManagementRoleId]);

  const handleCheckboxChange = async (event) => {
    const checked = event.target.checked;
    const updatedUserManagementRoleId = checked ? 1 : 0;

    // Update the local state to re-render the checkbox
    setIsChecked(checked);

    // Create the updated user object
    const updatedUser = { ...params.row, userManagementRoleId: updatedUserManagementRoleId };
    console.log('Updated user:', updatedUser);

    // Call the API to update the user's role
    try {
      await onUpdateUserRole(updatedUser);
      console.log('User management role updated successfully.');
    } catch (error) {
      console.error('Error updating user management role:', error);
    }
  };

  return (
    <Checkbox
      checked={isChecked}
      onChange={handleCheckboxChange}
    />
  );
};

const UserGrid = () => {
  const { data: users, isLoading: isUsersLoading, error: usersError } = useGetUsersQuery();
  const { data: roles, isLoading: isRolesLoading, error: rolesError } = useGetUsersRolesQuery();
  const [putUserValue] = usePutUserRoleValueMutation();

  console.log('Users:', users);
  console.log('Roles:', roles);

  if (isUsersLoading || isRolesLoading) return <p>Loading...</p>;
  if (usersError || rolesError) return <p>Error loading data</p>;

  const handleUpdateRole = async (userId, roleId) => {
    try {
      roleId = roleId == null || roleId === "" ? 0 : roleId;
      console.log(`User ID ${userId} role updated to ${roleId}`);
      await putUserValue({ id: userId, roleId });
    } catch (error) {
      console.error('Error updating user role:', error);
    }
  };

  const handleUpdateUserRole = async (updatedUser) => {
    try {
      await putUserValue(updatedUser);
    } catch (error) {
      console.error('Error updating user management role:', error);
    }
  };

  const columns = [
    { field: 'firstName', headerName: 'First Name', width: 150 },
    { field: 'lastName', headerName: 'Last Name', width: 150 },
    {
      field: 'email',
      headerName: (
        <>
          <EmailIcon style={{ verticalAlign: 'middle', marginRight: 4 }} />
          Email
        </>
      ),
      width: 250,
    },
    {
      field: 'roleId',
      headerName: (
        <>
          <AssignmentIndIcon style={{ verticalAlign: 'middle', marginRight: 4 }} />
          Role
        </>
      ),
      width: 300,
      renderCell: (params) => (
        <RoleDropdownCell
          params={params}
          roles={roles || []}
          onUpdateRole={handleUpdateRole}
        />
      ),
    },
    {
      field: 'admin',
      align: 'center',
      headerName: (
        <>
          <LockIcon style={{ verticalAlign: 'middle', marginRight: 4 }} />
          Admin
        </>
      ),
      width: 100,
      renderCell: (params) => (
        <AdminCheckboxCell params={params} onUpdateUserRole={handleUpdateUserRole} />
      ),
    },
  ];

  return (
    <Box
      sx={{ width: '100%', maxWidth: "calc(100% - 60px)", margin: "37px 30px" }}
    >
      <h2>Users</h2>
      <div style={{ height: '50%', width: '80%', margin: "37px 0px" }}>
        <DataGrid
          rows={users || []}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          getRowId={(row) => row.id}
          sx={{
            backgroundColor: 'white', // Setting background color to white
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: 'white',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold', // Making header text bold
            },
          }}
        />
      </div>
    </Box>
  );
};

export default UserGrid;