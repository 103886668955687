import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import {clientAPI} from '../services/ClientService'
import error from './slices/ErrorSlice'
import filter from './slices/FilterSlice'



export const store = configureStore({
  reducer:{
    [clientAPI.reducerPath]: clientAPI.reducer,
    error,
    filter,
},

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(clientAPI.middleware),
});

setupListeners(store.dispatch);
