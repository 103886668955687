import React from "react";
import { Checkbox, FormControlLabel, FormGroup, Grid } from "@mui/material";
import InputField from "../../../common/InputField";
import DatePickerField from "../../../common/DatePickerField";
import SelectField from "../../../common/SelectFieldForForm";
import { Field, FastField, useField } from "formik";
import { AddressGroup } from "./ContactInfo.styled";
import { useGetAppReminderPrefQuery, useGetMunicipalityQuery } from "../../../../services/ClientService";
import useFetchWithMsal from "../../../../utils/useFetchWithMsal";
import stateNames from "../../../../constants/States";

export const ContactInfo = ({ fields }) => {
  const [field] = useField({ name: "address1" });
  const { value } = field;
  const { skip } = useFetchWithMsal();

  const { data: municipalityList } = useGetMunicipalityQuery({ skip });
  const { data: appReminderPrefList } = useGetAppReminderPrefQuery({ skip });

  const getSelectData = (name) => {
    switch (name) {
      case "municipality":
        return municipalityList;
      case "appReminderPref":
        return appReminderPrefList;
      case "state":
        return stateNames;
      default:
        return [];
    }
  };

  const getInput = (field, setValue, value, error) => {
    switch (field.type) {
      case "select":
        return (
          <Grid item xs={4} key={field.name}>
            <SelectField
            value={value}
            setValue={setValue}
            error={error}
              name={field.name}
              label={field.label}
              data={getSelectData(field.name)}
              fullWidth
            />
          </Grid>
        );
      case "date":
        return (
          <Grid item xs={4} key={field.name}>
            <DatePickerField
       value={value}
       setValue={setValue}
       error={error}
              name={field.name}
              label={field.label}
              maxDate={new Date()}
            />
          </Grid>
        );
      default:
        return (
          <Grid item xs={4} key={field.name}>
            <InputField name={field.name} label={field.label} value={value}
            setValue={setValue}
            error={error} fullWidth />
          </Grid>
        );
    }
  };

  return (
    <React.Fragment>
      <Grid container rowSpacing={"26px"} columnSpacing={"66px"}>
        {Object.values(fields).map((item, index) => {
          if (!index) {
            return (
              <Grid item xs={4} key={item.name}>
                <AddressGroup>
                  <div>
                    <FastField name={item.name}>
                      {({ field, form }) => (
                        <InputField
                          name={item.name}
                          label={item.label}
                          value={field.value}
                          setValue={form.setFieldValue}
                          error={form.errors[item.name]}
                          fullWidth
                        />
                      )}
                    </FastField>
                  </div>
                  <FormGroup sx={{ marginTop: "22px" }}>
                    <FormControlLabel
                      control={<Checkbox size="small" checked={!value} />}
                      label="Homeless"
                    />
                  </FormGroup>
                </AddressGroup>
              </Grid>
            );
          }
          return (
            <FastField name={item.name} key={item.name}>
              {({ field, form }) =>
                getInput(
                  item,
                  form.setFieldValue,
                  field.value,
                  form.errors[item.name]
                )
              }
            </FastField>
          );
        })}
      </Grid>
    </React.Fragment>
  );
};
