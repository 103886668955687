import { Box, IconButton, Typography, styled } from "@mui/material";

export const PopupContainer = styled(Box)(({ theme }) => ({
  width: "480px",
  height: "fit-content",
  margin: "auto",
  backgroundColor: "white",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));
export const Header = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  color: "#fff",
  padding: "20px 25px",
  backgroundColor: theme.palette.background.popupHeader,
}));
export const PopupButton = styled(IconButton)({
  minWidth: "24px",
  width: "24px",
  height: "24px",
  borderRadius: "50%",
  backgroundColor: "rgba(255, 255, 255, 0.5)",
  "& svg": {
    width: "12px",
    height: "12px",
  },
});
export const PopupBody = styled(Box)({
  display: "flex",
  height: "100%",
  "&:focus-visible ": {
    outline: "none",
  },
});
export const Client = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
}));

export const StatisticRowContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
});

export const DateContainer = styled(Box)({
  display: "flex",
  padding: "10px 20px",
  flexWrap: "wrap",
  maxWidth: "430px",
});
export const DateItem = styled(Box)({
  fontSize: "10px",
  fontWeight: "400",
  lineHeight: "14px",
  letterSpacing: "0em",
  textAlign: "left",
  color: "grey",
  padding: "3px 7px",
});
export const PopupFooter = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  padding: "15px 25px",
  alignItems: "center",
});
export const ClientName = styled(Box)({
  display: "flex",
  alignItems: "center",
  fontSize: "12px",
  fontWeight: 600,
  color: "#BD986A",
  cursor: "pointer",
  "& img": {
    marginLeft: "5px",
  },
});
export const CountContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  fontSize: "14px",
  fontWeight: 600,
  "& img": {
    marginLeft: "19px",
  },
  "& span": {
    width: "20px",
    textAlign: "center",
  },
});
