import React, { useState } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import { styled } from "@mui/system";
import {
  Badge,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Popover,
  Toolbar,
  Typography,
} from "@mui/material";
import SelectField from "../../../shared/components/SelectField";
import {
  useGetClientCounselorsQuery,
  useGetClientHousingsQuery,
  useGetClientStatusesQuery,
  useGetCompanyBranchesQuery,
  useGetTracksQuery,
} from "../../../services/UtilityService";
import { useDispatch, useSelector } from "react-redux";
import {
  setCounselor,
  setHousing,
  setStatus,
} from "../../../store/slices/FilterSlice";
import useFetchWithMsal from "../../../utils/useFetchWithMsal";

const StyledPopover = styled(Popover)({
  "& .MuiPaper-root": {
    padding: "24px",
    height: "263px",
    width: "260px",
    flexDirection: "column",
    display: "flex",
    justifyContent: "space-between",
  },
});
const StyledFormGroupContainer = styled(Box)({
  padding: "19px 17px",
  background: "rgba(237, 238, 241, 0.3)",
  height: "220px",
  overflow: "auto",
  fontFamily: "Open Sans",
  fontSize: "12px",
  fontWeight: 600,
});
const StyledTracksOptionContainer = styled(Box)({
  display: "flex",
  width: "150px",
  justifyContent: "space-between",
  marginBottom: "10px",
  "& .MuiTypography-root": {
    cursor: "pointer",
  },
});

const StyledButton = styled(Button)(({ theme, color, backgroundColor }) => ({
  //background: backgroundColor ? backgroundColor : '#659DBD',
  font: theme.typography.button.font,
  //color: color ? '#fff' : '#659DBD',
  cursor: "pointer",
  padding: "4px 15px",
  textTransform: "uppercase",
  marginRight: "5px",
  '&:hover': {
    backgroundColor: backgroundColor,
    opacity: 0.8
  },
  "& .MuiButtonBase-root-MuiButton-root": {
    color: color
  },
  'svg': {
    color: '#fff'
  }
}));
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    height: "15px",
    minWidth: "15px",
    padding: "0",
    right: "-16px",
    top: "7px",
  },
}));

export const TableFilter = (props) => {
  const { onApplyFilters, onResetFilters } = props;
  const { skip } = useFetchWithMsal();

  const { data: statues } = useGetClientStatusesQuery('', {skip});
  const { data: counselors } = useGetClientCounselorsQuery('', {skip});
  const { data: housings } = useGetClientHousingsQuery('', {skip});


  const dispatch = useDispatch();
  const { status, counselor, housing } = useSelector(
    (state) => state.filter
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [trackCount, setTrackCount] = useState(0);
  const open = Boolean(anchorEl);

  // const handleCheckboxChange = (item) => (event) => {
  //   if (event.target.checked) {
  //     setTrackCount(trackCount + 1);
  //     dispatch(setTracks([...tracks, item]));
  //   } else {
  //     setTrackCount(trackCount - 1);
  //     const filteredArray = tracks.filter((track) => track !== item);
  //     dispatch(setTracks(filteredArray));
  //   }
  // };
  // const onCheckAll = () => {
  //   const IDs = tracksData.map((track) => track.id);
  //   dispatch(setTracks(IDs));
  // };
  // const onUncheckAll = () => {
  //   dispatch(setTracks([]));
  // };
  const onFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const onStatusChange = (event) => {
    dispatch(setStatus(event.target.value));
  };
  const onHousingChange = (event) => {
    dispatch(setHousing(event.target.value));
  };
  const onCounselorChange = (event) => {
    dispatch(setCounselor(event.target.value));
  };
  const onReset = () => {
    dispatch(setStatus(""));
    dispatch(setHousing(""));
    dispatch(setCounselor([]));
    setAnchorEl(null);
    onResetFilters();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleApply = () => {
    onApplyFilters();
    setAnchorEl(null);
  };

  return (
    <Toolbar
    >
      <StyledButton
        variant="outlined"
        onClick={onFilterClick}
        startIcon={<FilterListIcon style={{ color: '#659DBD' }} />}
        color={status || housing || counselor ? "info" : "link"}
      >
        Filter
      </StyledButton>

      <StyledPopover
        id={"filter-popover"}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <SelectField
          name={"Status"}
          label={"Status"}
          data={statues}
          onChange={onStatusChange}
          value={status}
          labelType={"subtitle2"}
          fullWidth
        />
        <SelectField
          name={"Counselor"}
          label={"Counselor"}
          data={counselors}
          onChange={onCounselorChange}
          value={counselor}
          labelType={"subtitle2"}
          fullWidth
        />
        <SelectField
          name={"Housing"}
          label={"Housing"}
          data={housings}
          onChange={onHousingChange}
          value={housing}
          labelType={"subtitle2"}
          fullWidth
        />
        <Box sx={{ display: "flex" }}>
          <StyledButton onClick={handleApply} backgroundColor="#fff">
            Apply
          </StyledButton>
          <StyledButton onClick={onReset} backgroundColor="#fff">
            Reset
          </StyledButton>
        </Box>
      </StyledPopover>
    </Toolbar>
  );
};
