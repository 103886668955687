import Box from "@mui/material/Box";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import clientFormModel from "../../constants/ClientFormModel";
import formInitialValues from "../../constants/FormInitialValues";
import { ClientInfoForm } from "./ClientTabForm/ClientInfoForm";
import { AddressInfoForm } from "./ClientTabForm/AddressInfoForm";
import { ContactInfoForm } from "./ClientTabForm/ContactInfoForm";
import validationSchema from "../../constants/ValidationSchema";
import { ClientTabContainer, StepContainer } from "./CreateClientTab.styled";
import { usePostClientMutation } from "../../services/ClientService";
import ErrorAlert from "../../shared/components/ErrorAlert";
import { setError } from "../../store/slices/ErrorSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Accordion, AccordionDetails, AccordionSummary, Typography, styled, Button } from "@mui/material";


const StyledAccordion = styled(Accordion)(({ theme, isActive }) => ({
  borderLeft: `3px solid ${isActive ? theme.palette.background.active : theme.palette.background.menu}`,
  marginBottom: "8px",
}));

const steps = ["Client info", "Address info", "Contact info"];

export const CreateClientTab = () => {
  const [postClient, { error, isSuccess, data }] = usePostClientMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeSteps, setActiveStep] = useState([0]);
  const [expanded, setExpanded] = useState(0);

  const { formId, formField } = clientFormModel;

  const currentValidationSchema = validationSchema[expanded];

  useEffect(() => {
    dispatch(setError(error?.message));
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      navigate(`/client/${data}`);
    }
  }, [isSuccess]);

  const handleSubmit = (value, actions) => {
    if (expanded === steps.length - 1) {
      postClient(value);
    } else {
      setActiveStep([...activeSteps, expanded + 1]);
      setExpanded(expanded + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const handleCancel = () => {
    navigate("/home");
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return <ClientInfoForm formField={formField} />;
      case 1:
        return <AddressInfoForm formField={formField} />;
      case 2:
        return <ContactInfoForm formField={formField} />;
      default:
        return <div>Not Found</div>;
    }
  };
  const onAccordionClick = (panel) => (event, isExpanded) => {
    if (activeSteps.includes(panel)) {
      setExpanded(isExpanded ? panel : false);
    }
  };

  return (
    <ClientTabContainer>
      <>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <React.Fragment>
            <Formik
              initialValues={formInitialValues}
              validationSchema={currentValidationSchema}
              validateOnChange={true}  // Only validate when field changes
              validateOnBlur={true} 
              onSubmit={handleSubmit}
            >
              {() => (
                <Form id={formId}>
                  {steps.map((tab, index) => {
                    return (
                      <StyledAccordion
                        expanded={expanded === index}
                        id={`accordion-${index}`}
                        onChange={onAccordionClick(index)}
                        key={index}
                        isActive={activeSteps.includes(index)}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon color="primary.main" />}
                          aria-controls={`${index}-content`}
                          id={`${index}-header`}
                          sx={{
                            color: activeSteps.includes(index)
                              ? "background.active"
                              : "background.main",
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <StepContainer isExpanded={activeSteps.includes(index)}>
                              <Typography>{index + 1}</Typography>
                            </StepContainer>
                            <Typography variant="accordion">{tab}</Typography>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails sx={{ padding: '8px 30px 30px;' }}>
                          {renderStepContent(index)}
                        </AccordionDetails>
                      </StyledAccordion>
                    );
                  })}

                    {/* Buttons Container at Bottom Right */}
                <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                  <Button variant="outlined" color="primary" onClick={handleCancel}>
                    Cancel
                  </Button>
                </Box>
                </Form>
              )}
            </Formik>
          </React.Fragment>
        </Box>
      </>
      <ErrorAlert />
    </ClientTabContainer>
  );
};
