import Box from "@mui/material/Box";
import { FieldContainer, FieldTitle, FieldValue, Role, SignatureButton, SignatureContainer, SignatureControls, SignatureField, SignatureIcon, SignatureImage } from "./Signature.styled";
import penSvg from "../../../shared/icons/pen.svg";
import crossInCircleSvg from "../../../shared/icons/crossInCircle.svg";
import historySvg from "../../../shared/icons/history.svg";
import { useEffect, useState } from "react";
import moment from "moment";

export const SignatureItem = (props) => {
  const { self, info, userRoles, signatures, signatureInfo, getSignatureId, getUnsignId } = props;

  const [sign, setSign] = useState(false);

  const [signature, setSignature] = useState();


  const getRoleById = (roleId) => {
    const foundRole = userRoles?.find(role => role.id === roleId);

    return foundRole ? foundRole.name : null;
  }

//   const getSignatureById = () => {
  
//     const currentSignature = signatures.find(signature => signature.formRequiredSignatureId === info?.signature?.id);
  
//     return currentSignature || null;
//   }
  const setSignForm = (value) => {
    if (value === false) {
        if(signature) {
            setSign(value)
            getUnsignId(signatureInfo?.id)
            getSignatureId(false)
        } else {
            setSign(value)
        }
    } else {
        if(signature) {
            setSign(value)
            getUnsignId(false)
        } else {
            setSign(value)
        }
    }
  }

//   getUnsignForm()

  const getSignatureImage = () => {
    if(signature?.formRequiredSignatureId === signatureInfo?.id && sign) {
        return `data:image/png;base64,${signature?.base64Data}`
    } else if (signature?.formRequiredSignatureId !== signatureInfo?.id && sign) {
        return `data:image/png;base64,${info?.signature?.base64Data}`
    }

  }

  useEffect(() => {
    if (self && sign) {
        getSignatureId(signatureInfo?.id);
    }
  }, [self, sign]);

  useEffect(() => {
    if(signatures) {
        const currentSignature = signatures.find(signature => signature.formRequiredSignatureId === info?.signature?.id);
        if(currentSignature?.formRequiredSignatureId === signatureInfo?.id) {
            setSignature(currentSignature)
            setSign(true)
        }
    }
  }, [signatures, info]);

  return (
      <>
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "15px",
            marginBottom: "15px"
        }}>
            <Role>{getRoleById(signatureInfo.roleId)}</Role>
            <FieldContainer>
                <FieldTitle>
                    Name
                </FieldTitle>
                <FieldValue>
                    {self ? `${info?.firstName} ${info?.lastName}` : null}
                </FieldValue>
            </FieldContainer>
            <FieldContainer>
                <FieldTitle>
                    Effective  Date
                </FieldTitle>
                <FieldValue>
                    {signature ? moment(signature.dateOfSigning).format("MM/DD/YYYY") : ''}
                    {/* 07/10/2023 */}
                </FieldValue>
            </FieldContainer>
            <FieldContainer>
                <FieldTitle>
                    Signature
                </FieldTitle>
                <SignatureField sx={{background: self ? '#F8EFE7' : '#f8fafb'}}>
                <SignatureContainer>
                   {sign && <SignatureImage component="img" src={getSignatureImage()} alt=""/>}
                </SignatureContainer>
                {/* <img src={`data:image/png;base64,${info?.signature?.base64Data}`}/> */}
                {self && <SignatureControls>
                    <SignatureButton onClick={() => setSignForm(true)}>
                        <SignatureIcon component="img" src={penSvg} alt=""/>
                        Use Your Saved Signature
                    </SignatureButton>
                    <SignatureButton onClick={() => setSignForm(false)}>
                        <SignatureIcon component="img" src={crossInCircleSvg} alt=""/>
                        Clear
                    </SignatureButton>
                </SignatureControls>}
                </SignatureField>
            </FieldContainer>
            <FieldContainer>
                <FieldTitle>
                    <SignatureIcon component="img" src={historySvg} alt=""/>
                    Assignment History
                </FieldTitle>
            </FieldContainer>
        </Box>
      </>
  );
};
