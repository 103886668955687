const servicesCreationModel = {
  formId: 'servicesCreation',
  formField: {
    startDate: {
      name: 'startDate',
      label: 'Start Date',
      requiredErrorMsg: 'Start Date is required',
      type:'date'
    },
    endDate: {
      name: 'endDate',
      label: 'End Date',
      requiredErrorMsg: 'End Date is required',
      type:'date'
    },
    serviceId: {
      name: 'serviceId',
      label: 'Service Type',
      requiredErrorMsg: 'Service is required',
     type: 'select'
    },
    description: {
      name: 'description',
      label: 'Description',
    },
    clients: {
      name: 'clientId',
      label: 'Client',
      type: 'clientSelect',
      requiredErrorMsg: 'Client is required',
    },
    serviceGroupNameId: {
      name: 'serviceGroupNameId',
      label: 'Group',
      type: 'groupSelect'
    },
    serviceTopicId: {
      name: 'serviceTopicId',
      label: 'Topic',
      type: 'topicSelect'
    },
    serviceRoomId: {
      name: 'serviceRoomId',
      label: 'Room',
      type: 'roomSelect'
    },
    track: {
      name: 'trackId',
      label: 'Track',
      type: 'trackSelect',
    },
    note: {
      name: 'note',
      label: 'Note',
    },
  }
};
export default servicesCreationModel