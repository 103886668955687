import stateNames from "./States";

const clientInfoFormModel = [
  {
    company: {
      name: "company",
      label: "Company",
      validationType: null,
    },
    companyBranchId: {
      name: "companyBranchId",
      label: "Company Branch",
      type: "select",
      validationType: null,
    },
    profilePicture: {
      name: "profilePicture",
      label: "Profile photo",
      type: "img",
    },
    CheckIfDecead: {
      name: "CheckIfDecead",
      label: "Check if decead",
      type: "checkbox",
      validationType: null,
    },
    firstName: {
      name: "firstName",
      label: "Name*",
      requiredErrorMsg: "Name is required",
      validationType: "required",
    },
    middleName: {
      name: "middleName",
      label: "Middle",
      validationType: null,
    },
    lastName: {
      name: "lastName",
      label: "Last name*",
      requiredErrorMsg: "Last name is required",
      validationType: "required",
    },
    suffixName: {
      name: "suffixName",
      label: "Suffix",
      validationType: null,
    },
    previousName: {
      name: "previousName",
      label: "Previous Name",
      validationType: null,
    },
    maritalStatusId: {
      name: "maritalStatusId",
      label: "Marital status",
      validationType: null,
      type: "select",
    },
    maidenName: {
      name: "maidenName",
      label: "Maiden Name",
    },
    genderId: {
      name: "genderId",
      label: "Gender",
      validationType: null,
      type: "select",
    },
    genderDescription: {
      name: "genderDescription",
      label: "Other",
      validationType: null,
      pairField: "genderId",
      pairFieldOtherId: 3,
      type: "otherInput",
    },
    sexualOrientationId: {
      name: "sexualOrientationId",
      label: "Sexual Orientation",
      validationType: null,
      type: "select",
    },
    sexualOrientationDescription: {
      name: "sexualOrientationDescription",
      label: "Other",
      validationType: null,
      pairField: "sexualOrientationId",
      pairFieldOtherId: 4,
      type: "otherInput",
    },
    genderIdentityId: {
      name: "genderIdentityId",
      label: "Gender Identity",
      validationType: null,
      type: "select",
    },
    genderIdentityDescription: {
      name: "genderIdentityDescription",
      label: "Other",
      validationType: null,
      pairField: "genderIdentityId",
      pairFieldOtherId: 6,
      type: "otherInput",
    },
    preferredName: {
      name: "preferredName",
      label: "Preferred Name",
      validationType: null,
    },
    gangAffiliation: {
      name: "gangAffiliation",
      label: "Gang Affiliation",
      validationType: null,
    },
    primaryEthnicity: {
      name: "primaryEthnicityId",
      label: "Primary Ethnicity",
      validationType: null,
      type: "select",
    },
    secondaryEthnicity: {
      name: "secondaryEthnicityId",
      label: "Secondary Ethnicity",
      validationType: null,
      type: "select",
    },
    ssn: {
      name: "SSN",
      label: "SSN #",
      validationType: null,
    },
    taxId: {
      name: "taxId",
      label: "Tax ID",
      validationType: null,
    },
    sbi: {
      name: "sbi",
      label: "SBI #",
      validationType: null,
    },
    driversLicense: {
      name: "driverLicense",
      label: "Driver's License #",
      validationType: null,
    },
    account: {
      name: "account",
      label: "Account #",
      validationType: null,
    },
    lounds: {
      name: "lounds",
      label: "lounds",
      validationType: null,
    },
    clientID: {
      name: "clientId",
      label: "Client ID",
      validationType: null,
    },
    statusId: {
      name: "statusId",
      label: "Status",
      validationType: null,
      type: "select",
    },
    birthDate: {
      name: "birthDate",
      label: "Birth date*",
      requiredErrorMsg: "Birth date is required",
      invalidErrorMsg: "Birth date is not valid",
      validationType: "date",
      type: "date",
    },
    track: {
      name: "track",
      label: "Tracks",
      validationType: null,
      type: "selectCheckbox",
    },
    requestedService: {
      name: "requestedService",
      label: "Requested Services",
      validationType: null,
      type: "selectCheckbox",
    },
    hasLegalGuardian: {
      name: "hasLegalGuardian",
      label: "Has legal guardian",
      type: "checkbox",
      validationType: null,
    },
    treatmentSchedule: {
      name: "treatmentSchedule",
      label: "Treatment schedule",
      type: "textarea",
      validationType: null,
    },
    levelOfCare: {
      name: "levelOfCareId",
      label: "Level of care",
      type: "select",
      validationType: null,
    },
  },
  {
    address1: {
      name: "address1",
      label: "Address*",
      requiredErrorMsg: "Address is required",
      validationType: "required",
    },
    address2: {
      name: "address2",
      label: "Address 2",
    },
    city: {
      name: "city",
      label: "City*",
      requiredErrorMsg: "City is required",
      validationType: "required",
    },
    state: {
      name: "state",
      label: "State*",
      requiredErrorMsg: "State is required",
      type: "select",
      data: stateNames,
      validationType: "required",
    },
    zipCode: {
      name: "zipCode",
      label: "Zip code*",
      requiredErrorMsg: "Zip code is required",
      invalidErrorMsg: "Zip code is not valid (e.g. 70000)",
      validationType: "zipcode",
    },
    email: {
      name: "email",
      label: "Primary email",
      requiredErrorMsg: "Primary email is required",
      invalidErrorMsg: "Primary email is invalid (e.g. example@mail.com)",
      //validationType: "email",
    },
    secondaryEmail: {
      name: "secondaryEmail",
      label: "Secondary email",
      invalidErrorMsg: "Secondary email is invalid (e.g. example@mail.com)",
    },
    appReminderPref: {
      name: "appReminderPref",
      label: "App reminder pref",
      type: "select",
      validationType: null,
    },
    municipality: {
      name: "municipality",
      label: "Municipality",
      type: "select",
      validationType: null,
    },
    primaryPhone: {
      name: "primaryPhone",
      label: "Primary phone*",
      requiredErrorMsg: "Primary phone is required",
      invalidErrorMsg: "Primary phone is not valid",
      validationType: "phone",
    },
    cellPhone: {
      name: "cellPhone",
      label: "Cell phone",
      invalidErrorMsg: "Cell phone is not valid",
      validationType: null,
    },
    mobile: {
      name: "mobile",
      label: "Mobile",
      invalidErrorMsg: "Home phone is not valid",
      validationType: "phone",
      validationType: null,
    },
    fax: {
      name: "fax",
      label: "Fax",
    },
    workPhone: {
      name: "workPhone",
      label: "Work phone",
      invalidErrorMsg: "Work phone is not valid",
      validationType: null,
    },
  },
  {
   name: {
      name: "insurance.information.name",
      label: "Name *",
      requiredErrorMsg: "Medicaid # is required",
      //validationType:"required",
    },
   primaryPayerId: {
      name: "insurance.information.primaryPayerId",
      label: "Primary Payer*",
      //requiredErrorMsg: "Primary Payer is required",
      //validationType:"required",
      type: "select",
    },
    genderId: {
      name: "insurance.information.genderId",
      label: "Sex",
      type: "select",
    },
   
   relationshipToInsuredId: {
      name: "insurance.information.relationshipToInsuredId",
      label: "Relationship to Insured *",
      type: "select",
      //requiredErrorMsg: "Relationship to Insured is required",
      //validationType:"required",
    },
    relationshipToInsuredOther: {
      name: "insurance.information.relationshipToInsuredOther",
      label: "Other",
    },
    medicaidNumber: {
      name: "insurance.information.medicaidNumber",
      label: "Medicaid #",
      //validationType:"required",
    },
    insuredsIdNumber: {
      name: "insurance.information.insuredsIdNumber",
      label: "Insured's ID #",
    },
    insuredsGroup: {
      name: "insurance.information.insuredsGroup",
      label: "Insured's Group #",
    },
  planOrProgramName: {
      name: "insurance.information.planOrProgramName",
      label: "Plan or Program Name",
    },
    insuredsAddress: {
      name: "insurance.information.insuredsAddress",
      label: "Insured's Address",
    },
    insuredsCity: {
      name: "insurance.information.insuredsCity",
      label: "Insured's City",
    },
   insuredsState: {
      name: "insurance.information.insuredsState",
      label: "Insured's State",
    },
    zipCode: {
      name: "insurance.information.zipCode",
      label: "Zip Code",
    },
    insuredsPhone: {
      name: "insurance.information.insuredsPhone",
      label: "Insured's Phone",
    },
   insuredsMailingAddress: {
      name: "insurance.information.insuredsMailingAddress",
      label: "Insured's Mailing Address",
    },
    insuredsEmployer: {
      name: "insurance.information.insuredsEmployer",
      label: "Insured's Employer",
    },
   insuredDob: {
      name: "insurance.information.insuredDob",
      label: "Insured DOB",
      type: "date",
    },
    policyHolderName: {
      name: "insurance.information.policyHolderName",
      label: "Policy Holder Name, if not self",
    },
    policyHolderDob: {
      name: "insurance.information.policyHolderDob",
      label: "DOB",
      type: "date",
    },
    policyHolderSocialSecurityNumber: {
      name: "insurance.information.policyHolderSocialSecurityNumber",
      label: "Social Security #",
    },
   relationToPatientId: {
      name: "insurance.information.relationToPatientId",
      label: "Relation to Patient",
      type: "select",
    },
  },
  {
    medicationName: {
      name: "medicationName",
      label: "Medication Name",
      validationType: null,
    },
    medicationDose: {
      name: "medicationDose",
      label: "Medication Dose",
      validationType: null,
    },
    quantityPrescribed: {
      name: "quantityPrescribed",
      label: "Quantity Prescribed",
      validationType: null,
    },
    medicationEndDate: {
      name: "medicationEndDate",
      label: "Medication End Date",
      validationType: null,
      type: "date",
    },
    totalRefilsAllowed: {
      name: "totalRefilsAllowed",
      label: "Total Refils Allowed",
      validationType: null,
    },
    earliestRefilDate: {
      name: "earliestRefilDate",
      label: "Earliest Refil Date",
      validationType: null,
      type: "date",
    },
    refilsUntil: {
      name: "refilsUntil",
      label: "Refils Until",
      validationType: null,
      type: "date",
    },
    method: {
      name: "method",
      label: "Method (eg: Take 4)",
      validationType: null,
      type: "select",
    },
    quantity: {
      name: "quantity",
      label: "Quantity (eg: 4)",
      validationType: null,
    },
    doseType: {
      name: "doseType",
      label: "Dose Type (eg: by mouth)",
      validationType: null,
      type: "select",
    },
    frequency: {
      name: "frequency",
      label: "Frequency (eg: every dau after meal)",
      validationType: null,
      type: "select",
    },
    timeOfDayOrOtherDirections: {
      name: "timeOfDayOrOtherDirections",
      label: "Time Of Day Or Other Directions",
      validationType: null,
    },
    prescriber: {
      name: "prescriber",
      label: "Prescriber",
      validationType: null,
      type: "select",
    },
    prescriberPhone: {
      name: "prescriberPhone",
      label: "Prescriber Phone",
      validationType: null,
    },
    pharmacy: {
      name: "pharmacy",
      label: "Pharmacy",
      validationType: null,
    },
    pharmacyPhone: {
      name: "pharmacyPhone",
      label: "Pharmacy Phone",
      validationType: null,
    },
    medicalReasonReasonForOrder: {
      name: "medicalReasonReasonForOrder",
      label: "Medical Reason / Reason For Order",
      validationType: null,
    },
    prescriptionDate: {
      name: "prescriptionDate",
      label: "Prescription Date",
      validationType: null,
    },
    continueDiscontinue: {
      name: "continueDiscontinue",
      label: "C: Continue D: Discontinue",
      validationType: null,
      type: "select",
    },
    discontinueDate: {
      name: "discontinueDate",
      label: "Discontinue Date",
      validationType: null,
      type: "date",
    },
  },
];
export default clientInfoFormModel;
