import React from "react";
import { CreateClientTab } from "../../components/createClientTab/CreateClientTab";
import { Container } from "./CreateClient.styled";
import { Typography } from "@mui/material";

export const CreateClient = () => {
  return (
    <Container>
      <Typography variant="h2" sx={{marginBottom:'25px'}}>Create Client</Typography>
      <CreateClientTab />
    </Container>
  );
};
