import { useEffect, useState } from "react";
import crossSvg from "../../../shared/icons/cross.svg";
import blueSave from "../../../shared/icons/blueSave.svg";
import { AddNoteFormContainer, Button, ButtonImage, ButtonsBlock, FormTitle } from "./NotesPanel.styled";
import SelectField from "../../../shared/components/SelectField";
import { TextareaAutosize, styled } from "@mui/material";
import { useEditNoteMutation, useGetRequestSatatusQuery, usePostNoteMutation } from "../../../services/ClientService";
import useFetchWithMsal from "../../../utils/useFetchWithMsal";

const Textarea = styled(TextareaAutosize)(
    ({}) => `
    background: rgba(248, 239, 231, 1);
    padding: 5px;
    border: none;
    resize: none;
    width: 100%;
    border-radius: 4px;
    margin-top: 10px;
  `,
  );

export const AddNoteForm = (props) => {
  const {closeForm, types, clientId, editNoteInfo} = props;
  const { skip } = useFetchWithMsal();
  const [type, setType] = useState(null);
  const [status, setStatus] = useState(editNoteInfo?.requestStatus || 1);
  const [message, setMessage] = useState('');
  const [postNote, { error }] = usePostNoteMutation();
  const { data: requestStatuses } = useGetRequestSatatusQuery({ skip });
  const [editNote] = useEditNoteMutation();

  const findElementIndexById = (id) => {
    const index = types.findIndex(element => element.id === id);
    return index;
  }

  useEffect(() => {
    if(editNoteInfo){
      setMessage(editNoteInfo?.text)
    }
  }, [editNoteInfo]);

  useEffect(() => {
    if(types){
        setType(editNoteInfo ? findElementIndexById(editNoteInfo?.quickNoteTypeId)+1 : 1)
    }
  }, [types?.length]);

  const onTypeChange = (event) => {
    setType(event.target.value);
  };

  const onStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const onSubmit = () => {
    if(editNoteInfo) {
      editNote({
        data: {
          text: message,
          clientId: clientId,
          quickNoteTypeId: type,
          ownerId: editNoteInfo.ownerId,
          quickNoteRequestSatatusId: status || null 
        },
        noteId: editNoteInfo.id
      });
    } else {
      postNote({
        text: message,
        clientId: clientId,
        quickNoteTypeId: type,
        quickNoteRequestSatatusId: status || null
      });
    }
    closeForm(message, clientId, type);
  }

  return (
    <AddNoteFormContainer editNoteInfo={editNoteInfo}>
        {!editNoteInfo && <FormTitle>Add Note</FormTitle>}
        <SelectField
            name={"Type"}
            label={"Type"}
            data={types}
            onChange={onTypeChange}
            value={type}
            marginBottom="5px"
            fullWidth
        />
        {type === 6 && <SelectField
            name={"Status"}
            label={"Status"}
            data={requestStatuses}
            onChange={onStatusChange}
            value={status}
            fullWidth
        />}
        <Textarea
            minRows={4}
            type={"textarea"}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
        />
        <ButtonsBlock>
            <Button onClick={onSubmit}>
                <ButtonImage src={blueSave}/>
                Save
            </Button>
            <Button onClick={closeForm}>
                <ButtonImage src={crossSvg}/>
                Cancel
            </Button>
        </ButtonsBlock>
    </AddNoteFormContainer>
  );
}