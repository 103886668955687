import { useEffect, useState } from "react";
import {
  CloseButton,
  NotesPanelContainer,
  CardContainer,
  CardDate,
  CardHeader,
  CardInfo,
  CardName,
  CardStatus,
  CardText,
  CardsContainer,
  NotesHeader,
  PanelCardsSearchInputContainer,
  PanelCardsSearchInput,
  NotesToggle,
  ToggleOption,
  AddNoteIcon,
  AddNoteButton,
  CardFooter,
} from './NotesPanel.styled';
import crossSvg from "../../../shared/icons/cross.svg";
import addNote from "../../../shared/icons/addNote.svg";
import bluePen from "../../../shared/icons/bluePen.svg";
import blueTrash from "../../../shared/icons/blueTrash.svg";
import { Search } from "@mui/icons-material";
import { AddNoteForm } from "./AddNoteForm";
import { useDeleteNoteMutation, useGetNotesMutation, useGetNotesTypesQuery, useGetUserInfoQuery } from "../../../services/ClientService";
import { useParams } from "react-router-dom";
import useFetchWithMsal from "../../../utils/useFetchWithMsal";
import moment from "moment";
import { LoadingPanel } from "../../../shared/components/LoadingPanel";
import { EmptyMessage } from "../TableTab.styled";

export const NotesPanel = (props) => {
  const {userNotes, clientIdForm} = props
  const { id: clientIdParam } = useParams();
  const { skip } = useFetchWithMsal();
  const { setVisibility, type } = props;
  const [searchValue, setSearchValue] = useState('');
  const [notes, setNotes] = useState([]);
  const [toggleOption, setToggleOption] = useState('show-all');
  const [openAddNoteForm, setOpenAddNoteForm] = useState(false);
  const [editNoteInfo, setEditNoteInfo] = useState();
  
  const clientId = !userNotes ? clientIdParam : clientIdForm

  const [getNotes, { data, isLoading }] = useGetNotesMutation({
    clientId: clientId,
    recordNumber: 999,
    offset: 0 
  }, {skip})

  const { data: types } = useGetNotesTypesQuery({ skip });
  const { data: basicInfo } = useGetUserInfoQuery({ skip });
  const [deleteNote, error] = useDeleteNoteMutation();


  useEffect(() => {
      getNotes({
        clientId: clientId,
        recordNumber: 999,
        offset: 0,
        searchText: searchValue 
      })
  }, [clientId, searchValue]);

  useEffect(() => {
    setNotes(data?.quickNotes)
  }, [data]);

  const addNewElement = (message, clientId, type, ownerId) => {
    
    const newArray = [...notes];

    const newElement = {
        id: newArray.length + 1,
        text: message,
        createDate: new Date().toISOString(),
        clientId: clientId,
        quickNoteTypeId: type,
        ownerId: basicInfo?.id,
        owner: {
            firstName: basicInfo?.firstName,
            lastName: basicInfo?.lastName
        }
    };

    newArray.unshift(newElement);

    setNotes(newArray);
  }

  const removeNote = (id) => {
    
    const newArray = [...notes];
    const indexToRemove = newArray.findIndex(item => item.id === id);
    
    if (indexToRemove !== -1) {
        newArray.splice(indexToRemove, 1);
    }

    setNotes(newArray);
}


  const closeForm = (message, clientId, type, ownerId) => {
    if(editNoteInfo) {
      setEditNoteInfo(null)
    }
    if(message && clientId && type) {
      addNewElement(message, clientId, type, ownerId)
    }
    setOpenAddNoteForm(false)
  }

  const getNameById = id => {
    const foundItem = types?.find(item => item.id === id);

    return foundItem ? foundItem.name : null;
  }

  const setOpenEditNoteForm = (text, quickNoteTypeId, id, ownerId, quickNoteRequestSatatusId) => {
    setEditNoteInfo({
      text: text,
      quickNoteTypeId: quickNoteTypeId,
      id: id,
      ownerId: ownerId,
      requestStatus: quickNoteRequestSatatusId,
    })
  }

  const setDeleteNote = (ownerId, quickNoteTypeId, id) => {
    deleteNote({data: { quickNoteTypeId: quickNoteTypeId, ownerId: ownerId }, noteId: id});
    removeNote(id)
  }

  return (
      <NotesPanelContainer type={type} toggleOption={toggleOption}>
        {isLoading && <LoadingPanel/>}
        <NotesHeader>
          {toggleOption !== 'show-all' ? null : 
            <PanelCardsSearchInputContainer>
                <PanelCardsSearchInput
                  value={searchValue}
                  onChange={e => setSearchValue(e.target.value)}
                  placeholder='Search'
                />
                <Search/>
            </PanelCardsSearchInputContainer>
          }
            <NotesToggle>
              <ToggleOption
                onClick={() => setToggleOption('latest')}
                active={toggleOption === 'latest'}
              >
                Latest Notes & Alerts
              </ToggleOption>
              <ToggleOption
                onClick={() => setToggleOption('show-all')}
                active={toggleOption === 'show-all'}
              >
                Show All
              </ToggleOption>
            </NotesToggle>
            <CloseButton src={crossSvg} alt="" onClick={() => setVisibility(false)}/>
        </NotesHeader>
        {!openAddNoteForm && <AddNoteButton onClick={() => setOpenAddNoteForm(true)}>
          <AddNoteIcon src={addNote} alt=""/>
          Add Notes & Alerts
        </AddNoteButton>}
        {openAddNoteForm && <AddNoteForm closeForm={closeForm} types={types} clientId={clientId} editNoteInfo={editNoteInfo}/>}
        <CardsContainer>
        {notes?.length ? notes.map((note) => {
          return <CardContainer key={note.id} editNoteInfo={editNoteInfo?.id === note.id}>
          {editNoteInfo?.id !== note.id ?
          <>
            <CardHeader>
              <CardInfo>
                <CardName>{note?.owner?.firstName} {note?.owner?.lastName}</CardName>
                <CardDate>{moment(note?.createDate).format("MM/DD/YYYY HH:mm")}</CardDate>
              </CardInfo>
              <CardStatus>
                {getNameById(note.quickNoteTypeId)}
              </CardStatus>
            </CardHeader>
            <CardText>
              {note.text}
            </CardText>
            {(basicInfo?.id === note.ownerId && note.quickNoteTypeId !== 9) && <CardFooter
            >
              <AddNoteIcon src={bluePen} alt="" onClick={() => setOpenEditNoteForm(
                note?.text,
                note?.quickNoteTypeId,
                note?.id,
                note?.ownerId,
                note?.quickNoteRequestSatatusId
              )}/>
              <AddNoteIcon src={blueTrash} alt="" onClick={() => setDeleteNote(
                note?.ownerId,
                note?.quickNoteTypeId,
                note?.id,
              )}/>
            </CardFooter>}
          </> : <AddNoteForm closeForm={closeForm} types={types} clientId={clientId} editNoteInfo={editNoteInfo}/>}
        </CardContainer>
        }) : <EmptyMessage>No notes to show</EmptyMessage>}
        </CardsContainer>
      </NotesPanelContainer>
  );
}