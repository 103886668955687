import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import InputField from "../../../common/InputField";
import DatePickerField from "../../../common/DatePickerField";
import SelectField from "../../../common/SelectFieldForForm";
import CheckboxField from "../../../common/CheckboxField";
import {
  FlexContainer,
  GridContainer,
  Groups,
  Img,
  ImgContainer,
} from "./BasicInfo.styled";
import {
  useGetClientStatusesQuery,
  useGetCompanyBranchesQuery,
  useGetEthnicitiesQuery,
  useGetGenderIdentitiesQuery,
  useGetGendersQuery,
  useGetMaritalStatusesQuery,
  useGetRequestedServicesQuery,
  useGetSexualOrientationsQuery,
  useGetTracksQuery,
} from "../../../../services/UtilityService";
import SelectWithCheckbox from "../../../common/SelectWithCheckbox";
import { Field, FastField, useField, useFormikContext } from "formik";
import { useParams } from "react-router-dom";
import { useGetLevelOfCareQuery, useGetProfilePictureQuery } from "../../../../services/ClientService";
import useFetchWithMsal from "../../../../utils/useFetchWithMsal";

export const BasicInfo = ({ fields }) => {
  const { id } = useParams();
  const { skip } = useFetchWithMsal();

  const { data: status } = useGetClientStatusesQuery("", { skip });
  const { data: companyBranch } = useGetCompanyBranchesQuery("", { skip });
  const { data: tracks } = useGetTracksQuery("", { skip });
  const { data: genders } = useGetGendersQuery("", { skip });
  const { data: sexualOrientations } = useGetSexualOrientationsQuery("", {
    skip,
  });
  const { data: genderIdentities } = useGetGenderIdentitiesQuery("", { skip });
  const { data: ethnicities } = useGetEthnicitiesQuery("", { skip });
  const { data: maritals } = useGetMaritalStatusesQuery("", { skip });
  const { data: requestedServices } = useGetRequestedServicesQuery("", {
    skip,
  });

  const { data: profilePicture } = useGetProfilePictureQuery(id, { skip });
  const { data: levelOfCareList } = useGetLevelOfCareQuery({ skip });

  const [field] = useField({ name: "profilePicture" });
  const { value } = field;
  const { setFieldValue } = useFormikContext();
  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64String = e.target.result;
        setFieldValue("profilePicture", {
          base64Image: base64String.split(",")[1],
          mimeType: file.type,
        });
      };

      reader.readAsDataURL(file);
    }
  };

  const getSelectData = (name) => {
    switch (name) {
      case "statusId":
        return status;
      case "companyBranchId":
        return companyBranch;
      case "track":
        return tracks;
      case "genderId":
        return genders;
      case "sexualOrientationId":
        return sexualOrientations;
      case "genderIdentityId":
        return genderIdentities;
      case "primaryEthnicityId":
        return ethnicities;
      case "secondaryEthnicityId":
        return ethnicities;
      case "maritalStatusId":
        return maritals;
      case "requestedService":
        return requestedServices;
      case "levelOfCareId":
        return levelOfCareList;
      default:
        return [];
    }
  };

  const findGenderId = (values, field) => {
    if (values.hasOwnProperty(field)) {
      return values[field];
    }
  };

  const getInputComponent = (item, width, height) => {
    switch (item.type) {
      case "select":
        return (
          <Field name={item.name}>
            {({ field, form }) => (
              <SelectField
                name={item.name}
                label={item.label}
                data={getSelectData(item.name)}
                value={field.value}
                setValue={form.setFieldValue}
                error={form.errors[item.name]}
                fullWidth
              />
            )}
          </Field>
        );
      case "selectCheckbox":
        return (
          <Field name={item.name}>
            {({ field, form }) => (
              <SelectWithCheckbox
                name={item.name}
                label={item.label}
                data={getSelectData(item.name)}
                value={field.value}
                setValue={form.setFieldValue}
                error={form.errors[item.name]}
                fullWidth
              />
            )}
          </Field>
        );
      case "date":
        return (
          <FastField name={item.name}>
            {({ field, form }) => (
              <DatePickerField
                name={item.name}
                label={item.label}
                value={field.value}
                setValue={form.setFieldValue}
                error={form.errors[item.name]}
                maxDate={new Date()}
              />
            )}
          </FastField>
        );
      case "checkbox":
        return (
          <FastField name={item.name}>
            {({ field, form }) => (
              <CheckboxField
                name={item.name}
                label={item.label}
                value={field.value}
                setValue={form.setFieldValue}
                error={form.errors[item.name]}
              />
            )}
          </FastField>
        );
      case "img":
        return;
      case "otherInput":
        return (
          <FastField name={item.name}>
            {({ field, form }) => (
              <InputField
                name={item.name}
                label={item.label}
                value={
                  findGenderId(form.values, item.pairField) ===
                  item.pairFieldOtherId
                    ? field.value
                    : ""
                }
                setValue={form.setFieldValue}
                error={form.errors[item.name]}
                fullWidth
                width={width}
                height={height}
                disabled={
                  findGenderId(form.values, item.pairField) !==
                  item.pairFieldOtherId
                }
              />
            )}
          </FastField>
        );
      default:
        return (
          <FastField name={item.name}>
            {({ field, form }) => (
              <InputField
                name={item.name}
                label={item.label}
                value={field.value || ""}
                setValue={form.setFieldValue}
                error={form.errors[item.name]}
                fullWidth
                width={width}
                height={height}
              />
            )}
          </FastField>
        );
    }
  };
  return (
    <React.Fragment>
      <Grid container rowSpacing={"20px"} columnSpacing={"20px"}>
        <Grid item xs={4}>
          <Box>
            <Box mb={"20px"}>{getInputComponent(fields.company)}</Box>
            {getInputComponent(fields.companyBranchId)}
          </Box>
        </Grid>

        <Grid item xs={4}>
          <GridContainer gap={"20px"} template={"207px 60px"} mb={"20px"}>
            <Box>{getInputComponent(fields.firstName, "207px")}</Box>
            <div>{getInputComponent(fields.middleName, "60px")}</div>
          </GridContainer>
          <GridContainer gap={"20px"} template={"207px 60px"}>
            <div>{getInputComponent(fields.lastName, "207px")}</div>
            <div>{getInputComponent(fields.suffixName, "60px")}</div>
          </GridContainer>
        </Grid>
        <Grid item xs={4}>
          <div>
            <Box mb={"10px"}>
              <Typography>Profile photo</Typography>
            </Box>
            <FlexContainer>
              <ImgContainer>
                <Img
                  alt="clientPhoto"
                  src={
                    value
                      ? `data:image/jpeg;base64,${value.base64Image}`
                      : profilePicture
                  }
                />
              </ImgContainer>
              <Button component="label" variant="outlined" size="small">
                Choose file
                <input
                  type="file"
                  hidden
                  onChange={handleImageUpload}
                  name={"profilePicture"}
                />
              </Button>
            </FlexContainer>
          </div>
        </Grid>
        {/* <Grid item xs={4}>
          {getInputComponent(fields.CheckIfDecead)}
        </Grid> */}
        <Grid item xs={12}>
          <Box
            sx={{
              borderBottom: "1px solid",
              borderColor: "primary.main",
              margin: "10px 0",
            }}
          ></Box>
        </Grid>
        <Grid item xs={4}>
          {getInputComponent(fields.preferredName)}
        </Grid>
        <Grid item xs={4}>
          {getInputComponent(fields.maritalStatusId)}
        </Grid>
        <Grid item xs={4}>
          {getInputComponent(fields.primaryEthnicity)}
        </Grid>
        <Grid item xs={4}>
          {getInputComponent(fields.previousName)}
        </Grid>
        <Grid item xs={4}>
          {getInputComponent(fields.birthDate)}
        </Grid>
        <Grid item xs={4}>
          {getInputComponent(fields.secondaryEthnicity)}
        </Grid>
        <Grid item xs={4}>
          {getInputComponent(fields.maidenName)}
        </Grid>
        <Grid item xs={4}>
          {getInputComponent(fields.gangAffiliation)}
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              borderBottom: "1px solid",
              borderColor: "primary.main",
              margin: "10px 0",
            }}
          ></Box>
        </Grid>
        <Grid item xs={4}>
          <Groups>
            {getInputComponent(fields.genderId)}

            <Box> {getInputComponent(fields.genderDescription)}</Box>
          </Groups>
        </Grid>
        <Grid item xs={4}>
          <Groups>
            {getInputComponent(fields.sexualOrientationId)}
            <Box>{getInputComponent(fields.sexualOrientationDescription)}</Box>
          </Groups>
        </Grid>
        <Grid item xs={4}>
          <Groups>
            {getInputComponent(fields.genderIdentityId)}
            <Box>{getInputComponent(fields.genderIdentityDescription)}</Box>
          </Groups>
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={{
              borderBottom: "1px solid",
              borderColor: "primary.main",
              margin: "10px 0",
            }}
          ></Box>
        </Grid>

        <Grid item xs={4}>
          {getInputComponent(fields.ssn)}
        </Grid>
        <Grid item xs={4}>
          {getInputComponent(fields.driversLicense)}
        </Grid>
        <Grid item xs={4}>
          {getInputComponent(fields.lounds)}
        </Grid>
        <Grid item xs={4}>
          {getInputComponent(fields.taxId)}
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={{
              borderBottom: "1px solid",
              borderColor: "primary.main",
              margin: "10px 0",
            }}
          ></Box>
        </Grid>

        <Grid item xs={4}>
          <Grid gap={"20px"} container>
            <Box width={"100%"}>{getInputComponent(fields.statusId)}</Box>
            <Box width={"100%"}>{getInputComponent(fields.track)}</Box>
            <Box width={"100%"}>
              {getInputComponent(fields.requestedService)}
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid gap={"20px"} container>
            <Box width={"100%"}>{getInputComponent(fields.sbi)}</Box>
            <Box width={"100%"}>{getInputComponent(fields.account)}</Box>
            <Box width={"100%"}>{getInputComponent(fields.clientID)}</Box>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Box width={"100%"}>{getInputComponent(fields.levelOfCare)}</Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
