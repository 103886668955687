import { Typography } from "@mui/material";
import React from "react";

const HelpTextField = () => {
 
  return (
    <Typography variant="body1">
      <ol>
        <li>Index numbers each Progress Note to correspond with most recent problems/goal/intervention strategy.</li>
        <ol>
          <li>=Health/Drug Use</li>
          <li>=Legal</li>
          <li>=Employment/Vocational</li>
          <li>=Educational</li>
          <li>=Psychosocial</li>
        </ol>
        <li>Write Progress Notes in problem-oriented format:</li>
        <ul>
          <li>D=Data</li>
          <li>A=Assessment</li>
          <li> P=Plan</li>
        </ul>
        <li>New problems, goals, and strategies should be index New problems, goals, and strategies should be index Plan, including date identified.</li>
        <li>Date and time all Progress Notes. Signature must be present and should include discipline.</li>
        <li>Progress Note should reflect type of service, e.g., individual, group, office, telephone, case review, consultation, etc.</li>
      </ol>
    </Typography>
  );
};

export default HelpTextField;
