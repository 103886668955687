import React, { useEffect, useState } from "react";
import { Button, Checkbox, FormControlLabel, Grid, InputLabel, TextField, Typography } from "@mui/material";
import InputField from "../../../common/InputField";
import DatePickerField from "../../../common/DatePickerField";
import SelectField from "../../../common/SelectFieldForForm";
import moment from "moment";


import {
  useGetGenderQuery,
  useGetPrimaryPayerQuery,
  useGetRelationToPatientQuery,
  useGetRelationshipToInsuredQuery,
} from "../../../../services/UtilityService";
import { Field, FastField, useField, useFormikContext } from "formik";
import { useParams } from "react-router-dom";
import useFetchWithMsal from "../../../../utils/useFetchWithMsal";

export const Insurance = ({ fields }) => {
  const { id } = useParams();
  const { skip } = useFetchWithMsal();
  const [isChecked, setIsChecked] = useState(false);

  const { data: relationToPatient } = useGetRelationToPatientQuery("", {
    skip,
  });
  const { data: relationshipToInsured } = useGetRelationshipToInsuredQuery("", {
    skip,
  });
  const { data: gender } = useGetGenderQuery("", {
    skip,
  });
  const { data: primaryPayer } = useGetPrimaryPayerQuery(id, { skip });

  const { values, setFieldValue } = useFormikContext();

  const handleCheckboxChange = (event) => {
    const checked = event.target.checked;
    setIsChecked(checked);

    if (checked) {
      // Copy basic info fields to insurance fields when checked
      setFieldValue("insurance.information.insuredsAddress", values.address1 || "");
      setFieldValue("insurance.information.insuredsCity", values.city || "");
      setFieldValue("insurance.information.insuredsState", values.state || "");
      setFieldValue("insurance.information.zipCode", values.zipCode || "");
      setFieldValue("insurance.information.insuredsPhone", values.primaryPhone || "");
      setFieldValue("insurance.information.insuredDob", values.birthDate);
    } else {
      // Optionally clear insurance fields if unchecked
      setFieldValue("insurance.information.insuredsAddress", "");
      setFieldValue("insurance.information.insuredsCity", "");
      setFieldValue("insurance.information.insuredsState", "");
      setFieldValue("insurance.information.zipCode", "");
      setFieldValue("insurance.information.insuredsPhone", "");
      setFieldValue("insurance.information.insuredDob", null);
    }
  };

  useEffect(() => {
    if (isChecked) {
      // Keep values in sync as long as the checkbox is checked
      setFieldValue("insurance.information.insuredsAddress", values.address1 || "");
      setFieldValue("insurance.information.insuredsCity", values.city || "");
      setFieldValue("insurance.information.insuredsState", values.state || "");
      setFieldValue("insurance.information.zipCode", values.zipCode || "");
      setFieldValue("insurance.information.insuredsPhone", values.primaryPhone || "");
      setFieldValue("insurance.information.insuredDob", values.birthDate);
    }
  }, [
    isChecked,
    values.address1,
    values.city,
    values.state,
    values.zipCode,
    values.primaryPhone,
    values.birthDate,
    setFieldValue,
  ]);

  const getSelectData = (name) => {
    switch (name) {
      case "insurance.information.relationToPatientId":
        return relationToPatient;
      case "insurance.information.relationshipToInsuredId":
        return relationshipToInsured;
      case "insurance.information.genderId":
        return gender;
      default:
        return primaryPayer;
    }
  };

  const PayerIdItem = React.memo(() => {
    const [field] = useField({ name: "insurance.information.primaryPayerId" });
    const { value } = field;

    const getValue = () => {
      const payerId = primaryPayer?.find((item) => item.id === value)?.payerId;

      return payerId;
    };

    return (
      <Grid item xs={4}>
        <InputLabel htmlFor="input" sx={{ height: "14px" }}>
          Payer ID
        </InputLabel>
        <TextField
          type={"text"}
          size="small"
          value={getValue() || ""}
          disabled
          style={{width:'100%'}}
          InputProps={{
            style: { height: "30px", maxWidth: "290px", width:'100%' },
          }}
        />
      </Grid>
    );
  });

  const RelationshipToInsuredIdItem = React.memo(
    ({ field, setValue, value, error }) => {
      const setData = (name, value) => {
        setValue(name, value);
        if (value !== 2) {
          setValue("insurance.information.relationshipToInsuredOther", "");
        }
      };
      return (
        <Grid item xs={4} key={field.name}>
          <SelectField
            value={value || ''}
            setValue={setData}
            error={error}
            name={field.name}
            label={field.label}
            data={getSelectData(field.name)}
            fullWidth
          />
        </Grid>
      );
    }
  );

  const getInput = (field, setValue, value, error, id) => {
    switch (field.type) {
      case "select":
        return (
          <Grid item xs={4} key={field.name}>
            <SelectField
              value={value}
              setValue={setValue}
              error={error}
              name={field.name}
              label={field.label}
              data={getSelectData(field.name)}
              fullWidth
            />
          </Grid>
        );
      case "date":
        return (
          <Grid item xs={4} key={field.name}>
            <DatePickerField
              value={value}
              setValue={setValue}
              error={error}
              name={field.name}
              label={field.label}
              maxDate={new Date()}
            />
          </Grid>
        );
      default:
        return (
          <Grid
            item
            xs={4}
            key={field.name}
          >
            <InputField
              name={field.name}
              label={field.label}
              value={value || ""}
              setValue={setValue}
              error={error}
              disabled={id && id !== 2}
              fullWidth
            />
          </Grid>
        );
    }
  };

  return (
    <React.Fragment>
      <Grid container rowSpacing={"26px"} columnSpacing={"66px"}>
        {Object.values(fields)
          .slice(0, 3)
          .map((item, index) => {
            return (
              <Field name={item.name} key={item.name}>
                {({ field, form }) => {
                  return getInput(
                    item,
                    form.setFieldValue,
                    field.value,
                    form.errors[item.name]
                  );
                }}
              </Field>
            );
          })}
        <PayerIdItem />
        <Field
          name={fields.relationshipToInsuredId.name}
          key={fields.relationshipToInsuredId.name}
        >
          {({ field, form }) => {
            return (
              <RelationshipToInsuredIdItem
                field={fields.relationshipToInsuredId}
                value={field.value}
                setValue={form.setFieldValue}
                error={form.errors[fields.relationshipToInsuredId.name]}
              />
            );
          }}
        </Field>
        <FastField
          name={fields.relationshipToInsuredOther.name}
          key={fields.relationshipToInsuredOther.name}
        >
          {({ field, form }) => {
            return getInput(
              fields.relationshipToInsuredOther,
              form.setFieldValue,
              field.value,
              form.errors[fields.relationshipToInsuredOther.name],
              form.values.insurance.information.relationshipToInsuredId
            );
          }}
        </FastField>
        {Object.values(fields)
          .slice(5, 9)
          .map((item, index) => {
            return (
              <FastField name={item.name} key={item.name}>
                {({ field, form }) => {
                  return getInput(
                    item,
                    form.setFieldValue,
                    field.value,
                    form.errors[item.name]
                  );
                }}
              </FastField>
            );
          })}
         <Grid item xs={12}>
          <Typography variant="subtitle2" sx={{ marginY: "10px" }}>
            Insured Information
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={isChecked}
                onChange={handleCheckboxChange}
                color="primary"
              />
            }
            label="Copy Basic Info to Insurance Fields"
          />
        </Grid>

        {Object.values(fields)
          .slice(10, -4)
          .map((item, index) => {
            return (
              <FastField name={item.name} key={item.name}>
                {({ field, form }) => {
                  return getInput(
                    item,
                    form.setFieldValue,
                    field.value,
                    form.errors[item.name]
                  );
                }}
              </FastField>
            );
          })}

        <Grid item xs={12}>
          <Typography variant="subtitle2" sx={{ marginY: "10px" }}>
            Policy Holder Information
          </Typography>
        </Grid>

        {Object.values(fields)
          .slice(-4)
          .map((item, index) => {
            return (
              <Field name={item.name} key={item.name}>
                {({ field, form }) => {
                  return getInput(
                    item,
                    form.setFieldValue,
                    field.value,
                    form.errors[item.name]
                  );
                }}
              </Field>
            );
          })}
      </Grid>
    </React.Fragment>
  );
};
