import React, { useState, useEffect } from 'react';
import { Box, Select, MenuItem, FormControl, InputLabel, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useGetUsersQuery, useGetUsersRolesQuery, usePutUserRoleValueMutation } from '../../services/ClientService';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const ScheduleCell = ({ params, onSaveSchedule }) => {
    const [schedule, setSchedule] = useState({
        Monday: { startTime: dayjs(), endTime: dayjs() },
        Tuesday: { startTime: dayjs(), endTime: dayjs() },
        Wednesday: { startTime: dayjs(), endTime: dayjs() },
        Thursday: { startTime: dayjs(), endTime: dayjs() },
        Friday: { startTime: dayjs(), endTime: dayjs() },
        Saturday: { startTime: dayjs(), endTime: dayjs() },
        Sunday: { startTime: dayjs(), endTime: dayjs() },
    });

    const handleTimeChange = (day, type, newValue) => {
        setSchedule((prevSchedule) => ({
            ...prevSchedule,
            [day]: {
                ...prevSchedule[day],
                [type]: newValue,
            },
        }));
    };

    return (
        <Box sx={{ display: 'flex', gap: 1.5 }}>
            {Object.keys(schedule).map((day) => (
                <Box
                    key={day}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 0.2,
                        padding: 0.5,
                    }}
                >
                    <span style={{ marginBottom: 4, fontWeight: 500 }}>{day}</span>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                            label="Start"
                            value={schedule[day].startTime}
                            onChange={(newValue) => handleTimeChange(day, 'startTime', newValue)}
                            renderInput={(params) => <TextField {...params} sx={{ width: 80 }} />}
                        />
                        <TimePicker
                            label="End"
                            value={schedule[day].endTime}
                            onChange={(newValue) => handleTimeChange(day, 'endTime', newValue)}
                            renderInput={(params) => <TextField {...params} sx={{ width: 80 }} />}
                        />
                    </LocalizationProvider>
                </Box>
            ))}
        </Box>
    );
};

const UserWorkScheduleGrid = () => {
    const { data: users, isLoading: isUsersLoading, error: usersError } = useGetUsersQuery();
    const { data: roles, isLoading: isRolesLoading, error: rolesError } = useGetUsersRolesQuery();
    const [putUserValue] = usePutUserRoleValueMutation();
    const [selectedUser, setSelectedUser] = useState('');
    const [paginationModel, setPaginationModel] = useState({ pageSize: 5, page: 0 });
    const rowHeight = 200;

    if (isUsersLoading || isRolesLoading) return <p>Loading...</p>;
    if (usersError || rolesError) return <p>Error loading data</p>;

    const handleSaveSchedule = (userId, schedule) => {
        console.log(`Schedule saved for user ${userId}:`, schedule);
        // Here, you would call an API to save the schedule for the user
    };

    const filteredUsers = selectedUser === 'all'
        ? users
        : selectedUser
            ? users.filter((user) => user.id === selectedUser)
            : users;

    const columns = [
        {
            field: 'userInfo',
            headerName: 'User Information',
            width: 300,
            renderCell: (params) => {
                const user = params.row;
                const roleName = roles.find((role) => role.id === user.roleId)?.name || 'No Role';
                return (
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <span style={{ fontWeight: 'bold' }}>
                            {user.firstName} {user.lastName}
                        </span>
                        <span>{roleName}</span>
                    </Box>
                );
            },
        },
        {
            field: 'schedule',
            headerName: 'Work Schedule',
            width: 1000,
            renderCell: (params) => (
                <ScheduleCell params={params} onSaveSchedule={handleSaveSchedule} />
            ),
        },
    ];

    return (
        <Box
            sx={{ width: '100%', maxWidth: "calc(100% - 60px)", margin: "37px 30px", height: '100%' }}
        >
            <h2>Users</h2>
            <FormControl sx={{ mb: 1, width: 300, paddingTop: 5 }}>
                {/* Set width of select to be much smaller than the data grid */}
                <InputLabel id="user-filter-label">Filter by User</InputLabel>
                <Select
                    labelId="user-filter-label"
                    value={selectedUser}
                    onChange={(e) => setSelectedUser(e.target.value)}
                >
                    <MenuItem value="all"><em>Select All</em></MenuItem>
                    {users?.map((user) => (
                        <MenuItem key={user.id} value={user.id}>{user.firstName} {user.lastName}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Box
                sx={{
                    flexGrow: 1,
                    width: '100%',
                    margin: "37px 0px",
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <DataGrid
                    autoHeight
                    rows={filteredUsers || []}
                    columns={columns}
                    pageSize={paginationModel.pageSize}
                    page={paginationModel.page}
                    pagination
                    paginationModel={paginationModel}
                    onPaginationModelChange={(newPaginationModel) => setPaginationModel(newPaginationModel)}
                    rowsPerPageOptions={[5, 10, 20]}
                    getRowId={(row) => row.id}
                    getRowHeight={() => rowHeight}
                    sx={{
                        backgroundColor: 'white',
                        '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: 'white',
                        },
                        '& .MuiDataGrid-columnHeaderTitle': {
                            fontWeight: 'bold',
                        },
                        '& .MuiDataGrid-cell': {
                            padding: '10px',
                            lineHeight: '1.5',
                        },
                    }}
                />
            </Box>
        </Box>
    );
};

export default UserWorkScheduleGrid;
