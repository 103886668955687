import checkoutFormModel from './ClientFormModel';
import * as Yup from 'yup';
const {
  formField: {
    firstName,
    lastName,
    birthDate,
    address1,
    city,
    state,
    zipcode,
    primaryPhone,
    email
  }
} = checkoutFormModel;

//eslint-disable-next-line
const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const phoneRegEx = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/

const validationSchema =[
  Yup.object().shape({
    [firstName.name]: Yup.string().required(`${firstName.requiredErrorMsg}`),
    [lastName.name]: Yup.string().required(`${lastName.requiredErrorMsg}`),
    [birthDate.name]: Yup.string()
      .nullable()
      .required(`${birthDate.requiredErrorMsg}`)
      .test('birthDate', birthDate.invalidErrorMsg, val => {
        if (val) {
          return !isNaN(new Date(val))
        }
        return false;
      }),
      
  }),
  Yup.object().shape({
    
    [address1.name]: Yup.string().required(`${address1.requiredErrorMsg}`),
    [city.name]: Yup.string()
      .nullable()
      .required(`${city.requiredErrorMsg}`),
    [zipcode.name]: Yup.string()
      .required(`${zipcode.requiredErrorMsg}`)
      .test(
        'len',
        `${zipcode.invalidErrorMsg}`,
        val => val && val.length === 5
      ),
    [state.name]: Yup.string()
      .nullable()
      .required(`${state.requiredErrorMsg}`)
  }),
  Yup.object().shape({
    [primaryPhone.name]: Yup.string()
    .matches(phoneRegEx, `${primaryPhone.invalidErrorMsg}`) // Format: NPA-NXX-XXXX
    .required(`${primaryPhone.requiredErrorMsg}`),
    [email.name]: Yup.string()
    //.nullable() // Email is optional
    //.notRequired() // Email is not required, can be empty
/*     .test(
      'is-valid-email', // Custom test name
      `${email.invalidErrorMsg}`, // Error message
      function (value) {
        if (!value || value.trim() === '') {
          // If the value is null or empty string, don't validate
          return true;
        }
        // Only validate if there's a value
        return emailRegEx.test(value);
      }
    ), */
    //.required(`${email.requiredErrorMsg}`),
  })
];

export default validationSchema
