import Box from "@mui/material/Box";
import { useFetchClientQuery, useGetUsersQuery, useGetUsersRolesQuery, usePostClientMutation } from "../../../services/ClientService";
import useFetchWithMsal from "../../../utils/useFetchWithMsal";
import { useEffect, useState } from "react";
import SelectField from "../../../shared/components/SelectField";
import { AssignmentRow } from "./AssignmentRow";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";
import addAssignment from "../../../shared/icons/addAssignment.svg";

export const Assignments = (props) => {
  const { getAssignments } = props;
  const { skip } = useFetchWithMsal();
  const [assignmentsList, setAssignmentsList] = useState([]);
  const [openAssignmentHistoryId, setOpenAssignmentHistoryId] = useState(null);
  const { id } = useParams();
  const { data: clientInfo } = useFetchClientQuery(id, { skip });
  const { data: userRoles, isLoading } = useGetUsersRolesQuery({ skip });
  const { data: users, isLoading: rolesLoading } = useGetUsersQuery(userRoles ? userRoles[0].id : null, { skip });
  const [postClient, { error }] = usePostClientMutation();

  const removeTemporaryIds = (arr) => {
    const assignmentsCopy = JSON.parse(JSON.stringify(arr))
    return assignmentsCopy.map(item => {
        if (item.id && (typeof item.id === 'string') && item.id.includes("temporary")) {
            delete item.id;
        }
        return item;
    });
  }

  const changeAssignmentsState = (id, roleId, userId) => {
    if(id && roleId && userId) {
      const updatedArray = assignmentsList.map(element => {
        if (element.id === id) {
          return {
            ...element,
            roleId: roleId,
            userId: userId
          };
        }
        return element;
      });
      setAssignmentsList(updatedArray);
    } 
  }

  const randomInteger = () => {
    let rand = 1 + Math.random() * (999999999 + 1 - 1);
    return `temporary-${Math.floor(rand)}`;
  }

  const addNewAssignment = () => {
      const assignmentsListCopy = JSON.parse(JSON.stringify(assignmentsList));
      assignmentsListCopy.push(
        {
          id: randomInteger(),
          roleId: userRoles[0].id,
          userId: users[0].id
        }
      )
      setAssignmentsList(assignmentsListCopy)
  }

  const deleteAssignment = (id) => {
    const assignmentsListCopy = assignmentsList.filter(element => element.id !== id);
    setAssignmentsList(assignmentsListCopy);
  }

  useEffect(() => {
    if(clientInfo) {
      setAssignmentsList(clientInfo?.clientAssignments);
    }
  }, [clientInfo?.clientAssignments?.length]);

  useEffect(() => {
    const assignmentsWithRemovedIds = removeTemporaryIds(assignmentsList)
    getAssignments(assignmentsWithRemovedIds)
  }, [assignmentsList]);

  const assignmentHistoryHandler = assignmentId => {
    setOpenAssignmentHistoryId(assignmentId)
  }

  return (
    <>
      <Box sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        flexWrap: "wrap",
      }}>
        {assignmentsList.map((assignment) => {
          return <AssignmentRow
            key={assignment?.id}
            assignment={assignment}
            changeAssignmentsState={changeAssignmentsState}
            deleteAssignment={deleteAssignment}
            assignmentHistoryHandler={assignmentHistoryHandler}
            openAssignmentHistoryId={openAssignmentHistoryId}
          />
        })}
        <Button variant="outlined" size="small" sx={{width: '160px'}} onClick={() => addNewAssignment()}>
          <img src={addAssignment}/>
          Add assignment
        </Button>
      </Box>
    </>
  );
};
