import { useState, useEffect, useMemo } from "react";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import {
  ButtonContainer,
  ButtonsContainer,
  ClientsTitleContainer,
  EmptyMessage,
  HeaderContainer,
  ResultData,
  TableControlsContainer,
  TableSearchContainer,
  ToolsImg,
  UploadFileContainer,
} from './AuthorizationReport.styled';
import { useDownloadFile837Mutation, useGetBillingReportMutation, usePostUpload835FileMutation } from '../../services/ClientService';
import styled from '@emotion/styled';
import moment from 'moment';
import { LoadingPanel } from '../../shared/components/LoadingPanel';
import useFetchWithMsal from '../../utils/useFetchWithMsal';
import { AuthorizationReportFilters } from "./AuthorizationReportFilters";
import exportIcon from "../../shared/icons/export.svg";
import { CustomButton } from "../table/TableTab";
import { FileUpload } from "../common/FileUpload";
import { UploadFile } from "@mui/icons-material";
import ErrorAlert from "../../shared/components/ErrorAlert";
import { useDispatch } from "react-redux";
import { setError } from "../../store/slices/ErrorSlice";
import { AuthorizationStatusSelect } from "./AuthorizationStatusSelect";

const StyledPaper = styled(Paper)(() => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
}));

const StyledTableHead = styled(TableHead)(() => ({
  th: {
    whiteSpace: 'nowrap',
    background: '#fff',
    color: '#4281AB',
    paddingTop: '6px',
    paddingBottom: '8px',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: '600',
    borderBottom: "1px solid #b2cede",
  },
  '&::after': {
    height: '8px',
    display: 'table-row',
    content: '""'
  }
}));

const StyledTableBody = styled(TableBody)(({ theme }) => ({
  td: {
    color: '#000',
    paddingTop: '15px',
    paddingBottom: '15px',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: '400',
  },
  th: {
    paddingTop: '15px',
    paddingBottom: '15px',
    minWidth: '120px',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: '400',
    a: {
      color: theme.palette.background.main,
      textDecoration: 'none',
    }
  },
  tr: {
    background: '#fff',
    boxShadow: '0px 1px 2px 0px #00000040',
  }
}));

const StyledPagination = styled(TablePagination)(({ theme }) => ({
  '& .MuiInputBase-root': {
    background: theme.palette.background.mainBlue
  }
}));

function stableSort(array) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  return stabilizedThis?.map((el) => el[0]);
}

const headCells = [
  {
    id: 'clientFirstName',
    numeric: false,
    disablePadding: false,
    label: 'Client Name',
    sortable: true,
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Service Name',
    sortable: true,
  },
  {
    id: 'billingStatus',
    numeric: false,
    disablePadding: false,
    label: 'Billing Status',
    sortable: true,
  },
  {
    id: 'user',
    numeric: false,
    disablePadding: false,
    label: 'User',
    sortable: true,
  },
  {
    id: 'serviceDate',
    numeric: false,
    disablePadding: false,
    label: 'Service Date',
    sortable: true,
  },
  {
    id: 'startTime',
    numeric: false,
    disablePadding: false,
    label: 'Start Time',
    sortable: false
  },
  {
    id: 'endTime',
    numeric: false,
    disablePadding: false,
    label: 'End Time',
    sortable: false
  },
  {
    id: 'levelOfCare',
    numeric: false,
    disablePadding: false,
    label: 'Level Of Care',
    sortable: true,
  },
  {
    id: 'serviceGroupName',
    numeric: false,
    disablePadding: false,
    label: 'Service Group Name',
    sortable: true,
  },
  {
    id: 'rate',
    numeric: false,
    disablePadding: false,
    label: 'Rate',
    sortable: true,
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <StyledTableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding='normal'
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={headCell.sortable ? createSortHandler(headCell.id) : undefined}
            >
              {headCell.label}
              {headCell.sortable && orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </StyledTableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export const AuthorizationReport = () => {
  const { skip } = useFetchWithMsal();
  const dispatch = useDispatch();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [serviceDate, setServiceDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [clientIdList, setClientIdList] = useState([]);
  const [service, setService] = useState([]);
  const [authorizationList, setAuthorizationList] = useState([]);
  const [levelOfCare, setLevelOfCare] = useState([]);
  const [billingStatus, setBillingStatus] = useState(null);
  const [fileValue835, setFileValue835] = useState(null);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [serviceName, setServiceName] = useState(0);

  const handleServiceNameChange = (event) => {
    setServiceName(event.target.value);
  };

  const [getBillingReport, { data, isLoading }] = useGetBillingReportMutation({
    recordNumber: page * rowsPerPage,
    offset: rowsPerPage,
    serviceDate: serviceDate,
    endDate: endDate,
    clientIdList: clientIdList,
    serviceIdList: service,
    levelOfCare: levelOfCare,
    billingStatus: billingStatus,
    authorizationList: authorizationList,
  }, {skip})

  const [upload835, { data: data835, isLoading: is835Loading }] = usePostUpload835FileMutation({ fileValue835 }, {skip})

  const [downloadFile837, { data: file837, isLoading: is837FileLoading }] = useDownloadFile837Mutation({
    recordNumber: page * rowsPerPage,
    offset: rowsPerPage,
    serviceDate: serviceDate,
    endDate: endDate,
    clientIdList: clientIdList,
    serviceIdList: service,
    levelOfCare: levelOfCare,
    billingStatus: billingStatus,
    authorizationList: authorizationList,
  }, {skip})

  let originalRows = data?.serviceRate;

  const [rows, setRows] = useState([]);

  const handleUpload835File = () => {
    upload835(fileValue835)
      .then(response => {
        if(response?.error?.message) {
          setOpen(true);
          setMessage(response?.error?.message)
        } else {
          setOpen(true);
          setMessage('File uploaded successfully')
        }
      })
  };

  useEffect(() => {
    if(originalRows) {
      setRows(originalRows)
    }
    if(data?.totalCount) {
      setCount(data?.totalCount)
    }
  },[data, originalRows] );

  const replaceName = (name) => {
    if (name === 'clientFirstName') {
      return 'fullName';
    } else {
      return name;
    }
  }

  useEffect(() => {
    if (!skip) {
      const transformedOrderBy = replaceName(orderBy)
      getBillingReport({
        recordNumber: page * rowsPerPage,
        offset: rowsPerPage,
        serviceDate: serviceDate,
        endDate: endDate,
        clientIdList: clientIdList,
        serviceIdList: service,
        order,
        orderBy: transformedOrderBy,
        levelOfCare: levelOfCare,
        billingStatus: billingStatus,
        authorizationList: authorizationList,
      })
    }
  },[page, rowsPerPage, order, orderBy, skip, serviceDate, endDate, clientIdList, service, levelOfCare, billingStatus, authorizationList]);


  const handleDownload837File = () => {
    const transformedOrderBy = replaceName(orderBy);
    downloadFile837({
      recordNumber: page * rowsPerPage,
      offset: rowsPerPage,
      serviceDate: serviceDate,
      endDate: endDate,
      clientIdList: clientIdList,
      serviceIdList: service,
      order,
      orderBy: transformedOrderBy,
      levelOfCare: levelOfCare,
      billingStatus: billingStatus,
      authorizationList: authorizationList,
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = useMemo(
    () =>
      stableSort(rows).slice(),
    [order, orderBy, page, rowsPerPage, rows, data, originalRows],
  );

  const extractIds = array => {
    return array?.map(item => item?.id);
  }

  const handleServiceDateChange = (name, date) => {
    setServiceDate(date);
  };

  const handleEndDateChange = (name, date) => {
    setEndDate(date);
  };

  const handleClientIdChange = (name, value) => {
    setClientIdList(extractIds(value));
  };

  const handleServiceChange = (name, value) => {
    setService(extractIds(value));
  };

  const handleLevelOfCareChange = (name, value) => {
    setLevelOfCare(extractIds(value));
  };

  const handleAuthorizationListChange = (name, value) => {
    setAuthorizationList(extractIds(value));
  };

  const handleBillingStatusChange = (name, value) => {
    setBillingStatus(extractIds(value));
  };

  const handleDownload835File = (name, value) => {
    setFileValue835(value);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    dispatch(setError(""));
  };
  

  return (
    <Box sx={{ width: '100%', maxWidth: "calc(100% - 60px)", margin: "37px 30px"}}>
      {(isLoading || is837FileLoading) && <LoadingPanel />}
      <StyledPaper sx={{ width: '100%', mb: 2 }}>
        <HeaderContainer>
          <TableSearchContainer>
            <ClientsTitleContainer>
              Biling Report
            </ClientsTitleContainer>
            <AuthorizationReportFilters
              handleServiceDateChange={handleServiceDateChange}
              serviceDate={serviceDate}
              handleEndDateChange={handleEndDateChange}
              endDate={endDate}
              handleClientIdChange={handleClientIdChange}
              clientIdList={clientIdList}
              handleServiceChange={handleServiceChange}
              service={service}
              handleLevelOfCareChange={handleLevelOfCareChange}
              levelOfCare={levelOfCare}
              handleAuthorizationListChange={handleAuthorizationListChange}
              authorizationList={authorizationList}
              handleBillingStatusChange={handleBillingStatusChange}
              billingStatus={billingStatus}
            />
          </TableSearchContainer>
          <TableControlsContainer>
          </TableControlsContainer>
        </HeaderContainer>
        <TableContainer>
          <Table
            sx={{ 
              minWidth: 750,
              minHeight: 110,
              borderCollapse: 'separate',
              borderSpacing: '0 7px',
              padding: '5px',
            }}
            aria-labelledby="tableTitle"
            size={'medium'}

          >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                colSpan={6} 
              />
            {visibleRows.length ? <StyledTableBody>
              {visibleRows.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    sx={{ cursor: 'pointer', verticalAlign: 'top' }}
                    padding='normal'
                  >
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding='normal'
                    >
                      {row.clientFirstName} {row.clientLastName}
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding='normal'
                    >
                      {row.name}
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding='normal'
                    >
                      {/* {row.billingStatus} */}
                      <AuthorizationStatusSelect name={row.billingStatus} id={row.id} />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding='normal'
                    >
                      {row.user?.firstName} {row.user?.lastName}
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding='normal'
                    >
                      {row?.serviceDate ? moment(row.serviceDate).format("MM/DD/YYYY"): ''}
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding='normal'
                    >
                      {row?.startTime}
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding='normal'
                    >
                      {row?.endTime}
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding='normal'
                    >
                      {row?.levelOfCare}
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding='normal'
                    >
                      {row.serviceGroupName}
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding='normal'
                    >
                      {row.rate}
                    </TableCell>
                  </TableRow>
                );
              })}
            </StyledTableBody> : <EmptyMessage>No data to show</EmptyMessage>}
          </Table>
        </TableContainer>
        <ResultData><span>Total rate: </span> {data?.totalRate}</ResultData>
        <StyledPagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <ButtonsContainer>
          <ButtonContainer>
            <UploadFileContainer>
              <FileUpload buttonValue={'Choose 835 file'} value={fileValue835} setValue={handleDownload835File}/>
            </UploadFileContainer>
            <CustomButton
              variant="contained"
              onClick={handleUpload835File}
              style={{ marginTop: '5px' }}
              disabled={!fileValue835?.fileName}
            >
            <UploadFile style={{ color: 'white' }} />
              Upload 835
            </CustomButton>
          </ButtonContainer>
          <CustomButton
            variant="contained"
            onClick={handleDownload837File}
          >
            <ToolsImg src={exportIcon} alt=""/>
            Download 837
          </CustomButton>
        </ButtonsContainer>
      </StyledPaper>
      <ErrorAlert severity={message === 'File uploaded successfully' ? 'success' : 'error'} handleClose={handleClose} open={open} errorMessage={message} />
    </Box>
  );
}