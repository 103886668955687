import { Box, Button, styled } from "@mui/material";

export const FileDowloadTabContainer = styled(Box)({
  width: '290px',
  height: '100px',
  background: '#ffffff',
  boxShadow: '0px 1px 3px 0px #00000040',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'column',
  position: 'relative',
});

export const TopPanel = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
});

export const DropzoneArea = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  
  alignItems: 'center',
  background: '#f8efe7',
});

export const DropzoneTitle = styled(Box)({
  fontSize: '12px',
  fontWeight: '600',
  lineHeight: '20px',
  margin: '10px 0'
});

export const DropzoneSubTitle = styled(Box)({
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '20px',
  marginBottom: '10px'
});

export const StyledButton = styled(Button)({
  minWidth: 'auto',
  padding: '0 10px',
  fontSize: '12px',
  background: '#fff',
  marginLeft: '10px',
});

export const FileNameContainer = styled(Box)({
  width: '100%',
  cursor: 'pointer',
  fontSize: '12px',
  textOverflow: 'ellipsis',
  maxHeight: '100%',
  overflow: 'hidden',
  height: 'inherit',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#f8efe7',
  color: '#659dbd',
  border: '1px solid',
  padding: '5px',
});

export const FileNameText = styled(Box)({
  texOverflow: 'ellipsis',
  maxWidth: '100%',
  padding: '0 10px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  display: 'block',
});

export const IconsContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
});

export const DeleteButtonContainer = styled(Box)({
  position: 'absolute',
  right: 3,
  top: 3,
});
