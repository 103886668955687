import CloseIcon from "@mui/icons-material/Close";
import {
  ButtonGroup,
  CalendarHeader,
  Client,
  FormContainer,
  NameGroup,
  PopupBody,
  PopupButton,
  PopupContainer,
} from "./AddServicesPopup.styled";
import {  Button, Grid, Modal, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import InputField from "../../common/InputField";
import DateTimePickerField from "../../common/DateTimePickerField";
import useFetchWithMsal from "../../../utils/useFetchWithMsal";
import SelectFieldForForm from "../../common/SelectFieldForForm";
import noteModel from "../../../constants/NoteModel";
import { useEditNoteMutation, useGetNotesTypesQuery, useGetRequestSatatusQuery, useGetUserInfoQuery } from "../../../services/ClientService";

export const AddNotePopup = ({ open, onClose, clientName, event, clientId, onClick, clients }) => {
  const { skip } = useFetchWithMsal();
  const { data } = useGetNotesTypesQuery({ skip });
  const { data: requestStatuses } = useGetRequestSatatusQuery({ skip });
  const { data: basicInfo } = useGetUserInfoQuery({ skip });

  const reformatClients = () => {
    if(clients){
      return clients.map(item => ({
        name: `${item.firstName} ${item.lastName}`,
        id: item.id
      }));
    }
  }

  const getInputComponent = (item) => {
    switch (item?.type) {
      case "select":
        return (
          <Field name={item.name}>
            {({ field, form }) => (
              <SelectFieldForForm
                name={item.name}
                label={item.label}
                data={data}
                value={field.value}
                setValue={form.setFieldValue}
                error={form.errors[item.name]}
                width={'100%'}
                fullWidth
                disabled={event ? (basicInfo.id !== event?.ownerId || event?.quickNoteTypeId === 9) : false}
              />
            )}
          </Field>
        );
      case "clientSelect":
        return (
          <Field name={item.name}>
            {({ field, form }) => (
              <SelectFieldForForm
                name={item.name}
                label={item.label}
                data={reformatClients(clients)}
                value={field.value}
                setValue={form.setFieldValue}
                error={form.errors[item.name]}
                width={'100%'}
                fullWidth
              />
            )}
          </Field>
        );  
      case "statusSelect":
        return (
          <Field name={item.name}>
            {({ field, form }) => (
              form.values.quickNoteTypeId === 6 && <SelectFieldForForm
                name={item.name}
                label={item.label}
                data={requestStatuses}
                value={field.value}
                setValue={form.setFieldValue}
                error={form.errors[item.name]}
                width={'100%'}
                fullWidth
              />
            )
          }
          </Field>
        );  
      case "date":
        return (
          <Field name={item.name}>
            {({ field, form }) => (
             <DateTimePickerField
                name={item.name}
                label={item.label}
                value={field.value}
                setValue={form.setFieldValue}
                error={form.errors[item.name]}
                maxDate={new Date()}
                disabled
              />
            )}
          </Field>
        );

      default:
        return (
          <Field name={item?.name}>
            {({ field, form }) => (
              <InputField
                name={item?.name}
                label={item?.label}
                value={field.value || ""}
                setValue={form.setFieldValue}
                error={form.errors[item?.name]}
                fullWidth
                width={"100%"}
                multiline={true}
                height={"fit-content"}
                disabled={event ? (basicInfo.id !== event?.ownerId || event?.quickNoteTypeId === 9) : false}
              />
            )}
          </Field>
        );
    }
  };

  const handleSubmit = (value) => {
    onClick(value, event ? "change":'add');
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-addServices"
      aria-describedby="modal-modal-addServices"
    >
      <PopupBody>
        <PopupContainer>
          <CalendarHeader>
            <Typography variant="h2">{event ? 'Edit Note': 'Add Note'}</Typography>
            {clientName && <NameGroup><Client variant="body1" >Client</Client>
            <Typography variant="body1">{clientName}</Typography></NameGroup>}
            <PopupButton aria-label="delete" onClick={onClose}>
              <CloseIcon />
            </PopupButton>
          </CalendarHeader>
          <FormContainer>
            <Formik
              // validationSchema={validationSchema}
              onSubmit={handleSubmit}
              initialValues={event || {
                noteId: "",
                date: "",
                quickNoteTypeId: 0,
              }}
            >
              {() => (
                <Form id={noteModel.noteId}>
                  <Grid container rowSpacing={"15px"} columnSpacing={"49px"}>
                    <Grid item xs={12}>
                      {getInputComponent(
                        noteModel.formField.quickNoteTypeId
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {getInputComponent(
                        noteModel.formField.requestStatus
                      )}
                    </Grid>
                    {!clientId && <Grid item xs={12}>
                      {getInputComponent(
                        noteModel.formField.client
                      )}
                    </Grid>}
                    <Grid item xs={12}>
                      {getInputComponent(
                        noteModel.formField.text
                      )}
                    </Grid>

                  </Grid>
                  <ButtonGroup>
                    <Button type="submit" variant="contained" disabled={event ? (basicInfo.id !== event?.ownerId || event?.quickNoteTypeId === 9) : false}>
                      Save
                    </Button>
                    <Button variant="outlined" onClick={() => onClose()}>
                      Close
                    </Button>
                  </ButtonGroup>
                </Form>
              )}
            </Formik>
          </FormContainer>
        </PopupContainer>
      </PopupBody>
    </Modal>
  );
};
