const clientFormModel = {
  formId: 'clientForm',
  formField: {
    firstName: {
      name: 'firstName',
      label: 'First name',
      requiredErrorMsg: 'First name is required'
    },
    lastName: {
      name: 'lastName',
      label: 'Last name',
      requiredErrorMsg: 'Last name is required'
    },
    birthDate: {
      name: 'birthDate',
      label: 'Birth date',
      requiredErrorMsg: 'Birth date is required',
      invalidErrorMsg: 'Birth date is not valid'
    },
    address1: {
      name: 'address1',
      label: 'Address 1',
      requiredErrorMsg: 'Address 1 is required'
    },
    address2: {
      name: 'address2',
      label: 'Address 2'
    },
    city: {
      name: 'city',
      label: 'City',
      requiredErrorMsg: 'City is required'
    },
    state: {
      name: 'state',
      label: 'State',
      requiredErrorMsg: 'State is required'
    },
    zipcode: {
      name: 'zipCode',
      label: 'Zip code',
      requiredErrorMsg: 'Zip code is required',
      invalidErrorMsg: 'Zip code is not valid (e.g. 70000)'
    },
    primaryPhone: {
      name: 'primaryPhone',
      label: 'Primary phone',
      requiredErrorMsg: 'Primary phone is required',
      invalidErrorMsg: 'Primary phone is not valid'
    },
    cellPhone: {
      name: 'cellPhone',
      label: 'Cell phone',

    },
    email: {
      name: 'email',
      label: 'Email',
      invalidErrorMsg: 'Email is invalid (e.g. example@mail.com)'
    }
  }
};
export default clientFormModel