import React from "react";
import { Container } from "./SignatureDownload.styled";
import { Typography } from "@mui/material";
import { SignatureDowloadTab } from "../../components/signatureDownload/SignatureDowloadTab";

export const SignatureDownload = () => {
  return (
    <Container>
      <Typography variant="h2" sx={{marginBottom:'25px'}}>Profile</Typography>
      <SignatureDowloadTab/>
    </Container>
  );
};
