import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Box, FormControl, FormHelperText, InputLabel, Typography } from '@mui/material';

export default function AutocompleteField(props) {

  const {
    onInputChange,
    possibleValues,
    onChange,
    value,
    isError,
    errorText,
    width,
    label,
    labelType,
    ...rest
  } = props;
  return (
    <Box sx={{textAlign:'start'}}>
      {label ? 
        <InputLabel id={label}>
          <Typography variant={labelType || "body1"}>{label}</Typography>
        </InputLabel>
      : null }
      <FormControl {...rest} error={isError} style={{width:'100%'}}>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={possibleValues || []}
          sx={{ height: "auto", maxWidth: width || "290px", width:'100% '}}
          value={value}
          renderInput={(params) => <TextField {...params} />}
          onChange={(event, newValue) => onChange(newValue)}
          isOptionEqualToValue={(option, value) => option?.name === value?.name}
          onInputChange={(event, newInputValue) => onInputChange(newInputValue)}
          getOptionLabel={(option) => option?.name || ""}
        />
        {isError && <FormHelperText>{errorText}</FormHelperText>}
      </FormControl>
    </Box>


  );
}
