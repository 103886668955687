import { Box, styled } from "@mui/material";

export const ImgContainer = styled(Box)({
  display: "flex",
  width: "100px",
  height: "100px",
  "& img": {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
});
export const InfoContainer = styled(Box)({
  display: "grid",
  gridTemplateColumns: "auto 1fr",
  gap: "10px",
});

