import { TableCell, TableRow } from "@mui/material";
import { ServiceStatus } from "../TableTab.styled";
import { useState } from "react";
import { CountContainer, DateContainer, DateItem } from "./ServicesStatisticsPopup.styled";
import styled from '@emotion/styled';
import moment from "moment";
import arrowDown from "../../../shared/icons/arrowDown.svg";


export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    background: '#fff',
    '&:nth-of-type(even)': {
      background: '#EFF5F8',
    },
  }));

export const ServiceRow = ({ row, keyValue }) => {
  const [openDetails, setOpenDetails] = useState(false);

  return (
        <StyledTableRow
            role="checkbox"
            tabIndex={-1}
            key={keyValue}
            sx={{ cursor: 'pointer', verticalAlign: 'top' }}
            padding='normal'
            onClick={() => setOpenDetails(!openDetails)}
            id={row.id}
        >
            <div>
                <TableCell align="left" sx={{ width: 100 }}>
                    {row.status && 
                        <ServiceStatus status={true}>
                            {row.status}
                        </ServiceStatus>
                    }
                </TableCell>
                <TableCell align="left" sx={{ width: 250 }}>
                    {row.name}
                </TableCell>
                <TableCell align="left">
                    <CountContainer>
                        <span>{row.count}</span><img src={arrowDown}/>
                    </CountContainer>
                </TableCell>
            </div>

        {openDetails && <DateContainer>
            {row.endDates.map((date, index) => {
                return (
                    <DateItem>
                        {moment(date.date).format("MM/DD/YYYY")}
                    </DateItem>
                )
            })}
        </DateContainer>}
        </StyledTableRow>

  );
};