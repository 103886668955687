import { Box, Icon, styled } from "@mui/material";
import { Link } from "react-router-dom";

export const ToolsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "20px",
  alignItems: "center",
  color: theme.palette.background.main,
  paddingTop: "10px",
  paddingBottom: "19px",
  backgroundColor:theme.palette.background.mainBlue
}));

export const ToolsButtonGroup = styled(Box)(({ theme }) => ({
  display: "grid",
  gridAutoFlow: "column",
  gap: "7px",
  alignItems: "center",
  color: theme.palette.background.main,
}));

export const ButtonWithoutStyle = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  color: theme.palette.background.main,
  border: "none",
  cursor: "pointer",
  backgroundColor: "inherit",
  height: "16px",
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.background.main,
}));
