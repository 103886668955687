import { useState, useEffect } from "react";
import { Box, Button, MenuItem, Select, styled } from "@mui/material";
import { useGetCanceledBillingStatusQuery, usePutBillingStatusMutation } from "../../services/ClientService";
import useFetchWithMsal from "../../utils/useFetchWithMsal";
import ErrorAlert from "../../shared/components/ErrorAlert";
import { setError } from "../../store/slices/ErrorSlice";
import { useDispatch } from "react-redux";
import { FaHourglassHalf, FaCheckCircle, FaTimesCircle, FaBan, FaTrashAlt, FaRedoAlt, FaDollarSign, FaFileAlt, FaSyncAlt, FaEraser } from 'react-icons/fa'; 

const StyledBillingStatusSelect = styled(Select)(() => ({
    background: '#fff',
}));

const StyledButton = styled(Button)(() => ({
    color: 'rgb(189, 152, 106)',
    fontSize: '10px',
    marginLeft: '10px'
}));

const billingStatusIcons = {
  'Submitted - Payment pending': <FaHourglassHalf style={{ marginRight: '8px', color: 'gray' }} />,
  'Write-Off': <FaTrashAlt style={{ marginRight: '8px', color: 'gray' }} />,
  'Void (created in error)': <FaEraser style={{ marginRight: '8px', color: 'gray' }} />,
  'Denied': <FaBan style={{ marginRight: '8px', color: 'gray' }} />,
  'Adjusted': <FaRedoAlt style={{ marginRight: '8px', color: 'gray' }} />,
  'Partial payment': <FaDollarSign style={{ marginRight: '8px', color: 'gray' }} />,
  'Paid in full': <FaCheckCircle style={{ marginRight: '8px', color: 'gray' }} />,
  'Rejected': <FaTimesCircle style={{ marginRight: '8px', color: 'gray' }} />,
  'Un-Billed': <FaFileAlt style={{ marginRight: '8px', color: 'gray' }} />,
  'Re-Submitted': <FaSyncAlt style={{ marginRight: '8px', color: 'gray' }} />,
};

export const BillingStatusSelect = ({ name, id }) => {
  const [billingStatus, setBillingStatus] = useState(0);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const { skip } = useFetchWithMsal();
  const dispatch = useDispatch();

  const { data: canceledBillingStatuses } = useGetCanceledBillingStatusQuery({ skip });
  const [putBillingStatus, { error }] = usePutBillingStatusMutation();

  // Sync the state with the prop when it changes
  useEffect(() => {
    setBillingStatus(name);
  }, [name]);

  const handleBillingStatusChange = (event) => {
    setBillingStatus(event.target.value);
  };

  const changeBillingStatus = () => {
    putBillingStatus({ id: id, statusId: billingStatus })
      .then(response => {
        if(response?.error?.message) {
          setOpen(true);
          setMessage(response?.error?.message)
        } else {
          setOpen(true);
          setMessage('Status changed successfully')
        }
      })
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    dispatch(setError(""));
  };

  return (
    <Box>
      <StyledBillingStatusSelect
        labelId="billing-status-select-label"
        id="billing-status-select"
        value={billingStatus}
        onChange={handleBillingStatusChange}
        label="Billing Status"
      >
        <MenuItem 
          value={name}
        >
          {billingStatusIcons[name]} {name}
        </MenuItem>
        {canceledBillingStatuses && canceledBillingStatuses.map(status => name !== status.name && (
          <MenuItem 
            key={status.id} 
            value={status.id}
          >
            {billingStatusIcons[status.name]} {status.name}
          </MenuItem>
        ))}
      </StyledBillingStatusSelect>
      {/* <StyledButton onClick={changeBillingStatus} disabled={billingStatus === name} variant="text">Change status</StyledButton>
      <ErrorAlert severity={message === 'Status changed successfully' ? 'success' : 'error'} handleClose={handleClose} open={open} errorMessage={message} /> */}
    </Box>
  );
}