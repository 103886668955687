import { InputLabel } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const letters = ['R', 'S', 'G', 'C', 'O'];

const LetterButtonWrapper = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: ${({ isActive }) => isActive ? '#FDF6DF' : 'white'};
  margin: 5px;
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  color: '#000';
  border: 1px solid;
  border-color: ${({ isActive }) => isActive ? '#8E8741' : '#659DBD'};
`;

const LetterButtonsContainer = styled.div`
  display: flex;
`;

export const ReactionField = ({initialIndexes, label, required, name, setValue, error}) => {
  const [activeIndexes, setActiveIndexes] = useState(initialIndexes);

  useEffect(() => {
    if(activeIndexes && setValue) {
        setValue(name, activeIndexes)
    }
  }, [activeIndexes]);

  useEffect(() => {
    setActiveIndexes(initialIndexes);
  }, [initialIndexes]);

  const toggleActive = (index) => {
    if(setValue) {
        if (activeIndexes.includes(index)) {
            setActiveIndexes(activeIndexes.filter((i) => i !== index));
        } else {
            setActiveIndexes([...activeIndexes, index]);
        }
    }
  };

  const getToolTip = (letter) => {
    switch (letter) {
      case 'R':
        return 'Respiratory symptoms: Sneezing, runny or stuffy nose (allergic rhinitis), coughing, wheezing, shortness of breath, chest tightness';
      case 'S':
        return 'Skin reactions: Itching, hives (urticaria), swelling (angioedema)';
      case 'G':
        return 'Gastrointestinal symptoms: Nausea, vomiting, diarrhea, abdominal pain';
      case 'C':
        return 'Cardiovascular symptoms: Drop in blood pressure, rapid heartbeat, lightheadedness, fainting (in severe cases)';
      case 'O':
        return 'Ocular symptoms: Red, itchy, watery eyes (allergic conjunctivitis)';
      default:
        return '';
    }
  };
  return (
    <>
        {label && (
        <InputLabel htmlFor="input" sx={{ height: "14px", color: error ? "red" : "rgba(66, 129, 171, 1)"}}>
          {label}{required && '*'}
        </InputLabel>
      )}
    <LetterButtonsContainer>
      {letters.map((letter, index) => (
        <LetterButtonWrapper
          key={index}
          isActive={activeIndexes?.includes(index+1)}
          onClick={() => toggleActive(index+1)}
          title={getToolTip(letter)}
        >
          {letter}
        </LetterButtonWrapper>
      ))}
    </LetterButtonsContainer>
    </>
  );
};
