import { Box, styled } from "@mui/material";

export const ClientTabContainer = styled(Box)({
  width: '700px',
  margin: '0 auto'
});
export const StepContainer = styled(Box)(({ theme, isExpanded }) => ({
  width: '28px',
height: '28px',
backgroundColor: isExpanded? theme.palette.background.active : theme.palette.background.step ,
borderRadius: '50%',
alignItems: 'center',
display: 'flex',
justifyContent: 'center',
color: '#fff',
marginRight: '12px',
}));