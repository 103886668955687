import React from "react";
import { Box, Button, Grid } from "@mui/material";
import InputField from "../../common/InputField";
import DatePickerField from "../../common/DatePickerField";
import { Field } from "formik";

export const ClientInfoForm = (props) => {
  const {
    formField: { firstName, lastName, birthDate },
  } = props;
  return (
    <React.Fragment>
      <Grid container rowSpacing={"26px"} columnSpacing={"65px"}>
        <Grid item xs={6}>
          <Field name={firstName.name}>
            {({ field, form }) => {
              return (
                <InputField
                  name={firstName.name}
                  label={firstName.label}
                  value={field.value}
                  setValue={form.setFieldValue}
                  error={form.errors[firstName.name]}
                  required={true}
                  fullWidth
                />
              );
            }}
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field name={lastName.name}>
            {({ field, form }) => (
              <InputField
                name={lastName.name}
                label={lastName.label}
                value={field.value}
                setValue={form.setFieldValue}
                error={form.errors[lastName.name]}
                required={true}
                fullWidth
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field name={birthDate.name}>
            {({ field, form }) => (
              <DatePickerField
                name={birthDate.name}
                label={birthDate.label}
                maxDate={new Date()}
                value={field.value}
                setValue={form.setFieldValue}
                required={true}
                error={form.errors[birthDate.name]}
              />
            )}
          </Field>
        </Grid>
      </Grid>
      <Box sx={{ marginTop: "28px", display: "flex", justifyContent: "start" }}>
        <Button type="submit" variant="contained">
          Next
        </Button>
      </Box>
    </React.Fragment>
  );
};
