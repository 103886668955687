import { useState, useEffect } from "react";

import { InteractionType } from "@azure/msal-browser";
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import { protectedResources } from "../authConfig";

/**
 * Custom hook to call a web API using bearer token obtained from MSAL
 * @param {PopupRequest} msalRequest
 * @returns
 */
const useFetchWithMsal = () => {
  const { instance } = useMsal();
  const [skip, setSkip] = useState(true);
  const { result } = useMsalAuthentication(InteractionType.Redirect, {
    scopes: protectedResources.api.scopes.read,
    account: instance.getActiveAccount(),
    redirectUri: "/",
  });
  useEffect(() => {
    if (result) {
      localStorage.setItem("token", result?.accessToken);
      setSkip(false);
    }
  }, [result]);
  return {
    skip,
    setSkip,
    result,
  };
};

export default useFetchWithMsal;