import React from "react";
import { Box, styled } from "@mui/material";
import avatar from "../icons/avatar.png";

const StyledUserIcon = styled(Box)({
  borderRadius: "50%",
  width: "30px",
  height: "30px",
  overflow: "hidden",
  marginRight: "9px",
  "& img": {
    height: "100%",
    width: "100%",
  },
});
const UserIcon = () => {
  return (
    <StyledUserIcon>
      <img src={avatar} alt={"avatar"} />
    </StyledUserIcon>
  );
};
export default UserIcon;
