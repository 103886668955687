import React from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";

const CheckboxField = (props) => {
  const { value, name, setValue, error,label, width, required, ...rest } = props;
  

  const renderHelperText = () => {
    if ( error) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  };

  function onChange(e) {
    setValue(name,e.target.checked);
  }

  return (
    <FormControl {...rest} style={{width: width || '100%', marginTop: '25px'}}>
      <FormControlLabel
        value={value}
        checked={value}
        required={required}
        control={<Checkbox onChange={onChange} />}
        label={label}
      />
      {renderHelperText()}
    </FormControl>
  );
};
export default React.memo(CheckboxField);
