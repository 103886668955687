import styled from "styled-components";
import {
  Button,
} from "@mui/material";

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #aac8db;
  margin: 0 5px;
  margin-bottom: 15px;
`;

export const ClientsTitleContainer = styled.div`
  font-family: Suez One;
  font-size: 20px;
  font-weight: 400;
  color: #4281AB;
  margin-right: 35px;
`;

export const TableSearchContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TableControlsContainer = styled.div`
  padding: 10px 0px;
  margin: 10px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SearchInputContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 20px;
  border: 1px solid rgba(229, 229, 229, 1);
  overflow: hidden;
  padding: 3px 20px;
  background: #fff;
`;

export const SearchInput = styled.input`
  outline: none;
  border: none;
  width: 100%;
`;

export const TableHeadContainer = styled.div`
    color: red;
`;

export const FilterChipList = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const FilterChip = styled.div`
  font-family: Lato;
  font-size: 11px;
  font-weight: 400;
  padding: 5px 14px;
  background: #FBEEC1;
  border-radius: 26px;
  margin-right: 15px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  span{
    color: #4281AB;
    font-family: Open Sans;
    margin-right: 5px;
  }
`;

export const TableRowContainer = styled.div`
  padding: 0 10px;
`;

export const ToolsImg = styled.img`
  margin-right: 4px;
  transform: ${(props) => props.rotate ? 'rotate(180deg);' : 'none'};
`;

export const CrossImg = styled.img`
  margin-left: 5px;
  cursor: pointer;
`;

export const TracksTitle = styled.div`
  margin-right: 5px;
`;

export const StyledButton = styled(Button)(() => ({
  cursor: "pointer",
  padding: "4px 15px",
  textTransform: "uppercase",
  fontSize: '12px',
  fontWeight: 600,
  marginRight: "5px",
  '&:hover': {
    opacity: 0.8,
  },
  'svg': {
    color: '#fff',
  },
}));

export const TrackWrap = styled.div`
  font-family: Lato;
  font-size: 11px;
  font-weight: 400;
  padding: 5px 14px;
  background: #659DBD;
  border-radius: 26px;
  color: #fff;
  margin: 0 5px;
`;

export const EmptyMessage = styled.div`
  font-family: Suez One;
  font-size: 15px;
  font-weight: 400;
  color: #4281AB;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
`;

export const StatusChip = styled.div`
  font-family: Open Sans;
  font-size: 11px;
  font-weight: 400;
  padding: 2px 10px;  
  border-radius: 16px;
  margin-bottom: 5px;
  width: fit-content;
  background: ${(props) => props.status ? '#b2cede' : '#f79183'};
`;

export const RowOptionContainer = styled.div`
  font-family: Open Sans;
  font-size: 11px;
  font-weight: 600;
  display: flex;
  align-items: center;
  color: #BD986A;
  margin-top: 5px;
  margin-right: 13px;
`;

export const NoteContainer = styled.div`
  font-family: Open Sans;
  font-size: 10px;
  display: flex;
  flex-direction: column;
  padding: 7px 9px;
  background: #EFF5F8;
  margin-bottom: 4px;
  color: #000000;
  width: 240px;
`;

export const NoteHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const NoteName = styled.div`
  font-wight: 600;
`;

export const NoteDate = styled.div`
  font-wight: 400;
  color: #797b7c;
`;

export const NoteText = styled.div`
  font-wight: 400;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  max-width: 100%;
  overflow: hidden;
`;

export const InsuredsType = styled.div`
  font-wight: 400;
  font-size: 11px;
  font-weight: 400;
  color: #808080;
  margin-right: 4px;
`;

export const StatisticsOptions = styled.div`
  display: flex;
`;

export const ServiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Open Sans;
  font-size: 11px;
  margin-bottom: 10px;
`;

export const ServiceHeader = styled.div`
  display: flex;
  margin-bottom: 5px;
`;

export const ServiceButtons = styled.div`
  display: flex;
`;

export const ServiceStatus = styled.div`
  font-size: 10px;
  background: #FBEEC1;
  padding: 2px 5px;
  margin-right: 7px;
  width: 67px;
  text-align: center;
`;

export const ServiceMaterials = styled.div`
  display: flex;
  align-items: center;
  color: grey;
  cursor: pointer;
`;

export const MaterialsArrow = styled.img`
  margin-left: 2px;
`;

export const ServiceDate = styled.div`
  font-weight: 600;
  margin-right: 4px;
  justify-self: flex-end;
`;

export const ServiceName = styled.div`
  font-weight: 400;
  margin-right: 4px;
  margin-right: 7px;
`;

export const InsuredsContainer = styled.div`
  display: flex;
`;

export const NotesPanelContainer = styled.div`
  display: ${(props) => props.visibility ? 'block' : 'none'};
  position: fixed;
  top: 0;
  right: 0;
  background: #fff;
  z-index: 1000;
  height: 100vh;
  width: 66%;
  padding-top: 34px;
`;

export const NotesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 10px 20px 10px;
`;

export const CloseNotesPanelButton = styled.img`
  background: #e0ebf2;
  padding: 6px;
  border-radius: 50%;
`;

export const NotesPanelCardContainer = styled.div`
  background: #fff;
  box-shadow: 0px 1px 3px 0px #00000040;
  width: 282px;
  padding: 17px 20px;
  color: #000;
  font-size: 11px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  margin: 10px;
`;

export const NotesPanelCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const NotesPanelCardInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NotesPanelCardName = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Open Sans;
  font-weight: 600;
`;

export const NotesPanelCardDate = styled.div`
  display: flex;
  flex-direction: column;
  color: grey;
`;

export const NotesCardStatus = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 10px;
  background: #FBEEC1;
  padding: 2px 5px;
`;

export const NotesPanelCardText = styled.div`
  width: 100%;
`;

export const NotesPanelCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const PanelCardsSearchInputContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  overflow: hidden;
  padding: 3px 20px;
  background: #F8EFE7;
  margin-right: 10px;
`;

export const PanelCardsSearchInput = styled.input`
  outline: none;
  border: none;
  width: 100%;
  background: #F8EFE7;
`;

export const CalendarSearchInputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  overflow: hidden;
  padding: 3px 20px;
  background: #fff;
  margin-right: 10px;
  margin-left: auto;
  min-width: 290px;
`;

export const CalendarSearchInput = styled.input`
  outline: none;
  border: none;
  width: 100%;
  background: #fff;
`;

export const AddElementButton = styled.div`
  cursor: pointer;
  font-size: 15px;
  color: rgba(101, 157, 189, 1);
  padding-left: 10px;
`;

export const ResultData = styled.div`
  font-family: Lato;
  font-size: 15px;
  font-weight: 400;
  padding: 5px 5px;
  border-radius: 26px;
  margin-right: 15px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  span{
    color: #4281AB;
    font-family: Open Sans;
    margin-right: 5px;
  }
`;

export const UploadFileContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

export const UploadFileLabel = styled.div`
  margin-bottom: 10px;
  color: #4281AB;
  font-family: Open Sans;
  margin-right: 5px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
`;
