import { Box, styled } from "@mui/material";

export const NavBar = styled(Box)({
  position: "fixed",
  width: "100%",
  top: 0,
  zIndex: 1000,
  height: "55px",
});
export const NavigationBarContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  color: "#fff",
  padding: "10px 20px",
  height: "70px",
  backgroundColor: theme.palette.background.header,
}));

export const BadgeContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginRight: "33px",
  marginLeft: "auto",
  cursor: "pointer",
});
export const NavigationBarButton = styled(Box)({
  display: "flex",
  alignItems: "center",
  color: "#fff",
  fontFamily: "Open Sans",
  border:'none',
  cursor: 'pointer',
  backgroundColor: 'inherit',
  fontSize:'12px',
  fontWeight: '400',
});

