import CloseIcon from "@mui/icons-material/Close";
import {
  Header,
  ClientName,
  PopupBody,
  PopupButton,
  PopupContainer,
  PopupFooter,
} from "./ServicesStatisticsPopup.styled";
import { Button, Modal, TableBody, Typography } from "@mui/material";
import useFetchWithMsal from "../../../utils/useFetchWithMsal";
import { useGetClientServiceStatisticQuery } from "../../../services/ClientService";
import styled from '@emotion/styled';
import { EmptyMessage } from "../TableTab.styled";
import { ServiceRow } from "./ServiceRow";
import circleArrow from "../../../shared/icons/circleArrow.svg";
import { LoadingPanel } from "../../../shared/components/LoadingPanel";

export const StyledTableBody = styled(TableBody)(({ theme }) => ({
  borderBottom: "1px solid #e0ebf2",
  td: {
    verticalAlign: 'middle',
    border: 'none',
    color: '#000',
    paddingTop: '7px',
    paddingBottom: '7px',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: '400',
  },
  th: {
    paddingTop: '15px',
    paddingBottom: '15px',
    minWidth: '120px',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: '400',
    a: {
      color: theme.palette.background.main,
      textDecoration: 'none',
    }
  },
  tr: {
    background: '#fff',
    '&:nth-of-type(even)': {
      background: '#EFF5F8',
    },
  }
}));

export const ServicesStatisticsPopup = ({ open, onClose, onClick, clientId, clientName, event }) => {
  const { skip } = useFetchWithMsal();
  const { data, isLoading } = useGetClientServiceStatisticQuery(clientId, { skip });

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-addServices"
      aria-describedby="modal-modal-addServices"
    >
      <PopupBody>
        {isLoading && <LoadingPanel />}
        <PopupContainer>
          <Header>
            <Typography variant="h2" >Services Statistics</Typography>
            <PopupButton aria-label="delete" onClick={onClose}>
              <CloseIcon />
            </PopupButton>
          </Header>
          {data?.services?.length ? <StyledTableBody>
            {data?.services.map(row => {
              return (
                <ServiceRow row={row} keyValue={row.id} />
              );
            })}
          </StyledTableBody> : <EmptyMessage>No data to show</EmptyMessage>}
          <PopupFooter>
            <Button
              variant="outlined"
              onClick={() => onClose()}
              sx={{ width: "108px" }}
            >
              Close
            </Button>
            <ClientName variant="body1">{clientName}<img src={circleArrow} /></ClientName>
          </PopupFooter>
        </PopupContainer>
      </PopupBody>
    </Modal>
  );
};
