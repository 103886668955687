import { useState, useEffect } from "react";
import { Box, Typography, Button, Modal } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styled from '@emotion/styled';
import moment from 'moment';
import { ElementInfoPopup } from "./ElementInfoPopup";
import { AddNewButton, AllergiesInfoText, IconsContainer, RowNotice } from "./ElementDetailsForm.styled";
import roundedPlus from "../../../../shared/icons/roundedPlus.svg";
import useFetchWithMsal from "../../../../utils/useFetchWithMsal";
import { useGetAllergyCodeSystemsQuery, useGetAllergySeverityQuery, useGetClientMedicationFrequencyQuery, useGetClientMedicationMethodQuery, useGetDrugOfChoiceQuery, useGetListOfAllergiesQuery } from "../../../../services/ClientService";
import { ReactionField } from "./ReactionField";
import { Delete, Edit } from "@mui/icons-material";

const StyledPaper = styled(Paper)(() => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
}));

const StyledTableHead = styled(TableHead)(() => ({
  th: {
    whiteSpace: 'nowrap',
    background: '#fff',
    color: '#4281AB',
    paddingTop: '6px',
    paddingBottom: '8px',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: '600',
    borderBottom: "1px solid #b2cede",
  },
  '&::after': {
    height: '8px',
    display: 'table-row',
    content: '""'
  }
}));

const StyledTableBody = styled(TableBody)(({ theme }) => ({
  td: {
    color: '#000',
    paddingTop: '5px',
    paddingBottom: '5px',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: '400',
    borderBottom: "none",
  },
  th: {
    paddingTop: '5px',
    paddingBottom: '5px',
    minWidth: '120px',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: '400',
    a: {
      color: theme.palette.background.main,
      textDecoration: 'none',
    }
  },
  tr: {
    background: '#fff',
    height: '30px',
    '&:nth-of-type(odd)': {
      background: '#EFF5F8',
    },
  }
}));

const headCells = [
  {
    id: 'allergicTo',
    numeric: false,
    disablePadding: false,
    label: 'Allergic to',
    enableSorting: false,
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'Type',
    enableSorting: false,
  },
  {
    id: 'reaction',
    numeric: false,
    disablePadding: false,
    label: 'Reaction',
    enableSorting: false,
  },
  {
    id: 'medicationPrescribed',
    numeric: false,
    disablePadding: false,
    label: 'Medication prescribed',
    enableSorting: false
  },
  {
    id: 'codeSystem',
    numeric: false,
    disablePadding: false,
    label: 'Code System',
    enableSorting: false,
  },
  {
    id: 'encounterDate',
    numeric: false,
    disablePadding: false,
    label: 'Encounter Date',
    enableSorting: false,
  },
  {
    id: 'controlsPanel',
    numeric: false,
    disablePadding: false,
    enableSorting: false,
  },
];

function EnhancedTableHead() {
  return (
    <StyledTableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding='normal'
            sx={{
              minWidth: headCell.id === 'firstName' ? '250px' : 'auto',
            }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </StyledTableHead>
  );
}

export const AllergyList = ({ values, setAllergyList, name, setValue }) => {
  const initialValue = values?.items?.length ? values?.items : values?.listOfAllergies;
  const { skip } = useFetchWithMsal();

  const { data: allergyCode } = useGetAllergyCodeSystemsQuery("", { skip });
  const { data: allergySeverity } = useGetAllergySeverityQuery("", { skip });
  const { data: listOfAllergies } = useGetListOfAllergiesQuery("", { skip });


  const [elementPopupOpen, setElementPopupOpen] = useState(false);
  const [selectedElement, setSelectedElement] = useState({});
  const [selectedElementIndex, setSelectedElementIndex] = useState(null);
  const [initialValues, setInitialValues] = useState(null); 
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [elementIndexToDelete, setElementIndexToDelete] = useState(null);

  const [elements, setElements] = useState(initialValue ? initialValue : []);
  const [rows, setRows] = useState([]);

  const handleRemoveElement = (index) => {
    setElementIndexToDelete(index);
    setConfirmDeleteOpen(true);
  };

  const transformData = (data) => {
    const transformedData = {
        type: 16,
        items: data.map(item => {
            const { newElement, ...rest } = item;
            return rest;
        })
    };

    return transformedData;
  };

  const confirmDelete = () => {
    if (elementIndexToDelete !== null) {
      setElements(prevElements => {
        const updatedElements = [...prevElements];
        updatedElements.splice(elementIndexToDelete, 1);
        return updatedElements;
      });
      setInitialValues(null)
      setElementIndexToDelete(null);
      closeElementPopup();
      setConfirmDeleteOpen(false);
    }
  };

  const cancelDelete = () => {
    setElementIndexToDelete(null);
    setConfirmDeleteOpen(false);
  };

  useEffect(() => {
    setElements(initialValue || [])
  }, [initialValue]);

  useEffect(() => {
    if(elements){
      setRows(elements)
      setAllergyList && setAllergyList(elements)
    }
  }, [elements]);

  const onSave = () => {
    name && setValue(name, transformData(elements))
  }
  

  let originalRows = initialValue

  useEffect(() => {
    if(originalRows) {
      setRows(originalRows)
    }
  },[initialValue, originalRows] );

  const closeElementPopup = () => {
    setElementPopupOpen(!elementPopupOpen);
  }

  const openElementPopup = (element, index) => {
    setSelectedElement(element);
    setElementPopupOpen(!elementPopupOpen);
    setSelectedElementIndex(index);
  }

  const handleAddElement = () => {
    const newElement = {
        id: 0,
        allergicTo: '',
        allergyId: '',
        reactionsIds: '',
        // medicationPrescribed: '',
        allergyCode: '',
        newElement: true,
        // encounterDate: '',
    }
    setElements([...elements, newElement]);
    openElementPopup(newElement, elements.length)
  };

  const findNameById = (id, array) => {
    const foundItem = array?.find(item => item.id == id);
    return foundItem ? foundItem.name : null;
  };

  return (
    <Box sx={{ width: '100%'}}>
      <StyledPaper sx={{ width: '100%', mb: 2}}>
        <TableContainer  sx={{ overflowX: 'hidden' }}>
          <Table
            sx={{ 
              minWidth: 750,
              padding: '5px',
              marginBottom: '15px',
              
            }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
              <EnhancedTableHead
                rowCount={rows.length}
                colSpan={6} 
              />
            <StyledTableBody>
              {rows?.length && rows.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.clientServiceId}
                    sx={{ 
                      cursor: 'pointer',
                    }}
                    padding='normal'
                  >
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding='normal'
                      style={{
                        color: "#BD986A",
                        cursor: "pointer",
                        borderBottom: "none",
                      }}
                    >
                      
                      {row.allergicTo}
                      {row.allergyId === 1 && 
                        <RowNotice>
                          Active: 
                          <span>{row.isActive ? 'Yes' : 'No'}</span>
                        </RowNotice>
                      }
                      
                    </TableCell>
                    {/* <TableCell align="left">{findNameById(row.type, types)}</TableCell> */}
                    <TableCell align="left">
                      {findNameById(row.allergyId, listOfAllergies)}
                      {row.allergyId === 1 && <RowNotice>
                        Severity:
                        <span>{findNameById(row.severityId, allergySeverity)}</span>
                      </RowNotice>}
                    </TableCell>
                    <TableCell align="left"><ReactionField initialIndexes={row.reactionsIds} /></TableCell>
                    <TableCell align="left">{row.medicationPrescribed}</TableCell>
                    <TableCell align="left">{findNameById(row.codeSystemId, allergyCode)}</TableCell>
                    <TableCell align="left">{row.encounterDate ? moment(row.encounterDate).utc().format('MM/DD/YYYY') : ''}</TableCell>
                    <TableCell align="left">
                    <IconsContainer>
                        <Edit
                          sx={{width:'15px', height:'15px', marginRight: '10px'}}
                          onClick={() => openElementPopup(row, index)}
                        /> 
                        <Delete
                          sx={{width:'15px', height:'15px'}}
                          onClick={() => handleRemoveElement(index)}
                        />
                      </IconsContainer>
                    </TableCell>
                  </TableRow>
                );
              })}
            </StyledTableBody>
          </Table>
          <AllergiesInfoText style={{  marginBottom: '15px'}}>
            <b>R</b> - Respiratory symptoms: Sneezing, runny or stuffy nose (allergic rhinitis), coughing, wheezing, shortness of breath, chest tightness<br/>
            <b>S</b> - Skin reactions: Itching, hives (urticaria), swelling (angioedema)<br/>
            <b>G</b> - Gastrointestinal symptoms: Nausea, vomiting, diarrhea, abdominal pain<br/>
            <b>C</b> - Cardiovascular symptoms: Drop in blood pressure, rapid heartbeat, lightheadedness, fainting (in severe cases)<br/>
            <b>O</b> - Ocular symptoms: Red, itchy, watery eyes (allergic conjunctivitis)<br/>
          </AllergiesInfoText>
          <AddNewButton onClick={handleAddElement}>
              <img src={roundedPlus}/>add new
          </AddNewButton>
        </TableContainer>
      </StyledPaper>
        {selectedElementIndex !== null && <ElementInfoPopup
          open={elementPopupOpen}
          elements={elements}
          onClose={closeElementPopup}
          setElements={setElements}
          index={selectedElementIndex}
          medication={selectedElement}
          allergyCode={allergyCode}
          listOfAllergies={listOfAllergies}
          allergySeverity={allergySeverity}
          handleRemoveElement={handleRemoveElement}
          onSave={onSave}
        />}
        <Modal open={confirmDeleteOpen} onClose={cancelDelete}>
          <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
            <Typography variant="h6">Are you sure you want to delete this element?</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
              <Button
                sx={{
                  marginRight: "10px",
                  width: "108px",
                  height: '26px',
                  borderRadius: '2px'
                }} variant="outlined"
                onClick={cancelDelete}>
                  Cancel
              </Button>
              <Button
                sx={{
                  marginRight: "10px",
                  width: "108px",
                  height: '26px',
                  borderRadius: '2px'
                }} variant="contained"
                onClick={confirmDelete}>
                  Delete
              </Button>
            </Box>
          </Box>
        </Modal>
    </Box>
  );
}