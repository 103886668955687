import React from "react";
import { Box, Button, Grid } from "@mui/material";
import InputField from "../../common/InputField";
import SelectField from "../../common/SelectFieldForForm";
import stateNames from "../../../constants/States";
import { Field } from "formik";

export const AddressInfoForm = (props) => {
  const {
    formField: { address1, address2, city, state, zipcode },
  } = props;
  return (
    <React.Fragment>
      <Grid container rowSpacing={"26px"} columnSpacing={"65px"}>
        <Grid item xs={6}>
          <Field name={address1.name}>
            {({ field, form }) => (
              <InputField
                name={address1.name}
                label={address1.label}
                value={field.value}
                setValue={form.setFieldValue}
                error={form.errors[address1.name]}
                fullWidth
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field name={state.name}>
            {({ field, form }) => (
              <SelectField
                name={state.name}
                label={state.label}
                data={stateNames}
                value={field.value}
                setValue={form.setFieldValue}
                error={form.errors[state.name]}
                fullWidth
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field name={address2.name}>
            {({ field, form }) => (
              <InputField
                name={address2.name}
                label={address2.label}
                value={field.value}
                setValue={form.setFieldValue}
                error={form.errors[address2.name]}
                fullWidth
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field name={zipcode.name}>
            {({ field, form }) => (
              <InputField
                name={zipcode.name}
                label={zipcode.label}
                value={field.value}
                setValue={form.setFieldValue}
                error={form.errors[zipcode.name]}
                fullWidth
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field name={city.name}>
            {({ field, form }) => (
              <InputField
                name={city.name}
                label={city.label}
                value={field.value}
                setValue={form.setFieldValue}
                error={form.errors[city.name]}
                fullWidth
              />
            )}
          </Field>
        </Grid>
      </Grid>
      <Box sx={{ marginTop: "28px", display: "flex", justifyContent: "start" }}>
        <Button type="submit" variant="contained">
          Next
        </Button>
      </Box>
    </React.Fragment>
  );
};
