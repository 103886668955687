    import React, { useEffect, useState } from "react";
    import { Box, Grid, Typography, Button, Modal } from "@mui/material";
    import DatePickerField from "../../../common/DatePickerField";
    import SelectField from "../../../common/SelectFieldForForm";
    import CheckboxField from "../../../common/CheckboxField";
    import InputField from "../../../common/InputField";
    import { AllergiesInfoText, ElementBody, ElementFragment, ElementHeader, PopupBody, PopupFooter } from "./ElementDetailsForm.styled";
    import { Delete } from "@mui/icons-material";
    import useFetchWithMsal from "../../../../utils/useFetchWithMsal";
    import RadioGroup from "../../../common/RadioGroup";
    import { PopupButton } from "../../../calendar/Calendar.styled";
    import CloseIcon from "@mui/icons-material/Close";
    import { ReactionField } from "./ReactionField";

    
    export const ElementInfoPopup = ({
      open,
      elements,
      onClose,
      index,
      setElements,
      allergyCode,
      listOfAllergies,
      allergySeverity,
      handleRemoveElement,
      onSave,
    }) => {

      const [initialValues, setInitialValues] = useState(null); 

      const [unsavedChangesModalOpen, setUnsavedChangesModalOpen] = useState(false);

      const handleOpenUnsavedChangesModal = () => {
        setUnsavedChangesModalOpen(true);
      };

      const handleCloseUnsavedChangesModal = () => {
        setUnsavedChangesModalOpen(false);
      };

      useEffect(() => {
          setInitialValues(elements[index]);
        
      }, [index]);

      const handleCancel = () => {
        if (initialValues) {
          elements[index]?.newElement ?
            setElements(prevElements => {
              const updatedElements = [...prevElements];
              updatedElements.splice(index, 1);
              return updatedElements;
            }) :
           setElements(prevElements => {
            const updatedElements = JSON.parse(JSON.stringify(prevElements));
            updatedElements[index] = initialValues;
            return updatedElements;
          });
        }
        handleCloseUnsavedChangesModal();
        onClose();
      };

    const handlePopupButtonClick = () => {
      const hasUnsavedChanges = elements.some((element, i) => {
        return JSON.stringify(element) !== JSON.stringify(initialValues) && i === index;
      });
      if (hasUnsavedChanges) {
        handleOpenUnsavedChangesModal();
      } else {
        if (elements[index]?.newElement) {
          setElements(prevElements => {
            const updatedElements = [...prevElements];
            updatedElements.splice(index, 1);
            return updatedElements;
          }) 
        }
        // setInitialValues(null)
        onClose();
      }
    };
      const [formErrors, setFormErrors] = useState({});

      const validateForm = () => {
        const errors = {};
        if (!elements[index].allergyId ) {
          errors.allergyId = true;
        }
        if (!elements[index].reactionsIds) {
          errors.reactionsIds = true;
        }
        if(elements[index].allergyId === 1) {
          if (!elements[index].codeSystemId) {
            errors.codeSystemId = true;
          }
          if (!elements[index].encounterDate) {
            errors.encounterDate = true;
          }
          if (!elements[index].severityId) {
            errors.severityId = true;
          }
          if (!elements[index].isActive) {
            errors.isActive = true;
          }
        }
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
      };
    
      const handleSave = () => {
        if (validateForm()) {
          setInitialValues(null);
          onSave();
          onClose();
        }
      };
    
      const getSelectData = (name) => {
        switch (name) {
          case "codeSystemId":
            return allergyCode;
          case "allergyId":
            return listOfAllergies;
          case "severityId":
            return allergySeverity;
          default:

          return [];
        }
      };
    

    
      const getInputComponent = (item, index, width, height) => {
        if(elements[index] !== undefined){
          switch (item?.type) {
            case "select":
              return (
                <SelectField
                  name={item.name}
                  label={item.label}
                  data={getSelectData(item.name)}
                  value={elements[index][item.name]}
                  setValue={(name, value) => handleFieldChange(name, value, index)}
                  required={item.required}
                  error={formErrors[item?.name]}
                  width={width}
                  fullWidth
                />
              );
            case "date":
              return (
                <DatePickerField
                  name={item.name}
                  label={item.label}
                  value={elements[index][item.name]}
                  required={item.required}
                  setValue={(name, value) => handleFieldChange(name, value, index)}
                  error={formErrors[item?.name]}
                />
              );
            case "checkbox":
              return (
                <CheckboxField
                  name={item.name}
                  label={item.label}
                  value={elements[index][item.name]}
                  required={item.required}
                  setValue={(name, value) => handleFieldChange(name, value, index)}
                />
              );
            case "radio":
              return (
                <RadioGroup
                  name={item.name}
                  label={item.label}
                  items={[{id: 1, name: 'Continue'}, {id: 2, name: 'Discontinue'}]}
                  value={elements[index][item.name]}
                  required={item.required}
                  setValue={(name, value) => handleFieldChange(name, value, index)}
                />
              );
            case "number":
              return (
                <InputField
                  name={item?.name}
                  label={item?.label}
                  value={elements[index][item?.name]}
                  setValue={(name, value) => item.maxLength ? 
                    value.length <= item.maxLength && handleFieldChange(name, Number(value), index) :
                    handleFieldChange(name, Number(value), index)
                  }
                  fullWidth
                  width={width}
                  height={height}
                  required={item.required}
                  type='number'
                  maxLength={item.maxLength}
                  step={item.step}
                  error={formErrors[item?.name]}
                />
              );
            case "reactions":
              return (
                <ReactionField
                  label={item?.label}
                  initialIndexes={elements[index][item?.name]}
                  name={item?.name}
                  setValue={(name, value) => handleFieldChange(name, value, index)}
                  error={formErrors[item?.name]}
                  required={item.required}
                />
              );
            
            default:
              return (
                <InputField
                  name={item?.name}
                  label={item?.label}
                  value={elements[index][item?.name]}
                  setValue={(name, value) => handleFieldChange(name, value, index)}
                  fullWidth
                  width={width}
                  height={height}
                  required={item.required}
                  maxLength={item.maxLength}
                  error={formErrors[item?.name]}
                />
              );
          }
        }
      };
    
      const handleFieldChange = (fieldName, value, elementIndex) => {
        setElements(prevElements => {
          const updatedHistory = [...prevElements];
          updatedHistory[elementIndex] = { ...updatedHistory[elementIndex], [fieldName]: value };
          return updatedHistory;
        });
      };
    
      return (
        <Modal
          open={open}
          onClose={onClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <PopupBody>
            <ElementFragment key={index} style={{ maxHeight: '550px' }}>
              <ElementHeader>
                <Box>
                  {elements[index]?.newElement ? 
                    <Typography variant="h2">Add Allergen</Typography> :
                    <Typography variant="h2">Edit Allergen</Typography>}
                </Box>
                <PopupButton aria-label="close" onClick={handlePopupButtonClick}>
                  <CloseIcon />
                </PopupButton>
              </ElementHeader>
              <ElementBody>
        <Grid container rowSpacing={"20px"} columnSpacing={"20px"}>
          <Grid item xs={12}>
            {getInputComponent({ name: "allergyId", label: "Type", type: "select", required: true }, index, '500px')}
          </Grid>
          <Grid item xs={12}>
            {getInputComponent({ name: "reactionsIds", label: "Reaction", type: "reactions", required: true, }, index, '500px')}
            <AllergiesInfoText>
              <b>R</b> - Respiratory symptoms: Sneezing, runny or stuffy nose (allergic rhinitis), coughing, wheezing, shortness of breath, chest tightness<br/>
              <b>S</b> - Skin reactions: Itching, hives (urticaria), swelling (angioedema)<br/>
              <b>G</b> - Gastrointestinal symptoms: Nausea, vomiting, diarrhea, abdominal pain<br/>
              <b>C</b> - Cardiovascular symptoms: Drop in blood pressure, rapid heartbeat, lightheadedness, fainting (in severe cases)<br/>
              <b>O</b> - Ocular symptoms: Red, itchy, watery eyes (allergic conjunctivitis)<br/>
          </AllergiesInfoText>
          </Grid>
          <Grid item xs={12}>
            {getInputComponent({ name: "allergicTo", label: "Allergic to", type: "text" }, index, '500px')}
          </Grid>
          <Grid item xs={12}>
            {getInputComponent({ name: "medicationPrescribed", label: "Medication prescribed", type: "text" }, index, '500px')}
          </Grid>
          {elements[index]?.allergyId === 1 && 
            <>
              <Grid item xs={6}>
                {getInputComponent({ name: "codeSystemId", label: "Code System", type: "select", required: true }, index)}
              </Grid>
              <Grid item xs={6}>
                {getInputComponent({ name: "encounterDate", label: "Encounter Date", type: "date", required: true }, index, '500px')}
              </Grid>
              <Grid item xs={6}>
                {getInputComponent({ name: "severityId", label: "Severity", type: "select", required: true }, index, '500px')}
              </Grid>
              <Grid item xs={6}>
                {getInputComponent({ name: "isActive", label: "Active", type: "checkbox", required: true }, index, '500px')}
              </Grid>
            </>
          }
        </Grid>
      </ElementBody>
      <PopupFooter>
        <Button sx={{ marginRight: "10px", width: "108px", height: '26px', borderRadius: '2px' }} variant="contained" onClick={handleSave}>Save</Button>
        <Button sx={{ marginRight: "10px", width: "108px", height: '26px', borderRadius: '2px' }} variant="outlined" onClick={handlePopupButtonClick}>Close</Button>
        {!elements[index]?.newElement && <Delete sx={{ marginLeft: "auto", cursor: "pointer", width: "14px", }} onClick={() => handleRemoveElement(index)}/>}
        <Modal open={unsavedChangesModalOpen} onClose={handleCloseUnsavedChangesModal}>
          <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
            <Typography variant="h6">Your changes will not be saved</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
              <Button
                sx={{
                  marginRight: "10px",
                  width: "108px",
                  height: '26px',
                  borderRadius: '2px'
                }}
                variant="outlined"
                onClick={handleCloseUnsavedChangesModal}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  marginRight: "10px",
                  width: "108px",
                  height: '26px',
                  borderRadius: '2px'
                }}
                variant="contained"
                onClick={handleCancel}
              >
                Close
              </Button>
            </Box>
          </Box>
        </Modal>
      </PopupFooter>
    </ElementFragment>
  </PopupBody>
</Modal>
      );
    };
    
