import React from "react";
import { Grid, Typography } from "@mui/material";

import useFetchWithMsal from "../../../utils/useFetchWithMsal";
import {
  useFetchClientQuery,
  useGetProfilePictureQuery,
} from "../../../services/ClientService";
import moment from "moment";
import { useParams } from "react-router-dom";
import {  ImgContainer, InfoContainer } from "./AssessmentUserInfo.styled";
import { useGetCompanyBranchesQuery } from "../../../services/UtilityService";

export const AssessmentUserInfo = () => {
  const { skip } = useFetchWithMsal();
  const { id } = useParams();
  const { data: companyBranch } = useGetCompanyBranchesQuery('', {skip});

  const { data } = useFetchClientQuery(id, { skip });
  const { data: profilePicture } = useGetProfilePictureQuery(id, { skip });
  const getCompanyBranch =(id) =>{
    return companyBranch?.find(item => item.id === id)?.name
  }
  return (
    <>
     
        <Grid container columnSpacing={"66px"} mb={"20px"}>
          <Grid item xs={2}>
            <ImgContainer>
              {profilePicture && <img alt="clientPhoto" src={profilePicture} />}
            </ImgContainer>
          </Grid>
          <Grid item xs={10}>
            <InfoContainer>
              <Typography>Name:</Typography>
              <Typography>{`${data?.firstName || ''} ${data?.lastName || ''}`}</Typography>
              <Typography>Company Branch:</Typography>
              <Typography>{getCompanyBranch(data?.companyBranchId) || ''}</Typography>
              <Typography>Birth date:</Typography>
              <Typography>
                {data?.birthDate? moment(data?.birthDate).format("MM/DD/YYYY") : ''}
              </Typography>
            </InfoContainer>
          </Grid>
        </Grid>
    </>
  );
};
