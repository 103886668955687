import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import InputField from "../../common/InputField";
import CheckboxGroup from "../../common/CheckboxGroup";
import { Field } from "formik";

const Item = React.memo(
  ({ field, setValue, value, error,  fields, form }) => {

    useEffect(() => {
        if(value?.length){
          if (!Number(form.values[fields[0].id]) || Number(field.name) > Number(form.values[fields[0].id])) {
            setValue(fields[0].id, field.name);
          }
        } else{
          const sortedFields = fields.slice(2)
          ?.slice()
          .sort((a, b) => b.priority - a.priority);
          const newDropdownValue = sortedFields.find(item => form.values[item.id]?.length > 0)?.name
          setValue(fields[0].id, newDropdownValue || '');
        }
    }, [value]);

    return (
      <>
        <CheckboxGroup
          name={String(field.id)}
          id={field.id}
          setValue={setValue}
          value={value}
          error={error}
          fields={field.possibleValues}
        />
      </>
    );
  }
);

const ASAMCriteriaItem = React.memo(({ formFields }) => {
  const [sortedFields, setSortedFields] = useState([]);

  useEffect(() => {
    if (formFields) {
      const sortedArray = formFields
        ?.slice()
        .sort((a, b) => a.priority - b.priority);
      setSortedFields(sortedArray);
    }
  }, [formFields]);


  return (
    <>
      {sortedFields?.map((item) => {
        return (
          <Grid container mb={"30px"} columnSpacing={"66px"} key={item.id}>
            <Grid item xs={4}>
              <Typography>{item.name}</Typography>
            </Grid>
            <Grid item xs={8}>
              <Field name={item.id}>
                {({ field, form }) => {
                  if (item.type == 6) {
                    return (
                      <Item
                        field={item}
                        form={form}
                        setValue={form.setFieldValue}
                        value={field.value}
                        dropdownValue={form.values[sortedFields[0]?.id]}
                        error={form.errors[item.id]}
                        fields={sortedFields}
                      />
                    );
                  }
                  return ( <InputField
                    name={String(field.id)}
                    value={field.value}
                    setValue={form.setFieldValue}
                    error={form.errors[item.id]}
                    disabled
                    fullWidth
                  />)
                }}
              </Field>
            </Grid>
          </Grid>
        );
      })}
    </>
  );
});
export default ASAMCriteriaItem;
