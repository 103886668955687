import React, { useState } from 'react';
import {
  Box,
  MenuItem,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  TextField,
  Paper,
  styled,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Typography,
} from '@mui/material';
import { FaPlus, FaTrash, FaInfoCircle } from 'react-icons/fa';
import { BillingStatusSelect } from './BillingStatusSelect';
import { useGetCanceledBillingStatusQuery, usePutBillingStatusMutation } from "../../services/ClientService";
import useFetchWithMsal from "../../utils/useFetchWithMsal";
import ErrorAlert from "../../shared/components/ErrorAlert";
import { setError } from "../../store/slices/ErrorSlice";
import { useDispatch } from "react-redux";
import { FaHourglassHalf, FaCheckCircle, FaTimesCircle, FaBan, FaTrashAlt, FaRedoAlt, FaDollarSign, FaFileAlt, FaSyncAlt, FaEraser } from 'react-icons/fa';
import AlarmIcon from '@mui/icons-material/Alarm';

const billingStatusIcons = {
  'Submitted - Pending': <FaHourglassHalf style={{ marginRight: '8px', color: 'gray' }} />,
  'Write-Off': <FaTrashAlt style={{ marginRight: '8px', color: 'gray' }} />,
  'Void': <FaEraser style={{ marginRight: '8px', color: 'gray' }} />,
  'Denied': <FaBan style={{ marginRight: '8px', color: 'gray' }} />,
  'Adjusted': <FaRedoAlt style={{ marginRight: '8px', color: 'gray' }} />,
  'Partial payment': <FaDollarSign style={{ marginRight: '8px', color: 'gray' }} />,
  'Paid in full': <FaCheckCircle style={{ marginRight: '8px', color: 'gray' }} />,
  'Rejected': <FaTimesCircle style={{ marginRight: '8px', color: 'gray' }} />,
  'Un-Billed': <FaFileAlt style={{ marginRight: '8px', color: 'gray' }} />,
  'Re-Submitted': <FaSyncAlt style={{ marginRight: '8px', color: 'gray' }} />,
  'Error': <AlarmIcon style={{ fontSize: '12pt', marginRight: '8px', color: 'gray' }} />,
};
const StyledBillingStatusSelect = styled(Select)(() => ({
  borderRadius: '4px',
}));

const TransparentTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputLabel-root': {
    fontSize: theme.typography.h6.fontSize, // Increase label font size
    margin: '-4px 0', // 2px margin on all labels
  },
}));

const SmallIconButton = styled(IconButton)(() => ({
  fontSize: 16,
  padding: '4px', // Reduce padding for smaller icon size
}));

const BillingEditModal = ({ open, anchorEl, onClose, record, onSave, isLoading, error }) => {
  const [formData, setFormData] = useState({
    ...record,
    client: `${record.clientFirstName} ${record.clientLastName}`,
    provider: `${record.user?.firstName || ''} ${record.user?.lastName || ''}`,
  });
  const [billingStatus, setBillingStatus] = useState(0);
  const [message, setMessage] = useState('');
  const { skip } = useFetchWithMsal();
  const dispatch = useDispatch();

  const { data: canceledBillingStatuses } = useGetCanceledBillingStatusQuery({ skip });
  const [putBillingStatus] = usePutBillingStatusMutation();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const isPaymentEditable = formData.billingStatus !== 'Paid in full';

  const handleSave = () => {
    onSave(formData);
    onClose();
  };

  const handleBillingStatusChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      billingStatus: e.target.value,
    }));
  };

  const checkStatus = () => {
    console.log('Checking status for:', formData);
  };

  const [payments, setPayments] = useState(record.payments || []);

  const handleAddPayment = () => {
    setPayments([...payments, { id: Date.now(), amount: '', date: new Date().toLocaleDateString(), checkNumber: '' }]);
  };

  const handleEditPayment = (id, updatedPayment) => {
    setPayments(payments.map(payment => payment.id === id ? updatedPayment : payment));
  };

  const handleDeletePayment = (id) => {
    setPayments(payments.filter(payment => payment.id !== id));
  };

  return (
    <Dialog
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      aria-labelledby="edit-record-dialog-title"
      fullWidth
    >
      <DialogTitle id="edit-record-dialog-title">
        Edit Billing Record
        <Button
          variant="contained"
          color="primary"
          onClick={checkStatus}
          startIcon={<FaInfoCircle />}
          size="small"
          style={{ position: 'absolute', right: '16px', top: '16px' }}
        >
          Check Status
        </Button>
      </DialogTitle>
      <DialogContent>
        {isLoading ? (
          <CircularProgress />
        ) : error ? (
          <p>Error loading record details</p>
        ) : (
          <Box component="form" noValidate autoComplete="off">
            <Box sx={{ display: 'flex', gap: 2, mb: 2, paddingTop: 2 }}>
              <TransparentTextField
                name="client"
                label="Client"
                value={formData.client || ''}
                fullWidth
                margin="dense"
                disabled
              />
              <TransparentTextField
                name="levelOfCare"
                label="Level of Care"
                value={formData.levelOfCare || ''}
                fullWidth
                margin="dense"
                disabled
              />
            </Box>
            <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
              <TransparentTextField
                name="service"
                label="Service"
                value={formData.name || ''}
                fullWidth
                margin="dense"
                disabled
              />
              <TransparentTextField
                name="serviceDate"
                label="Service Date"
                value={`${new Date(formData.serviceDate).toLocaleDateString()} ${new Date(formData.serviceDate).toLocaleTimeString()}`}
                fullWidth
                margin="dense"
                disabled
              />
            </Box>
            <Box sx={{ display: 'flex', gap: 2, mb: 2, alignItems: 'center' }}>
              <TransparentTextField
                name="rate"
                label="Rate"
                value={new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                }).format(formData.rate || 0)}
                fullWidth
                margin="dense"
                sx={{ flex: 1 }} // Make the rate field half the size
                disabled
              />
              <TransparentTextField
                name="procedureCode"
                label="Procedure Code"
                value={'W7310' || ''}
                fullWidth
                margin="dense"
                sx={{ flex: 1 }} // Make the rate field half the size
                disabled
              />
              <StyledBillingStatusSelect
                labelId="billing-status-select-label"
                id="billing-status-select"
                value={formData.billingStatus || ''}
                onChange={handleBillingStatusChange}
                fullWidth
                sx={{ flex: 2 }} // Make the billing status field larger
              >
                <MenuItem value={formData.billingStatus || ''}>
                  {billingStatusIcons[formData.billingStatus]} {formData.billingStatus}
                </MenuItem>
                {canceledBillingStatuses && canceledBillingStatuses.map(status => formData.billingStatus !== status.name && (
                  <MenuItem
                    key={status.id}
                    value={status.name}
                  >
                    {billingStatusIcons[status.name]} {status.name}
                  </MenuItem>
                ))}
              </StyledBillingStatusSelect>
            </Box>

            <Box sx={{ display: 'flex', gap: 2 }}>
              <TransparentTextField
                name="authorization"
                label="Authorization Code"
                value={formData.authorizationNumber || ''}
                fullWidth
                margin="dense"
                sx={{ flex: 1 }} // Adjust size if necessary
                disabled
              />
              <TransparentTextField
                name="authorizationStatus"
                label="Authorization Status"
                value={formData.authorizationStatus || ''}
                fullWidth
                margin="dense"
                sx={{ flex: 1 }} // Adjust size if necessary
                disabled
              />
            </Box>

            <Box sx={{ mb: 2, paddingTop: 2 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                <Typography variant="h6">Payments</Typography>
                <Button onClick={handleAddPayment} startIcon={<FaPlus />}>
                  Add Payment
                </Button>
              </Box>
              <TableContainer component={Paper}>
                <Table>
                  <TableBody>
                    {payments.map((payment) => (
                      <TableRow key={payment.id}>
                        <TableCell>
                          <TextField
                            type="date"
                            value={new Date(payment.date).toISOString().split('T')[0]}
                            onChange={(e) => handleEditPayment(payment.id, { ...payment, date: e.target.value })}
                            fullWidth
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            label="Amount"
                            type="number"
                            value={payment.amount}
                            onChange={(e) => handleEditPayment(payment.id, { ...payment, amount: e.target.value })}
                            fullWidth
                            inputProps={{ step: '0.01', min: '0' }}
                            InputLabelProps={{
                              sx: {
                                fontSize: "12px",
                                left: "0rem",
                                top: "-0.3rem",
                              }
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            label="Check Number"
                            value={payment.checkNumber}
                            onChange={(e) => handleEditPayment(payment.id, { ...payment, checkNumber: e.target.value })}
                            fullWidth
                            InputLabelProps={{
                              sx: {
                                fontSize: "12px",
                                left: "0rem",
                                top: "-0.3rem",
                              }
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <SmallIconButton onClick={() => handleDeletePayment(payment.id)}>
                            <FaTrash />
                          </SmallIconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="secondary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BillingEditModal;