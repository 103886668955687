const tabsNames = [
    'Basic information',
    'Contact Information',
    'Insurance',
    'Authorizations',
    'Progress Notes',
    // 'Military Service',
    // 'Notifications',
    'Medication List',
    'Allergies',
  ];
  export default tabsNames