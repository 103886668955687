import { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styled from '@emotion/styled';
import moment from 'moment';
import useFetchWithMsal from "../../../../utils/useFetchWithMsal";
import { useGetClientMedicationDoseTypeQuery, useGetClientMedicationFrequencyQuery, useGetClientMedicationMethodQuery } from "../../../../services/ClientService";
import { MedicationsFilters } from "./MedicationFilters";
import { MedicationPopup } from "./MedicationPopup";
import { AddNewButton, Circle } from "./MedicationDetailsForm.styled";
import roundedPlus from "../../../../shared/icons/roundedPlus.svg";
import { EmptyMessage } from "../../../bilingReport/BilingReport.styled";

const StyledPaper = styled(Paper)(() => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
}));

const StyledTableHead = styled(TableHead)(() => ({
  th: {
    whiteSpace: 'nowrap',
    background: '#fff',
    color: '#4281AB',
    paddingTop: '6px',
    paddingBottom: '8px',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: '600',
    borderBottom: "1px solid #b2cede",
  },
  '&::after': {
    height: '8px',
    display: 'table-row',
    content: '""'
  }
}));

const StyledTableBody = styled(TableBody)(({ theme }) => ({
  td: {
    color: '#000',
    paddingTop: '5px',
    paddingBottom: '5px',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: '400',
    borderBottom: "none",
  },
  th: {
    paddingTop: '5px',
    paddingBottom: '5px',
    minWidth: '120px',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: '400',
    maxWidth: '300px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    a: {
      color: theme.palette.background.main,
      textDecoration: 'none',
    }
  },
  tr: {
    background: '#fff',
    height: '30px',
    '&:nth-of-type(odd)': {
      background: '#EFF5F8',
    },
  }
}));

const headCells = [
  {
    id: 'active',
    numeric: true,
    disablePadding: true,
    enableSorting: false
  },
  {
    id: 'medicationName',
    numeric: false,
    disablePadding: false,
    label: 'Medication name',
    enableSorting: false
  },
  {
    id: 'medicationDose',
    numeric: false,
    disablePadding: false,
    label: 'Medication dose',
    enableSorting: false,
  },
  {
    id: 'medicationEndDate',
    numeric: false,
    disablePadding: false,
    label: 'Medication End Date',
    enableSorting: false,
  },
];

function EnhancedTableHead() {
  return (
    <StyledTableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding='normal'
            sx={{
              minWidth: headCell.id === 'firstName' ? '250px' : 'auto',
            }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </StyledTableHead>
  );
}

export const MedicationDetailsFormGrid = ({ values, setMedications, setValue, name }) => {
  const initialValue = values?.items?.length ? values.items : values?.medicationList?.items
  const [medicationDetails, setMedicationDetails] = useState(initialValue || []);
  const [filteredMedications, setFilteredMedications] = useState(initialValue || []);
  const [medicationPopupOpen, setMedicationPopupOpen] = useState(false);
  const [selectedMedication, setSelectedMedication] = useState({});
  const [selectedMedicationIndex, setSelectedMedicationIndex] = useState(null);
  const [selectedAdministrationInstructionsIndex, setSelectedAdministrationInstructionsIndex] = useState(null);

  const { skip } = useFetchWithMsal();

  const { data: doseType } = useGetClientMedicationDoseTypeQuery("", { skip });
  const { data: frequency } = useGetClientMedicationFrequencyQuery("", { skip });
  const { data: method } = useGetClientMedicationMethodQuery("", { skip });

  const [rows, setRows] = useState([]);

  useEffect(() => {
    if(initialValue) {
      setMedicationDetails(initialValue);
    }
  }, [initialValue]);

  useEffect(() => {
    setFilteredMedications([{}])
  }, [medicationDetails.length]);

  useEffect(() => {
    setMedications && setMedications(medicationDetails)
    setRows(medicationDetails)
  }, [medicationDetails, filteredMedications]);

  const transformData = (data) => {
    const transformedData = {
        type: 17,
        items: data.map(item => {
            const { newElement, ...rest } = item;
            return rest;
        })
    };

    return transformedData;
  };

  const onSave = () => {
    name && setValue(name, transformData(medicationDetails))
  }

  let originalRows = initialValue

  useEffect(() => {
    if(originalRows) {
      setRows(originalRows)
    }
  },[initialValue, originalRows] );

  const closeMedicationPopup = () => {
    setMedicationPopupOpen(!medicationPopupOpen);
    setSelectedAdministrationInstructionsIndex(null);
  }

  const openMedicationPopup = (medication, index) => {
    setSelectedMedication(medication);
    setMedicationPopupOpen(!medicationPopupOpen);
    setSelectedMedicationIndex(index);
  }

  const handleAddMedication = () => {
    const newMedication = {
      newMedication: true,
      clientMedicationListId: 5,
      controlled: false,
      medicationName: "",
      medicationDose: "",
      quantityPrescribed: null,
      medicationEndDate: null,
      totalRefillsAllowed: 0,
      earliestRefillDate: null,
      refillsUntil: null,
      prescriberId: null,
      prescriberPhone: null,
      pharmacy: null,
      pharmacyPhone: "",
      medicalReasonReasonforOrder: "",
      prescriptionDate: null,
      continueStatus: 1,
      discontinueDate: null,
      administrationInstructions: [{
        clientMedicationMethodId: 1,
        quantity: null,
        clientMedicationDoseTypeId: 1,
        clientMedicationFrequencyId: 1,
        timeOfDayOrOtherDirections: null
      }]
    }
    setMedicationDetails(prevDetails => [...prevDetails, newMedication]);
    openMedicationPopup(newMedication, medicationDetails.length)
  };

  const handleAddAdministrationInstructions = (index, instructionsCount) => {
    setMedicationDetails(prevDetails => {
      const updatedDetails = [...prevDetails];
      const newAdministrationInstructions = {
        clientMedicationMethodId: 1,
        quantity: null,
        clientMedicationDoseTypeId: 1,
        clientMedicationFrequencyId: 1,
        timeOfDayOrOtherDirections: null,
        newElement: true,
      };
      if (!updatedDetails[index].administrationInstructions) {
        updatedDetails[index] = {
          ...updatedDetails[index],
          administrationInstructions: [newAdministrationInstructions]
        };
      } else {
        updatedDetails[index] = {
          ...updatedDetails[index],
          administrationInstructions: [
            ...updatedDetails[index].administrationInstructions,
            newAdministrationInstructions
          ]
        };
      }
      return updatedDetails;
    });
    setSelectedAdministrationInstructionsIndex(instructionsCount)
  };

  return (
    <Box sx={{ width: '100%'}}>
      <MedicationsFilters data={rows} setFilteredMedications={setFilteredMedications} />
      <StyledPaper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ 
              minWidth: 750,
              padding: '5px',
              marginBottom: '15px',
            }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
              <EnhancedTableHead
                rowCount={rows.length}
                colSpan={6} 
              />
            <StyledTableBody>
              {rows?.length ? rows.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                const shouldDisplay = filteredMedications.some(item => item.id === row.id);
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.clientServiceId}
                    sx={{ 
                      cursor: 'pointer',
                    }}
                    padding='normal'
                    style={{display: shouldDisplay ? 'table-row' : 'none'}}
                  >
                    <TableCell align="left"><Circle active={row.isActive} /></TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding='normal'
                      onClick={() => openMedicationPopup(row, index)}
                      style={{
                        color: "#BD986A",
                        cursor: "pointer",
                        borderBottom: "none",
                      }}
                    >
                      {row.medicationName}
                    </TableCell>
                    <TableCell align="left">{row.medicationDose}</TableCell>
                    <TableCell align="left">{row.medicationEndDate ? moment(row.medicationEndDate).utc().format('MM/DD/YYYY') : ''}</TableCell>
                  </TableRow>
                );
              }) : <EmptyMessage>There is no data yet.</EmptyMessage>}
            </StyledTableBody>
          </Table>
          <AddNewButton onClick={handleAddMedication}>
              <img src={roundedPlus}/>add new
          </AddNewButton>
        </TableContainer>
      </StyledPaper>
        {medicationPopupOpen && <MedicationPopup
          open={medicationPopupOpen}
          medicationDetails={medicationDetails}
          onClose={closeMedicationPopup}
          values={values}
          index={selectedMedicationIndex}
          setMedicationDetails={setMedicationDetails}
          medication={selectedMedication}
          handleAddAdministrationInstructions={handleAddAdministrationInstructions}
          setSelectedAdministrationInstructionsIndex={setSelectedAdministrationInstructionsIndex}
          selectedAdministrationInstructionsIndex={selectedAdministrationInstructionsIndex}
          doseType={doseType}
          frequency={frequency}
          method={method}
          onSave={onSave}
        />}
    </Box>
  );
}