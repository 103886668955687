import { NoteContainer, NoteDate, NoteHeader, NoteName, NoteText, NotesCardStatus } from "../TableTab.styled";
import moment from "moment";

export const NotesGridField = (props) => {
  const { types, notes} = props;

  const getNameById = id => {
    const foundItem = types?.find(item => item.id === id);

    return foundItem ? foundItem.name : null;
  }

  return (
    <>
        {notes?.slice(0, 2).map((note) => (
            <NoteContainer key={note.id}>
                <NoteHeader>
                <NoteName>{note?.owner?.firstName} {note?.owner?.lastName}</NoteName>
                <NotesCardStatus>
                    {getNameById(note?.quickNoteTypeId)}
                </NotesCardStatus>
                </NoteHeader>
                <NoteDate>{moment(note?.createDate).format("MM/DD/YYYY HH:mm")}</NoteDate>
                <NoteText>
                    {note?.text}
                </NoteText>
            </NoteContainer>
        ))}
    </>
  );
}