import { useState } from "react";
import { Box, Button, MenuItem, Select, styled } from "@mui/material";
import { useGetCanceledBillingStatusQuery, usePutBillingStatusMutation } from "../../services/ClientService";
import useFetchWithMsal from "../../utils/useFetchWithMsal";
import ErrorAlert from "../../shared/components/ErrorAlert";
import { setError } from "../../store/slices/ErrorSlice";
import { useDispatch } from "react-redux";

const StyledAuthorizationStatusSelect = styled(Select)(() => ({
    background: '#fff',
}));

const StyledButton = styled(Button)(() => ({
    color: 'rgb(189, 152, 106)',
    fontSize: '10px',
    marginLeft: '10px'
}));

export const AuthorizationStatusSelect = ({name, id}) => {
  const [billingStatus, setBillingStatus] = useState(0);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const { skip } = useFetchWithMsal();
  const dispatch = useDispatch();

  const { data: canceledBillingStatuses } = useGetCanceledBillingStatusQuery({ skip });
  const [putBillingStatus, { error }] = usePutBillingStatusMutation();

  const handleBillingStatusChange = (event) => {
    setBillingStatus(event.target.value);
  };

  const changeBillingStatus = () => {
    putBillingStatus({ id: id, statusId: billingStatus })
    .then(response => {
      if(response?.error?.message) {
        setOpen(true);
        setMessage(response?.error?.message)
      } else {
        setOpen(true);
        setMessage('Status changed successfully')
      }
    })
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    dispatch(setError(""));
  };

  return (
    <Box sx={{display:'flex', flexDirection: 'column'}}>
        <StyledAuthorizationStatusSelect
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={billingStatus}
            onChange={handleBillingStatusChange}
            label="Age"
        >
            <MenuItem value={0}>{name}</MenuItem>
            {canceledBillingStatuses && canceledBillingStatuses.map(status => name !== status.name && (
              <MenuItem key={status.id} value={status.id}>{status.name}</MenuItem>
            ))}
        </StyledAuthorizationStatusSelect>
        <StyledButton onClick={() => changeBillingStatus()} disabled={billingStatus === 0} variant="text">Change status</StyledButton>
        <ErrorAlert severity={message === 'Status changed successfully' ? 'success' : 'error'} handleClose={handleClose} open={open} errorMessage={message} />
    </Box>

  );
}