import React from "react";
import { FormHelperText, InputLabel, TextField, TextareaAutosize, styled } from "@mui/material";
import { ErrorMessage } from "formik";

export const TextareaFieldStyled = styled(TextareaAutosize)(
  ({}) => `
  background: rgba(248, 239, 231, 1);
  padding: 5px;
  border: none;
  resize: none;
  width: 100%;
  border-radius: 4px;
  margin-top: 10px;
`,
);

const TextareaField = (props) => {
  const { value, name, setValue, error, label, height, width, type, multiline, minRows, ...rest } =
    props;
 
  return (
    <>
      {label && (
        <InputLabel htmlFor="input" sx={{ height: "14px", marginBottom: '0' }}>
          {label}
        </InputLabel>
      )}
      <TextareaFieldStyled
        {...rest}
        type={type || "text"}
        size="small"
        value={value}
        error={!!error}
        onChange={(e) => setValue(name, e.target.value)}
        InputProps={{
          style: { height: height || "30px", maxWidth: width || "290px", width:'100%' },
        }}
        multiline={multiline || false}
        minRows={minRows || 3}
      />
      <ErrorMessage component="div" name={name} className="input-feedback">
        {(msg) => <FormHelperText error>{msg}</FormHelperText>}
      </ErrorMessage>
    </>
  );
};

export default React.memo(TextareaField);
