import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import DatePickerField from '../common/DatePickerField';
import { useGetAssignmentClientsMutation, useGetAuthorizationListQuery, useGetBillingStatusQuery, useGetLevelOfCareQuery, useGetServicesQuery } from '../../services/ClientService';
import AutocompleteWithCheckboxes from '../../shared/components/AutocompleteWithCheckboxes';
import useFetchWithMsal from '../../utils/useFetchWithMsal';

export const AuthorizationReportFilters = ({
    handleServiceDateChange,
    serviceDate,
    handleClientIdChange,
    clientIdList,
    handleServiceChange,
    service,
    handleLevelOfCareChange,
    levelOfCare,
    handleBillingStatusChange,
    billingStatus,
    handleAuthorizationListChange,
    authorizationList,
}) => {
    const { skip } = useFetchWithMsal();

    const [getClients, { data: clientsData}] = useGetAssignmentClientsMutation({
        startDate: null,
        endDate: null,
        isShowAll: true
      }, {skip})
    const { data: services } = useGetServicesQuery({ skip });
    const { data: levelOfCareList } = useGetLevelOfCareQuery({ skip });
    const { data: billingStatuses } = useGetBillingStatusQuery({ skip });
    const { data: authorizationListData } = useGetAuthorizationListQuery({ skip });

    
    useEffect(() => {
    if (!skip || !clientsData) {
        getClients({
        startDate: null,
        endDate: null,
        isShowAll: true
        })
    }
    },[skip]);

    const reformatClients = () => {
    if(clientsData){
        return clientsData.map(item => ({
        name: `${item.firstName} ${item.lastName}`,
        id: item.id
        }));
    }
    }

    return (
        <div style={{flex: 1}}>
            <Grid container xs={12} spacing={2} alignItems="center" sx={{marginBottom: "20px"}}>
                <Grid item xs={4}>
                    <DatePickerField
                        label="Service Date"
                        inputFormat="MM/dd/yyyy"
                        value={serviceDate}
                        setValue={handleServiceDateChange}
                        width="100%"
                    />
                </Grid>
                <Grid item xs={4}>
                    <AutocompleteWithCheckboxes
                        multiple
                        name={"Clients"}
                        label={"Clients"}
                        value={clientIdList}
                        data={reformatClients()}
                        setValue={handleClientIdChange}
                        width={'100%'}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <AutocompleteWithCheckboxes
                        multiple
                        name={"Billing Status"}
                        label={"Billing Status"}
                        value={billingStatus}
                        data={billingStatuses}
                        setValue={handleBillingStatusChange}
                        width={'100%'}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <AutocompleteWithCheckboxes
                        multiple
                        name={"Service"}
                        label={"Service"}
                        value={service}
                        data={services}
                        setValue={handleServiceChange}
                        width={'100%'}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <AutocompleteWithCheckboxes
                        multiple
                        name={"Level Of Care"}
                        label={"Level Of Care"}
                        value={levelOfCare}
                        data={levelOfCareList}
                        setValue={handleLevelOfCareChange}
                        width={'100%'}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <AutocompleteWithCheckboxes
                        multiple
                        name={"Authorization"}
                        label={"Authorization"}
                        value={authorizationList}
                        data={authorizationListData}
                        setValue={handleAuthorizationListChange}
                        width={'100%'}
                        fullWidth
                    />
                </Grid>
            </Grid>
        </div>
    );
};
