import { Box, styled } from "@mui/material";

export const AssignmentHistory = styled(Box)({
  fontFamily: 'Open Sans',
  display: "flex",
  justifyContent: 'space-between',
  width: "100%",
  fontSize: '12px',
  marginBottom: '15px',
});

export const HistoryDate = styled(Box)({
  fontWeight: '600',
  color: '#000',
  marginRight: '28px',
});

export const HistoryName = styled(Box)({
  fontWeight: '300',
  color: '#000',
  marginRight: '12px',
  minWidth: '80px',
});

export const CloseButton = styled(Box)({
  marginTop: '10px',
  cursor: 'pointer',
  fontFamily: 'Lato',
  fontSize: '12px',
  fontWeight: '600',
  color: '#2E7BA3',
});
