import Box from "@mui/material/Box";
import { DeleteButtonContainer, DropzoneArea, FileDowloadTabContainer, FileNameContainer, FileNameText, IconsContainer, StyledButton } from "./FileUpload.styled";
import { useState, useEffect } from "react";
import Dropzone from 'react-dropzone'
import dropzoneSvg from "../../shared/icons/dropzone.svg";
import { Delete, FileOpen } from "@mui/icons-material";
import { useDeleteUrineScreenFileMutation } from "../../services/ClientService";
import ErrorAlert from "../../shared/components/ErrorAlert";
import { useDispatch } from "react-redux";
import { setError } from "../../store/slices/ErrorSlice";

export const FileUpload = ({name, value, setValue, buttonValue}) => {

  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();
  const [deleteUrineScreenFile] = useDeleteUrineScreenFileMutation();
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    dispatch(setError(""));
  };
  

  const handleImageUpload = (data) => {
    const file = data[0];
    setFileName(file?.name)
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64String = e.target.result;
        name ? setFile({
          type: 13,
          base64Data: base64String.split(",")[1],
          contentType: file.type,
          fileName: file?.name
        }) :
        setFile({
          base64Data: base64String.split(",")[1],
          contentType: file.type,
          fileName: file?.name
        })
        ;
      };
      
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    setFile({
        id: value?.id,
        base64Data: value?.data,
        contentType: value?.contentType,
    });
    setFileName(value?.fileName)
  }, []);

  useEffect(() => {
    setValue(name, file)
  }, [file]);

  const getFile = () => {
        return `data:${file?.contentType};base64,${file?.base64Data}`
  }

  const openPdfInNewWindow = async () => {
    const file = getFile();
    
    if (file && file.startsWith('data:application/pdf')) {
        try {
            const response = await fetch(file);
            if (!response.ok) {
                throw new Error('Failed to fetch file');
            }
            const blob = await response.blob();
            const fileSize = blob.size; // размер файла в байтах
            if(fileSize < 20000000) {
              const newWindow = window.open();
              newWindow.document.write('<iframe width="100%" height="100%" src="' + file + '"></iframe>');
            } else {
              setOpen(true);
              setErrorMessage('Your file is too large')
            }
        } catch (error) {
            console.error('Error fetching file:', error);
        }
    } else {
      setOpen(true);
      setErrorMessage('Could not open preview: File format is not pdf')
    }
};

  const deleteFileHandler = () => {
    deleteUrineScreenFile(value?.id || null).then(() => 
      setFile(null),
      setFileName('')
    )
  }

  const renderFileButton = () => {
      return (
        <FileNameContainer>
            <IconsContainer>
              {name && <FileOpen onClick={openPdfInNewWindow} style={{cursor: "pointer"}}/>}
              <DeleteButtonContainer>
                <Delete onClick={deleteFileHandler} style={{cursor: "pointer", fontSize: 15}} />
              </DeleteButtonContainer>
            </IconsContainer>
            <FileNameText>{fileName}</FileNameText>
        </FileNameContainer>
      );
  }

  return (
      <>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <FileDowloadTabContainer>
            {fileName && renderFileButton()}
            <Dropzone onDrop={handleImageUpload}>
              {({getRootProps, getInputProps}) => (
                <>
                  <DropzoneArea {...getRootProps()}>
                    <input {...getInputProps()} />
                      <>
                        <img src={dropzoneSvg} alt="dropzone"/>
                        <StyledButton component="label" variant="outlined" size="small">
                          {buttonValue || 'Browse File'}
                        </StyledButton>
                      </>
                  </DropzoneArea>
                </>
              )}
            </Dropzone>
          </FileDowloadTabContainer>
          <ErrorAlert handleClose={handleClose} open={open} errorMessage={errorMessage} />
        </Box>
      </>
  );
};
