import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { FirstTypeElementComponent } from './FirstTypeElementComponent';
import { SecondTypeElementComponent } from './SecondTypeElementComponent';
import { ThirdTypeElementComponent } from './ThirdTypeElementComponent';

const DynamicElementsComponent = ({name, setValue, value, type}) => {
  const [elements, setElements] = useState(value ? value : []);

  const transformData = (data) => {
    const transformedData = {
        type: type,
        items: data
    };

    return transformedData;
  };

  useEffect(() => {
    setValue(name, transformData(elements))
  }, [elements]);
  const addElement = () => {
    let newElement;
    switch (type) {
      case 9:
        newElement = { Goal: '', StartDate: null, EndDate: null, Intervention: '', Objectives: '' };
        break;
      case 11:
        newElement = { Goal: '', TargetDate: null, CompletedDate: null };
        break;
      case 12:
        newElement = { Substance: '', 'Test Result': null, 'Measured Result': null, 'Reference Range': null, 'SubstanceNewValue': '' };
        break;
      default:
        newElement = null;
    }
    setElements([...elements, newElement]);
  };

  const updateElement = (index, updatedElement) => {
    const updatedElements = [...elements];
    updatedElements[index] = updatedElement;
    updatedElements[index].Id = index+1;
    setElements(updatedElements);
  };

  const deleteElement = (index) => {
    const updatedElements = [...elements];
    updatedElements.splice(index, 1);
    setElements(updatedElements);
  };

  return (
    <div>
      <Button onClick={addElement} variant="contained" color="primary">Add item</Button>
      {elements && elements.map((element, index) => (
        <div key={index} style={{marginTop: '20px', paddingLeft: '42px'}}>
          <Typography variant="h2" gutterBottom>Item {index + 1}</Typography>
          {type === 9 && <FirstTypeElementComponent
            element={element}
            onChange={(updatedElement) => updateElement(index, updatedElement)}
            onDelete={() => deleteElement(index)}
            setValue={setValue}
            type={type}
          />}
          {type === 11 && <SecondTypeElementComponent
            element={element}
            onChange={(updatedElement) => updateElement(index, updatedElement)}
            onDelete={() => deleteElement(index)}
            setValue={setValue}
            type={type}
          />}
          {type === 12 && <ThirdTypeElementComponent
            element={element}
            onChange={(updatedElement) => updateElement(index, updatedElement)}
            onDelete={() => deleteElement(index)}
            setValue={setValue}
            type={type}
          />}
          {type === 15 && <ThirdTypeElementComponent
            element={element}
            onChange={(updatedElement) => updateElement(index, updatedElement)}
            onDelete={() => deleteElement(index)}
            setValue={setValue}
            type={12}
          />}
        </div>
      ))}
    </div>
  );
};

export default DynamicElementsComponent;
