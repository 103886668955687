import { useState, useEffect, useMemo, useCallback } from "react";
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { Grid, Box, Chip } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { Page, PDFDownloadLink } from '@react-pdf/renderer';
import InvoiceReport from '../reports/InvoiceReport';
import { visuallyHidden } from '@mui/utils';
import { GridToolbar } from '@mui/x-data-grid';
import {
  ButtonContainer,
  ButtonsContainer,
  ClientsTitleContainer,
  EmptyMessage,
  HeaderContainer,
  ResultData,
  TableControlsContainer,
  TableSearchContainer,
  ToolsImg,
  UploadFileContainer,
  StyledButton,
} from './BilingReport.styled';
import {
  useDownloadFile837Mutation, useGetBillingReportMutation, usePostUpload835FileMutation, useGetClientServiceBillingHistoryQuery, useGetAssignmentClientsMutation,
  useGetAuthorizationListQuery,
  useGetBillingStatusQuery,
  useGetLevelOfCareQuery,
  useGetServicesQuery,
} from '../../services/ClientService';
import styled from '@emotion/styled';
import moment from 'moment';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { LoadingPanel } from '../../shared/components/LoadingPanel';
import useFetchWithMsal from '../../utils/useFetchWithMsal';
import { BilingReportFilters } from "./BilingReportFilters";
import BillingEditModal from "./BillingEditModal";
import BillingStatusTotals from './BillingStatusTotals';
import exportIcon from "../../shared/icons/export.svg";
import { CustomButton } from "../table/TableTab";
import { FileUpload } from "../common/FileUpload";
import { UploadFile } from "@mui/icons-material";
import ErrorAlert from "../../shared/components/ErrorAlert";
import { useDispatch } from "react-redux";
import { setError } from "../../store/slices/ErrorSlice";
import { BillingStatusSelect } from "./BillingStatusSelect";
import { IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, Popover, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AlarmIcon from '@mui/icons-material/Alarm';
import { BsThreeDots } from 'react-icons/bs';
import { FaHistory, FaUpload, FaHourglassHalf, FaCheckCircle, FaTimesCircle, FaBan, FaTrashAlt, FaRedoAlt, FaDollarSign, FaFileAlt, FaSyncAlt, FaEraser, FaReceipt, FaInfo, FaEdit, FaFilter, FaPaperPlane } from 'react-icons/fa';
import FilterListIcon from "@mui/icons-material/FilterList";

const StyledPaper = styled(Paper)(() => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
}));

const StyledTableHead = styled(TableHead)(() => ({
  th: {
    whiteSpace: 'nowrap',
    background: '#fff',
    color: '#4281AB',
    paddingTop: '6px',
    paddingBottom: '8px',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: '600',
    borderBottom: "1px solid #b2cede",
  },
  '&::after': {
    height: '8px',
    display: 'table-row',
    content: '""'
  }
}));

const StyledTableBody = styled(TableBody)(({ theme }) => ({
  td: {
    color: '#000',
    paddingTop: '15px',
    paddingBottom: '15px',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: '400',
  },
  th: {
    paddingTop: '15px',
    paddingBottom: '15px',
    minWidth: '120px',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: '400',
    a: {
      color: theme.palette.background.main,
      textDecoration: 'none',
    }
  },
  tr: {
    background: '#fff',
    boxShadow: '0px 1px 2px 0px #00000040',
  }
}));

const StyledPagination = styled(TablePagination)(({ theme }) => ({
  '& .MuiInputBase-root': {
    background: theme.palette.background.mainBlue
  }
}));

function stableSort(array) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  return stabilizedThis?.map((el) => el[0]);
}

const billingStatusIcons = {
  'Submitted - Pending': <FaHourglassHalf style={{ marginRight: '8px', color: 'gray' }} />,
  'Write-Off': <FaTrashAlt style={{ marginRight: '8px', color: 'gray' }} />,
  'Void': <FaEraser style={{ marginRight: '8px', color: 'gray' }} />,
  'Denied': <FaBan style={{ marginRight: '8px', color: 'gray' }} />,
  'Adjusted': <FaRedoAlt style={{ marginRight: '8px', color: 'gray' }} />,
  'Partial payment': <FaDollarSign style={{ marginRight: '8px', color: 'gray' }} />,
  'Paid in full': <FaCheckCircle style={{ marginRight: '8px', color: 'gray' }} />,
  'Rejected': <FaTimesCircle style={{ marginRight: '8px', color: 'gray' }} />,
  'Un-Billed': <FaFileAlt style={{ marginRight: '8px', color: 'gray' }} />,
  'Re-Submitted': <FaSyncAlt style={{ marginRight: '8px', color: 'gray' }} />,
  'Error': <AlarmIcon style={{ marginRight: '8px', color: 'gray' }} />,
};

const billingStatusColors = {
  'Submitted - Pending': '#f0f0f0',
  'Write-Off': '#e0e0e0',
  'Void': '#ffe0e0',
  'Denied': '#ffe0e0',
  'Adjusted': '#f0f0e0',
  'Partial payment': '#e0ffe0',
  'Paid in full': '#e0ffe0',
  'Rejected': '#ffe0e0',
  'Un-Billed': '#f0f0f0',
  'Re-Submitted': '#e0f0ff',
  'Error': '#ffe0e0',
};

const CustomLoadingOverlay = () => (
  <Box
    sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(255, 255, 255, 0.7)', // Optional: for a semi-transparent background
      zIndex: 1,
    }}
  >
    <CircularProgress />
  </Box>
);

const headCells = [
  {
    id: 'clientFirstName',
    numeric: false,
    disablePadding: false,
    label: 'Client',
    sortable: true,
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Service',
    sortable: true,
  },
  {
    id: 'levelOfCare',
    numeric: false,
    disablePadding: false,
    label: 'Level Of Care',
    sortable: true,
  },
  {
    id: 'user',
    numeric: false,
    disablePadding: false,
    label: 'Provider',
    sortable: true,
  },
  {
    id: 'serviceDate',
    numeric: false,
    disablePadding: false,
    label: 'Service Date',
    sortable: true,
  },
  {
    id: 'startTime',
    numeric: false,
    disablePadding: true,
    label: 'Start Time',
    sortable: false
  },
  {
    id: 'endTime',
    numeric: false,
    disablePadding: true,
    label: 'End Time',
    sortable: false
  },
  {
    id: 'billingStatus',
    numeric: false,
    disablePadding: false,
    label: 'Billing Status',
    sortable: true,
  },
  {
    id: 'billingHistory',
    numeric: false,
    disablePadding: false,
    label: '',
    sortable: false,
  },
  {
    id: 'rate',
    numeric: false,
    disablePadding: false,
    label: 'Rate',
    sortable: true,
    align: 'right',
  },
  {
    id: 'totalPayment',
    numeric: false,
    disablePadding: false,
    label: 'Payment',
    sortable: true,
    align: 'right',
  }, {
    id: 'buttons',
    numeric: false,
    disablePadding: false,
    label: ' ',
    sortable: true,
  },
];

const CustomToolbar = () => (
  <GridToolbar showQuickFilter={false} quickFilterProps={{ debounceMs: 500 }} />
);

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <StyledTableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align || (headCell.numeric ? 'right' : 'left')}  // Default to left if no align specified
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={headCell.sortable ? createSortHandler(headCell.id) : undefined}
            >
              {headCell.label}
              {headCell.sortable && orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>

        ))}
      </TableRow>
    </StyledTableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


const getNameById = (id, data) => {
  console.log('getNameById id:', id);
  console.log('getNameById data:', data);
  const item = data?.find((entry) => entry.id === id);
  return item ? item.name : "Unknown Status"; // Default to "Unknown Status" if not found
};


export const BilingReport = () => {
  const { skip, setSkip } = useFetchWithMsal();
  const dispatch = useDispatch();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [serviceDate, setServiceDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [clientIdList, setClientIdList] = useState([]);
  const [service, setService] = useState([]);
  const [authorizationList, setAuthorizationList] = useState([]);
  const [levelOfCare, setLevelOfCare] = useState([]);
  const [billingStatus, setBillingStatus] = useState([1]);
  const [fileValue835, setFileValue835] = useState(null);
  const [uploadMessage, setUploadMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [serviceName, setServiceName] = useState(0);
  const [uploadSuccessful, setUploadSuccessful] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isHistoryDialogOpen, setIsHistoryDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const isFilterPopoverOpen = Boolean(filterAnchorEl);

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? 'billing-history-popover' : undefined;
  const serviceId = selectedRecord?.id;

  /*   useEffect(() => {
      // This will be triggered only once after the component mounts
      setSkip(false);
    }, []); // Empty dependency array to make this effect run only once */



  const [getClients, { data: clientsData }] = useGetAssignmentClientsMutation({
    startDate: null,
    endDate: null,
    isShowAll: true
  }, { skip })
  const { data: services } = useGetServicesQuery({ skip });
  const { data: levelOfCareList } = useGetLevelOfCareQuery({ skip });
  const { data: billingStatuses } = useGetBillingStatusQuery({ skip });
  const { data: authorizationListData } = useGetAuthorizationListQuery({ skip });
  const memoizedClientIdList = useMemo(() => clientIdList, [clientIdList]);
  const memoizedService = useMemo(() => service, [service]);
  const memoizedLevelOfCare = useMemo(() => levelOfCare, [levelOfCare]);
  const memoizedAuthorizationList = useMemo(() => authorizationList, [authorizationList]);
  const memoizedBillingStatus = useMemo(() => billingStatus, [billingStatus]);

  const [tempServiceDate, setTempServiceDate] = useState(serviceDate);
  const [tempClientIdList, setTempClientIdList] = useState(clientIdList);
  const [tempService, setTempService] = useState(service);
  const [tempLevelOfCare, setTempLevelOfCare] = useState(levelOfCare);
  const [tempBillingStatus, setTempBillingStatus] = useState(billingStatus);
  const [tempAuthorizationList, setTempAuthorizationList] = useState(authorizationList);

  /*   useEffect(() => {
      if (!skip) {
        getClients();
      }
    }, [skip, getClients]); */

  const handleFilterDialogOpen = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterDialogClose = () => {
    setFilterAnchorEl(null);
  };

  const columns = [
    {
      field: 'client',
      headerName: 'Client',
      width: 200,
      renderCell: (params) => `${params.row.clientFirstName} ${params.row.clientLastName}`,
    },
    {
      field: 'name',
      headerName: 'Service',
      width: 250,
      renderCell: (params) => (
        <Box
          sx={{
            marginTop: '12px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start', // Align content to the left
            justifyContent: 'flex-start', // Align content to the top
            lineHeight: '1.2',
          }}
        >
          <span>{params.row.name}</span> {/* First line with the name */}
          <span style={{ fontSize: '0.85em', color: '#666' }}>{params.row.procedureCode}</span> {/* Second line with procedureCode */}
        </Box>
      ),
    },
    { field: 'levelOfCare', headerName: 'Level Of Care', width: 200 },
    {
      field: 'user',
      headerName: 'Provider',
      width: 200,
      renderCell: (params) => `${params.row.user?.firstName || ''} ${params.row.user?.lastName || ''}`,
    },
    {
      field: 'serviceDetails',
      headerName: 'Service Date',
      width: 200,
      renderCell: (params) => (
        <Box
          sx={{
            marginTop: '12px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            lineHeight: '1.2', // Adjust line height for spacing
          }}
        >
          <span>{params.row.serviceDate ? moment(params.row.serviceDate).format('M/D/YYYY') : ''}</span> {/* Service Date */}
          <span style={{ fontSize: '0.85em', color: '#666' }}>
            {params.row.startTime || 'N/A'} - {params.row.endTime || 'N/A'}
          </span>
        </Box>
      ),
    },
    {
      field: 'billingStatus',
      headerName: 'Billing Status',
      width: 225, // Increase the width to accommodate both components
      sortable: false, // Disable sorting
      resizable: false, // Disable column resizing
      renderCell: (params) => {
        const status = params.row.billingStatus || 'Unknown';
        const icon = billingStatusIcons[status];
        const backgroundColor = billingStatusColors[status] || 'white'; // Default to white if status not found

        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              backgroundColor: backgroundColor,
              padding: '0px', // Add some padding for better spacing
              borderRadius: '4px', // Optional: for rounded corners
            }}
          >
            <Box sx={{ paddingLeft: '8px', display: 'flex', alignItems: 'center' }}>
              {icon}
              <span>{status}</span>
            </Box>
            <IconButton onClick={(event) => handleOpenHistoryDialog(event, params.row)} sx={{ ml: 1, paddingRight: '10px' }}>
              <BsThreeDots style={{ fontSize: '16px' }} />
            </IconButton>
          </Box>
        );
      },
    },
    //{ field: 'authorizationNumber', headerName: 'Authorization #', value: 'ABC1234567', width: 100 },
    {
      field: 'authorizationNumber',
      headerName: 'Authorization #',
      width: 200,
      renderCell: (params) => {
        const isAuthorizationRequired = params.row.authorizationErrors === "Authorization Required";
        const authorizationNumber = params.row.authorizationNumber || 'N/A';

        // Determine the text color
        const textColor = isAuthorizationRequired
          ? '#a4262c' // Dark red for "Authorization Required"
          : authorizationNumber === 'N/A'
            ? 'gray'  // Grey text for 'N/A'
            : 'inherit'; // Default color for other cases

        const backgroundColor = isAuthorizationRequired ? '#fed9cc' : 'inherit'; // Light red background for alert

        return (
          <Box
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 'fit-content',
              padding: '2px 8px',
              backgroundColor: backgroundColor,
              color: textColor,
              borderRadius: '12px',
              fontWeight: 'bold',
              fontSize: '0.85em',
              whiteSpace: 'nowrap',
              lineHeight: '1.2',
              minHeight: 'unset',
            }}
          >
            {isAuthorizationRequired ? params.row.authorizationErrors : authorizationNumber}
          </Box>
        );
      },
    },
    {
      field: 'rate',
      headerName: 'Rate',
      width: 120,
      headerAlign: 'right',
      align: 'right',
      renderCell: (params) => new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(params.row.rate),
    },
    {
      field: 'totalPayment',
      headerName: 'Payment',
      width: 100,
      headerAlign: 'right',
      align: 'right',
      renderCell: (params) => {
        const paymentValue = params.row.totalPayment;
        const textColor = paymentValue === 0 ? 'gray' : 'inherit';

        return (
          <span style={{ color: textColor }}>
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(paymentValue)}
          </span>
        );
      },
    },
    {
      field: 'buttons',
      headerName: ' ',
      sortable: false, // Disable sorting
      resizable: false, // Disable column resizing
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 75,
      renderCell: (params) => (
        <div>
          <PDFDownloadLink
            document={<InvoiceReport data={params.row} />}
            fileName="invoice.pdf"
          >
            <IconButton aria-label="download" color="primary">
              <FaReceipt style={{ fontSize: '16px' }} />
            </IconButton>
          </PDFDownloadLink>

          {/* Additional Edit Icon Button */}
          <IconButton
            aria-label="edit"
            color="primary"
            onClick={(event) => handleOpenEditModal(event, params.row)}
          >
            <FaEdit style={{ fontSize: '16px' }} />
          </IconButton>
        </div>
      ),
    },
  ];

  // Handle edit action
  const handleOpenEditModal = (event, row) => {
    setSelectedRecord(row);
    setAnchorEl(event.currentTarget);
    setIsEditModalOpen(true);
  };

  const handleOpenHistoryDialog = (event, record) => {
    setSelectedRecord(record);
    setAnchorEl(event.currentTarget);
    setIsHistoryDialogOpen(true);
  };


  const handleOpenDialog = (event, record) => {
    setAnchorEl(event.currentTarget);
    setSelectedRecord(record);
  };

  const handleCloseDialog = () => {
    setAnchorEl(null);
    setSelectedRecord(null);
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedRecord(null);
    setAnchorEl(null);
  };

  const handleCloseHistoryDialog = () => {
    setIsHistoryDialogOpen(false);
    setSelectedRecord(null);
    setAnchorEl(null);
  };

  const handleSave = (updatedRecord) => {
    // Implement your save logic here, e.g., updating the state or making an API call
    console.log('Record saved:', updatedRecord);
  };

  const handleServiceNameChange = (event) => {
    setServiceName(event.target.value);
  };

  const [getBillingReport, { data, isLoading: isGetBillingHistoryLoading }] = useGetBillingReportMutation({
    recordNumber: page * rowsPerPage,
    offset: rowsPerPage,
    serviceDate: serviceDate,
    endDate: endDate,
    clientIdList: clientIdList,
    serviceIdList: service,
    levelOfCare: levelOfCare,
    billingStatus: billingStatus,
    authorizationList: authorizationList,
  }, { skip })

  const [upload835, { data: data835, isLoading: is835Loading }] = usePostUpload835FileMutation({ fileValue835 }, { skip })

  const [downloadFile837, { data: file837, isLoading: is837FileLoading }] = useDownloadFile837Mutation({
    recordNumber: page * rowsPerPage,
    offset: rowsPerPage,
    serviceDate: serviceDate,
    endDate: endDate,
    clientIdList: clientIdList,
    serviceIdList: service,
    levelOfCare: levelOfCare,
    billingStatus: billingStatus,
    authorizationList: authorizationList,
  }, { skip })

  const {
    data: billingHistory = [],
    error,
    isLoading,
  } = useGetClientServiceBillingHistoryQuery(
    openPopover && serviceId ? { serviceId } : skipToken
  );

  let originalRows = data?.serviceRate;

  const [rows, setRows] = useState([]);

  const handleUpload835File = () => {
    console.log('Record saved:', fileValue835);
    if (!fileValue835 || typeof fileValue835.base64Data === 'undefined') {
      console.log('no file selected exit');
      setOpen(true);
      setMessage('Select an 835 payment file.');
      return;
    }

    upload835(fileValue835)
      .then(response => {
        console.log('response 2:', response.data.message); // Log the response to check structure

        if (response?.error?.message) {
          setOpen(true);
          setMessage(response.error.message);
        } else {
          setOpen(true);

          // Check if the response has a 'message' property and display it
          if (response && response.data.message) {
            setMessage(response.data.message);
          } else {
            setMessage('File uploaded successfully');
          }

          // Trigger a data refresh after successful upload
          const transformedOrderBy = replaceName(orderBy);
          getBillingReport({
            recordNumber: page * rowsPerPage,
            offset: rowsPerPage,
            serviceDate: serviceDate,
            endDate: endDate,
            clientIdList: clientIdList,
            serviceIdList: service,
            order,
            orderBy: transformedOrderBy,
            levelOfCare: levelOfCare,
            billingStatus: billingStatus,
            authorizationList: authorizationList,
          });
        }
      })
      .catch(error => {
        console.error('Upload error:', error); // Log the error to the console for debugging
        setOpen(true);
        setMessage(`An error occurred: ${error.message}`);
      });
  };
  useEffect(() => {
    if (originalRows) {
      setRows(originalRows)
    }
    if (data?.totalCount) {
      setCount(data?.totalCount)
    }
  }, [data, originalRows]);

  const replaceName = (name) => {
    if (name === 'clientFirstName') {
      return 'fullName';
    } else {
      return name;
    }
  }

  // Timer effect to automatically close the alert
  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        setOpen(false);
      }, 3000); // 5 seconds

      // Clean up the timer when the component unmounts or when `open` changes
      return () => clearTimeout(timer);
    }
  }, [open]);

  useEffect(() => {
    if (!skip && !isFilterDialogOpen) {
      console.log("Dependencies changed", {
        page,
        rowsPerPage,
        order,
        orderBy,
        serviceDate,
        endDate,
        memoizedClientIdList,
        memoizedService,
        memoizedLevelOfCare,
        memoizedBillingStatus,
        memoizedAuthorizationList,
      });

      const transformedOrderBy = replaceName(orderBy);
      getBillingReport({
        recordNumber: page * rowsPerPage,
        offset: rowsPerPage,
        serviceDate,
        endDate,
        clientIdList: memoizedClientIdList,
        serviceIdList: memoizedService,
        levelOfCare: memoizedLevelOfCare,
        billingStatus: memoizedBillingStatus,
        authorizationList: memoizedAuthorizationList,
        order,
        orderBy: transformedOrderBy,
      });
    }
  }, [
    skip,
    page,
    order,
    orderBy,
    serviceDate,
    endDate,
    memoizedClientIdList,
    memoizedService,
    memoizedLevelOfCare,
    memoizedBillingStatus,
    memoizedAuthorizationList,
    isFilterDialogOpen,
  ]);


  const handleDownload837File = () => {
    const transformedOrderBy = replaceName(orderBy);
    downloadFile837({
      recordNumber: page * rowsPerPage,
      offset: rowsPerPage,
      serviceDate: serviceDate,
      endDate: endDate,
      clientIdList: clientIdList,
      serviceIdList: service,
      order,
      orderBy: transformedOrderBy,
      levelOfCare: levelOfCare,
      billingStatus: billingStatus,
      authorizationList: authorizationList,
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = useMemo(
    () =>
      stableSort(rows).slice(),
    [order, orderBy, page, rowsPerPage, rows, data, originalRows],
  );

  const extractIds = array => {
    return array?.map(item => item?.id);
  }

  const handleServiceDateChange = (name, date) => {
    setServiceDate(date);
  };

  const handleEndDateChange = (name, date) => {
    setEndDate(date);
  };

  const handleClientIdChange = (name, value) => {
    setClientIdList(extractIds(value));
  };

  const handleServiceChange = (name, value) => {
    setService(extractIds(value));
  };

  const handleLevelOfCareChange = (name, value) => {
    setLevelOfCare(extractIds(value));
  };

  const handleAuthorizationListChange = (name, value) => {
    setAuthorizationList(extractIds(value));
  };

  const handleBillingStatusChange = (name, value) => {
    setBillingStatus(extractIds(value));
  };

  const handleTempServiceDateChange = (name, date) => {
    setTempServiceDate(date);
  };

  const handleTempClientIdChange = (name, value) => {
    setTempClientIdList(extractIds(value));
  };

  const handleTempServiceChange = (name, value) => {
    setTempService(extractIds(value));
  };

  const handleTempLevelOfCareChange = (name, value) => {
    setTempLevelOfCare(extractIds(value));
  };

  const handleTempBillingStatusChange = (name, value) => {
    setTempBillingStatus(extractIds(value));
  };

  const handleTempAuthorizationListChange = (name, value) => {
    setTempAuthorizationList(extractIds(value));
  };

  const handleDownload835File = (name, value) => {
    setFileValue835(value);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    dispatch(setError(""));
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleRefreshData = () => {

    setSkip(true);  // Temporarily set skip to true to "reset" the fetching condition
    setTimeout(() => setSkip(false), 0);  // Set skip to false immediately after to trigger the fetch
  };

  const handleChipDelete = useCallback((name, id) => {
    switch (name) {
      case 'Clients':
        setClientIdList((prev) => prev.filter((item) => item !== id));
        break;
      case 'Billing Status':
        setBillingStatus((prev) => prev.filter((item) => item !== id));
        break;
      case 'Service':
        setService((prev) => prev.filter((item) => item !== id));
        break;
      case 'Level Of Care':
        setLevelOfCare((prev) => prev.filter((item) => item !== id));
        break;
      case 'Authorization':
        setAuthorizationList((prev) => prev.filter((item) => item !== id));
        break;
      default:
        break;
    }
  }, []);

  const applyFilters = () => {
    // Apply the temporary filter states to the main state
    setServiceDate(tempServiceDate);
    setClientIdList(tempClientIdList);
    setService(tempService);
    setLevelOfCare(tempLevelOfCare);
    setBillingStatus(tempBillingStatus);
    setAuthorizationList(tempAuthorizationList);

    // Close the filter popover
    setFilterAnchorEl(null);
  };

  const memoizedBillingStatusChips = useMemo(() => {
    if (billingStatuses && billingStatus.length > 0) {
      return billingStatus.map((id) => (
        <Chip
          key={id}
          label={`Status: ${billingStatuses[id - 1]?.name}`}
          onDelete={() => handleChipDelete('Billing Status', id)}
        />
      ));
    }
    return [];
  }, [billingStatuses, billingStatus]);

  return (
    <Box sx={{ width: '100%', maxWidth: "calc(100% - 60px)", margin: "37px 30px" }}>
      {(isGetBillingHistoryLoading || is837FileLoading) && <LoadingPanel />}
      <StyledPaper sx={{ width: '100%', mb: 2 }}>
        <HeaderContainer>
          <TableSearchContainer>
            <ClientsTitleContainer>
              Billing Report
            </ClientsTitleContainer>
            <StyledButton
              variant="outlined"
              onClick={handleFilterDialogOpen}
              startIcon={<FilterListIcon style={{ color: '#659DBD' }} />}
            >
              Filter
            </StyledButton>
          </TableSearchContainer>
          <TableControlsContainer>
            <CustomButton
              variant="contained"
              onClick={handleDialogOpen}>
              <FaUpload style={{ marginRight: '8px' }} />
              Import 835 File
            </CustomButton>
            <CustomButton
              variant="contained"
              onClick={handleDownload837File}
            >
              <ToolsImg src={exportIcon} alt="" />
              Download 837
            </CustomButton>
            <CustomButton
              variant="contained"
              onClick={handleDownload837File}
            >
              <FaPaperPlane style={{ marginRight: '8px' }} />
              Transmit Claims
            </CustomButton>

            <CustomButton
              variant="contained"
              onClick={handleRefreshData}
            >
              <FaSyncAlt style={{ marginRight: '8px' }} />
              Refresh Data
            </CustomButton>
          </TableControlsContainer>
        </HeaderContainer>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
          {serviceDate && (
            <Chip
              label={`Service Date: ${moment(serviceDate).format('MM/DD/YYYY')}`}
              onDelete={() => setServiceDate(null)}
            />
          )}
          {clientIdList?.map((id) => (
            <Chip
              key={id}
              label={`Client: ${getNameById(id, clientsData)}`}
              onDelete={() => handleChipDelete('Clients', id)}
            />
          ))}
          {billingStatus?.map((status) => (
            <Chip
              key={status}
              label={`Billing Status: ${getNameById(status, billingStatuses)}`}
              onDelete={() => handleChipDelete('Billing Status', status)}
            />
          ))}
          {service?.map((id) => (
            <Chip
              key={id}
              label={`Service: ${getNameById(id, services)}`}
              onDelete={() => handleChipDelete('Service', id)}
            />
          ))}
          {levelOfCare?.map((id) => (
            <Chip
              key={id}
              label={`Level Of Care: ${getNameById(id, levelOfCareList)}`}
              onDelete={() => handleChipDelete('Level Of Care', id)}
            />
          ))}
          {authorizationList?.map((id) => (
            <Chip
              key={id}
              label={`Authorization: ${getNameById(id, authorizationListData)}`}
              onDelete={() => handleChipDelete('Authorization', id)}
            />
          ))}
        </Box>
        <Box sx={{ height: 600, width: '100%' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={rowsPerPage}
            rowsPerPageOptions={[25, 50, 100]}
            pagination
            onPageChange={(params) => handleChangePage(params.page)}
            onPageSizeChange={handleChangeRowsPerPage}
            sortModel={[{ field: orderBy, sort: order }]}
            onSortModelChange={(model) => {
              if (model.length) {
                setOrder(model[0].sort);
                setOrderBy(model[0].field);
              }
            }}
            disableSelectionOnClick
            disableCellFocusOutline
            disableColumnSelector
            components={{
              LoadingOverlay: CustomLoadingOverlay, // Custom loading spinner overlay
              NoRowsOverlay: () => <EmptyMessage>No data to show</EmptyMessage>,
              Toolbar: CustomToolbar, // Custom toolbar
            }}
            sx={{
              backgroundColor: 'white', // Setting background color to white
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: 'white',
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold', // Making header text bold
              },
            }}
          />
        </Box>
        {selectedRecord && (
          <BillingEditModal
            open={isEditModalOpen}
            anchorEl={anchorEl}
            onClose={handleCloseEditModal}
            record={selectedRecord}
            onSave={handleSave}
          />
        )}

        {selectedRecord && (
          <Dialog
            id="billing-history-dialog"
            open={isHistoryDialogOpen}
            anchorEl={anchorEl}
            onClose={handleCloseHistoryDialog}
            aria-labelledby="billing-history-dialog-title"
            maxWidth="lg" // Increased max width
            fullWidth // Ensures it spans the width of the screen
          >
            <DialogTitle id="billing-history-dialog-title">Record Details</DialogTitle>
            <DialogContent>
              {isLoading ? (
                <CircularProgress />
              ) : error ? (
                <p>Error loading billing history</p>
              ) : billingHistory && billingHistory.length > 0 ? (
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ minWidth: '150px' }}>Status</TableCell>
                        <TableCell style={{ minWidth: '150px' }}>Status Date</TableCell>
                        <TableCell style={{ minWidth: '200px' }}>Created By</TableCell>
                        <TableCell style={{ minWidth: '200px' }}>Action</TableCell>
                        <TableCell style={{ minWidth: '300px' }}>Note</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {billingHistory.map((history, index) => (
                        <TableRow key={index}>
                          <TableCell>{history.status}</TableCell>
                          <TableCell>
                            {moment(history.statusDate).format('MM/DD/YYYY h:mm A')}
                          </TableCell>
                          <TableCell>{history.createdBy}</TableCell>
                          <TableCell>{history.billingHistoryAction}</TableCell>
                          <TableCell>{history.note}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <p>No billing history available</p>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseHistoryDialog} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        )}
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
          <ResultData>
            <span>Total rate: </span>
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(data?.totalRate)}
          </ResultData>
        </div>
      </StyledPaper>

      <Popover
        id={isFilterPopoverOpen ? 'filter-popover' : undefined}
        open={isFilterPopoverOpen}
        anchorEl={filterAnchorEl}
        onClose={handleFilterDialogClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 2, minWidth: 300 }}>
          <BilingReportFilters
            handleServiceDateChange={handleTempServiceDateChange}
            serviceDate={serviceDate}
            handleTempClientIdChange={handleTempClientIdChange}
            tempClientIdList={tempClientIdList}
            handleTempServiceChange={handleTempServiceChange}
            tempService={tempService}
            handleTempLevelOfCareChange={handleTempLevelOfCareChange}
            tempLevelOfCare={tempLevelOfCare}
            handleTempBillingStatusChange={handleTempBillingStatusChange}
            tempBillingStatus={tempBillingStatus}
            handleTempAuthorizationListChange={handleTempAuthorizationListChange}
            tempAuthorizationList={tempAuthorizationList}
            clientsData={clientsData}
            services={services}
            levelOfCareList={levelOfCareList}
            billingStatuses={billingStatuses}
            authorizationListData={authorizationListData}
            onApplyFilters={applyFilters}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button variant="contained" color="primary" onClick={applyFilters}>
              Apply Filters
            </Button>
            <Button onClick={handleFilterDialogClose} color="primary">
              Close
            </Button>
          </Box>
        </Box>
      </Popover>


      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        maxWidth="sm" // Changed to 'sm' to make the dialog smaller
        fullWidth
        PaperProps={{
          sx: {
            width: '625px', // Set a smaller width for the dialog
            maxWidth: '80vw', // Ensure it’s responsive on smaller screens
            position: 'relative', // Allow for absolute positioning within the Dialog
          },
        }}
      >
        <DialogTitle>
          Upload 835 File
          <IconButton
            aria-label="close"
            onClick={handleDialogClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px', marginTop: '16px' }}>
            <UploadFileContainer>
              <FileUpload buttonValue={'Choose 835 file'} value={fileValue835} setValue={handleDownload835File} />
            </UploadFileContainer>
            <CustomButton
              variant="contained"
              onClick={handleUpload835File}
              style={{ marginTop: '5px', marginLeft: '60px' }}
              disabled={!fileValue835}
            >
              <FaUpload style={{ color: 'white', marginRight: '8px' }} />
              Upload 835
            </CustomButton>
          </Box>
        </DialogContent>

        {/* Container for ErrorAlert */}
        <Box
          sx={{
            position: 'absolute',
            bottom: 8, // Adjust the distance from the bottom as needed
            left: '50%', // Center horizontally
            transform: 'translateX(-45%)', // Center horizontally
            width: '90%', // Adjust width as needed
          }}
        >
          <ErrorAlert
            severity={message === 'File uploaded successfully' ? 'success' : 'error'}
            handleClose={handleClose}
            open={open}
            errorMessage={message}
          />
        </Box>

        <DialogActions>
          <CustomButton onClick={handleDialogClose} color="primary">
            Close
          </CustomButton>
        </DialogActions>
      </Dialog>

    </Box>


  );


}