import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

// Styles for the PDF document
const styles = StyleSheet.create({
    page: {
        padding: 30,
    },
    section: {
        marginBottom: 10,
    },
    header: {
        fontSize: 20,
        marginBottom: 20,
    },
    text: {
        fontSize: 12,
        marginBottom: 5,
    },
});

const InvoiceReport = ({ data }) => {
    if (!data) {
        return (
            <Document>
                <Page style={styles.page}>
                    <Text>No data available</Text>
                </Page>
            </Document>
        );
    }

    return (
        <Document>
            <Page style={styles.page}>
                <View style={styles.section}>
                    <Text style={styles.text}>Id: {data.id}</Text>
                    <Text style={styles.text}>Client: {data.clientFirstName} {data.clientLastName}</Text>
                    <Text style={styles.text}>Service: {data.name}</Text>
                    <Text style={styles.text}>Billing Status: {data.billingStatus}</Text>
                    <Text style={styles.text}>Total Payment: ${data.totalPayment}</Text>
                    <Text style={styles.text}>Service Dates: {new Date(data.startDate).toLocaleDateString()} - {new Date(data.endDate).toLocaleDateString()}</Text>
                </View>
            </Page>
        </Document>
    );
};

export default InvoiceReport;