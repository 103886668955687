/* eslint-disable no-useless-escape */
import * as Yup from "yup";
const emailRegEx =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneRegEx =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

export const generateValidationSchema = (data) => {
  const fieldsArray = Object.values(data).map((item) => Object.values(item));
  const fields = [].concat(...fieldsArray);
  const schema = {};

  fields?.forEach((field) => {
    if (field.validationType === "email") {
      if (field.requiredErrorMsg) {
        schema[field.name] = Yup.string()
          .matches(emailRegEx, `${field.invalidErrorMsg}`)
          .required(`${field.requiredErrorMsg}`);
      } else {
        schema[field.name] = Yup.string().matches(
          emailRegEx,
          `${field.invalidErrorMsg}`
        );
      }
    } else if (field.validationType === "phone") {
      if (field.requiredErrorMsg) {
        schema[field.name] = Yup.string()
          .matches(phoneRegEx, `${field.invalidErrorMsg}`)
          .required(`${field.requiredErrorMsg}`);
      } else {
        schema[field.name] = Yup.string().matches(
          phoneRegEx,
          `${field.invalidErrorMsg}`
        );
      }
    } else if (field.validationType === "zipcod") {
      schema[field.name] = Yup.string()
        .required(`${field.requiredErrorMsg}`)
        .test(
          "len",
          `${field.invalidErrorMsg}`,
          (val) => val && val.length === 5
        );
    } else if (field.validationType === "date") {
      schema[field.name] = Yup.string()
        .required(`${field.requiredErrorMsg}`)
        .test(field.name, field.invalidErrorMsg, (val) => {
          if (val) {
            return !isNaN(new Date(val));
          }
          return false;
        });
    } else if (field.validationType === "required") {
      const nestedItems =  String(field.name).split(".");
      if (nestedItems.length === 1) {
        schema[field.name] = Yup.string().required(
          `${field.requiredErrorMsg || "Field is required"}`
        );
      }
    }
  });

  return Yup.object().shape(schema);
};
