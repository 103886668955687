import React from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from "../authConfig";
import './SplashPage.css';
import emrImage from '../shared/assets/splash.png';  // Make sure to place the image in the appropriate directory
import { FaUserMd, FaFileMedicalAlt, FaShieldAlt } from 'react-icons/fa';

function SplashPage() {
  const { instance, accounts } = useMsal();

  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  };

  const isAuthenticated = accounts.length > 0;

  return (
    <div className="splash-container">
      <img src={emrImage} alt="Electronic Medical Records" className="splash-image" />
      <h1>Welcome to EMR Portal</h1>
      <p>Your comprehensive Electronic Medical Records management system.</p>

      <div className="splash-icons">
        <div className="icon-item">
          <FaUserMd size={50} />
          <p>Manage Patients</p>
        </div>
        <div className="icon-item">
          <FaFileMedicalAlt size={50} />
          <p>Track Medical Records</p>
        </div>
        <div className="icon-item">
          <FaShieldAlt size={50} />
          <p>Secure and Compliant</p>
        </div>
      </div>
      {!isAuthenticated && (
        <button onClick={handleLoginRedirect} className="splash-button">Enter the Portal</button>
      )}
    </div>
  );
}

export default SplashPage;