import { Box, IconButton, Typography, styled } from "@mui/material";

export const PopupContainer = styled(Box)(({ theme }) => ({
  width: "480px",
  height: "fit-content",
  margin: "auto",
  backgroundColor: "white",
  maxHeight: '100%',
  overflowY: 'auto',
}));

export const FormContainer = styled(Box)({
  padding: "25px",
  paddingTop: "85px",
});
export const CalendarHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  color: "#fff",
  padding: "20px 25px",
  backgroundColor: theme.palette.background.popupHeader,
  width: '480px',
  position: 'fixed',
  zIndex: 1,
}));
export const PopupButton = styled(IconButton)({
  minWidth: "24px",
  width: "24px",
  height: "24px",
  borderRadius: "50%",
  backgroundColor: "rgba(255, 255, 255, 0.5)",
  "& svg": {
    width: "12px",
    height: "12px",
  },
});
export const PopupBody = styled(Box)({
  display: "flex",
  height: "100%",
  "&:focus-visible ": {
    outline: "none",
  },
});
export const ButtonGroup = styled(Box)({
  marginTop: "30px",
  paddingTop: "25px",
  borderTop: `1px solid rgba(101, 157, 189, 0.2)`,
  display: "flex",
  gap: "20px",
  "& .MuiButtonBase-root": {
    width: "108px",
    height:'26px',
padding:'4px 16px'
  },
});
export const NameGroup = styled(Box)({
  marginLeft: "auto",
  marginRight: "23px",
  display: "flex",
  gap: "8px",
  alignItems:'center',
  color:' #000000'
});
export const Client = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
}));