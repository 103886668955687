import Box from "@mui/material/Box";
import React, { useEffect, useRef, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import { Form, Formik, useFormikContext } from "formik";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PhoneIcon from "@mui/icons-material/Phone";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import AssignmentIcon from "@mui/icons-material/Assignment";
import MedicationIcon from "@mui/icons-material/Medication";
import WarningIcon from "@mui/icons-material/Warning";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import GavelIcon from '@mui/icons-material/Gavel';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import * as Yup from "yup";

import tabsNames from "../../constants/TabsNames";
import { BasicInfo } from "./accordionItem/basicInfo/BasicInfo";
import { ContactInfo } from "./accordionItem/contactInfo/ContactInfo";
import clientInfoFormModel from "../../constants/ClientInfoFormModel";
import { generateValidationSchema } from "../../utils/ValidationUtils";
import { ClientInfoTools } from "./clientInfoTools/ClientInfoTools";
import {
  useFetchClientQuery,
  usePostClientMutation,
} from "../../services/ClientService";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setError } from "../../store/slices/ErrorSlice";
import { LoadingPanel } from "../../shared/components/LoadingPanel";
import { styled } from "@mui/system";
import useFetchWithMsal from "../../utils/useFetchWithMsal";
import { Insurance } from "./accordionItem/insurance/Insurance";
import { ProgressNotes } from "./accordionItem/progressNotes/ProgressNotes";
import { NotesPanel } from "../table/notesPanel/NotesPanel";
import { Assignments } from "../psychosocialAssessment/assignments/Assignments";
import { MedicationDetailsFormGrid } from "./accordionItem/medicationDetails/MedicationDetailsFormGrid";
import { AllergyList } from "./accordionItem/allergyList/AllergyList";

const HeaderWithTabs = styled(Box)({
  backgroundColor: "white",
  position: "sticky",
  top: "70px",
  zIndex: "100",
});
const Arrow = styled(ExpandMoreIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
}));
const StyledAccordion = styled(Accordion)(({ theme, expanded }) => ({
  borderLeft: `3px solid ${expanded ? theme.palette.background.menu : theme.palette.background.active
    }`,
  marginBottom: "8px",
}));
const initValue = {
  id: 0,
  firstName: "",
  lastName: "",
  birthDate: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  zipCode: "",
  primaryPhone: "",
  cellPhone: "",
  email: "",
  companyBranchID: "",
  statusId: "",
  track: [],
  requestedService: [],
  profilePicture: null,
  insurance: {
    information: {
      medicaidNumber: "",
    },
  },
};


const IconWrapper = styled(Box)(({ theme, expanded }) => ({
  color: expanded ? theme.palette.background.menu : theme.palette.background.active,
  display: "flex",
  alignItems: "center",
  marginRight: "8px",
}));

const icons = [
  <AccountCircleIcon />, // Icon for Basic Information
  <PhoneIcon />, // Icon for Contact Information
  <LocalHospitalIcon />, // Icon for Insurance
  <GavelIcon />,
  <AssignmentIcon />, // Icon for Progress Notes
  <MedicationIcon />, // Icon for Medication List
  <WarningIcon />, // Icon for Allergies
  <AssignmentTurnedInIcon />,
];

const renderTabIcon = (icon, expanded) => {
  return <IconWrapper expanded={expanded}>{icon}</IconWrapper>;
};




export const ClientInfo = () => {
  const { id } = useParams();
  const { skip } = useFetchWithMsal();
  const { data, isLoading } = useFetchClientQuery(id, { skip });
  const [postClient, { error }] = usePostClientMutation();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const [initialValue, setInitialValue] = useState(initValue);
  const [expanded, setExpanded] = useState([0]);
  const [notesVisible, setNotesVisible] = useState(false);
  const [errorAccordion, setErrorAccordion] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [medicationsList, setMedications] = useState([]);
  const [allergyList, setAllergyList] = useState([]);
  const accordionRefs = useRef(tabsNames.map(() => React.createRef()));
  const allClientFormFields = [].concat(...clientInfoFormModel);
  const validationSchema = generateValidationSchema(allClientFormFields);
  const nestedValidationSchema = Yup.object().shape({
    insurance: Yup.object().shape({
      information: Yup.object().shape({
        name: Yup.string().required(`${"Field is required"}`),
        medicaidNumber: Yup.string().required(`${"Field is required"}`),
        primaryPayerId: Yup.string().required(`${"Field is required"}`),
        relationshipToInsuredId: Yup.string().required(
          `${"Field is required"}`
        ),
      }),
    }),
  });

  const currentValidationSchema = validationSchema.concat(
    nestedValidationSchema
  );

  useEffect(() => {
    dispatch(setError(error?.message));
  }, [error]);

  useEffect(() => {
    if (data) {
      if (!data.insurance || !data.insurance.information) {
        const initData = cloneDeep(data);
        initData.insurance = {
          information: {
            medicaidNumber: "",
            primaryPayerId: "",
            relationshipToInsuredId: "",
          },
        };
        setInitialValue(initData);
      } else {
        setInitialValue(data);
      }
    }
  }, [data]);

  const onTabChange = (isValid) => (event, newValue) => {
    setExpanded([newValue]);
    setActiveTab(newValue);
    scrollToSection(newValue, isValid);
  };

  const onAccordionClick = (panel) => () => {
    if (expanded.includes(panel)) {
      setExpanded(expanded.filter((item) => item !== panel));
    } else {
      setExpanded([panel]);
      setActiveTab(panel);
    }
  };
  const onCollapseClick = () => {
    setExpanded([]);
  };

  const getAssignments = (assignments) => {
    setAssignments(assignments);
  };

  const MemoizedAccordionContent = React.memo(({ setValues, index, values }) => {
    return (
      <AccordionDetails>
        {renderTabContent(setValues, index, values)}
      </AccordionDetails>
    );
  });

  const onSubmit = (value, actions) => {
    console.log("submitting form:", value);
    const updatedClient = JSON.parse(JSON.stringify(value));
    updatedClient.clientAssignments = assignments;
    if (!updatedClient.medicationList) {
      updatedClient.medicationList = {};
    }
    if (updatedClient?.medicationList.items || medicationsList) {
      updatedClient.medicationList.items = medicationsList;
    }
    if (updatedClient?.listOfAllergies || allergyList) {
      updatedClient.listOfAllergies = allergyList;
    }
    postClient(updatedClient);
    actions.setTouched({});
    actions.setSubmitting(false);
    setInitialValue(updatedClient);
  };

  const renderTabContent = (setValues, tab, values) => {

    switch (tab) {
      case 0:
        return (
          <BasicInfo fields={clientInfoFormModel[0]} setValue={setValues} />
        );
      case 1:
        return <ContactInfo fields={clientInfoFormModel[1]} />;
      case 2:
        return <Insurance fields={clientInfoFormModel[2]} />;
      case 4:
        return <ProgressNotes />;
      case 5:
        return <MedicationDetailsFormGrid fields={clientInfoFormModel[3]} setValue={setValues} values={values} setMedications={setMedications} />;
      case 6:
        return <AllergyList fields={clientInfoFormModel[3]} setValue={setValues} values={values} setAllergyList={setAllergyList} />;
      default:
        return <Typography>Not Found</Typography>;
    }
  };

  const FormErrorNotification = React.memo(() => {
    const { isValid, errors } = useFormikContext();

    const getIndex = (namesToFind) => {
      const indexOfAccordions = [];
      clientInfoFormModel.forEach((innerArray, index) => {
        const keysArray = Object.keys(innerArray);
        const filteredArray = keysArray.filter((item) =>
          namesToFind.includes(item)
        );
        if (filteredArray.length) indexOfAccordions.push(index);
      });

      return indexOfAccordions;
    };

    useEffect(() => {
      if (!isValid) {
        const errorField = Object.keys(errors);
        const errorsIndex = getIndex(errorField);
        const areEqual =
          errorsIndex.length === errorAccordion.length &&
          errorsIndex.every((value, index) => value === errorAccordion[index]);
        if (!areEqual) setErrorAccordion(errorsIndex);
      }
    }, [isValid, errors]);

    return null;
  });

  const scrollToSection = (index, isValid) => {
    let top = 0;
  
    // Define the accordion reference based on validation and index
    const accordion =
      !isValid
        ? accordionRefs.current[index]?.current
        : accordionRefs.current[index > 0 ? index - 1 : 0]?.current;
  
    // Check if the accordion element exists before accessing getBoundingClientRect
    if (accordion) {
      top = accordion.getBoundingClientRect().top;
    } else {
      console.error("Accordion element not found.");
      return; // Exit early if the accordion doesn't exist
    }
  
    // Perform the scroll operation
    window.scrollTo({
      top: top + window.pageYOffset - 140,
      behavior: "smooth",
    });
  };
  
  const isAccordionExpanded = (index) => expanded.includes(index);

  return (
    <Box sx={{ minWidth: "750px", position: "relative" }}>
      {(!data || isLoading) && <LoadingPanel />}
      {true && (
        <Formik
          enableReinitialize
          initialValues={initialValue}
          //validationSchema={currentValidationSchema}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={onSubmit}
          onReset={() => setErrorAccordion([])}
        >
          {({ isValid, dirty, setValues, values }) => (
            <Form id={"clientInfo"}>
              <FormErrorNotification />
              <HeaderWithTabs>
                <ClientInfoTools
                  onCollapseClick={onCollapseClick}
                  setNotesVisible={setNotesVisible}
                  notesVisible={notesVisible}
                  onExpandAll={() =>
                    setExpanded(tabsNames.map((tab, index) => index))
                  }
                  isDirty={dirty}
                  name="Client"
                  id={id}
                />

                <Box
                  sx={{ borderBottom: 1, borderColor: "divider", mb: "22px" }}
                >
                  <Tabs
                    value={activeTab}
                    onChange={onTabChange(isValid)}
                    aria-label="tabs"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    {tabsNames.map((tab, index) => {
                      return <Tab key={index} label={tab} value={index} />;
                    })}
                    <Tab key={"222"} label={"Assignments"} value={"222"} />
                  </Tabs>
                </Box>
              </HeaderWithTabs>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <div style={{ maxWidth: '100%' }}>
                  {tabsNames.map((tab, index) => {
                    const isExpanded = expanded.includes(index) || errorAccordion.includes(index);
                    return (
                      <StyledAccordion
                        expanded={isExpanded}
                        id={`accordion-${index}`}
                        onChange={onAccordionClick(index)}
                        key={index}
                        ref={accordionRefs.current[index]}
                      >
                        <AccordionSummary
                          expandIcon={<Arrow expanded={isExpanded} />}
                          aria-controls={`${index}-content`}
                          id={`${index}-header`}
                          sx={{ color: "background.main" }}
                        >
                          {renderTabIcon(icons[index], isExpanded)}
                          <Typography variant="accordion" sx={{ marginLeft: "8px" }}>
                            {tab}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {renderTabContent(setValues, index, values)}
                        </AccordionDetails>
                      </StyledAccordion>
                    );
                  })}
                  <StyledAccordion
                    expanded={
                      expanded.includes("222")
                    }
                    id={`accordion-222`}
                    onChange={onAccordionClick("222")}
                    key={`accordion-222`}
                  >
                    <AccordionSummary
                      expandIcon={<Arrow />}
                      aria-controls={`222-content`}
                      id={`222-header`}
                      sx={{ color: "background.main" }}
                    >
                      {icons[7]}
                      <Typography variant="accordion" sx={{ marginLeft: "8px" }}>
                        Assignments
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Assignments getAssignments={getAssignments} />
                    </AccordionDetails>
                  </StyledAccordion>
                </div>
              </Box>
            </Form>
          )}
        </Formik>
      )}
      {notesVisible && <NotesPanel type='client-form' visibility={notesVisible} setVisibility={setNotesVisible} />}
    </Box>
  );
};
