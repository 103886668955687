import React, { useMemo } from 'react';
import { Grid, Button } from '@mui/material';
import DatePickerField from '../common/DatePickerField';
import AutocompleteWithCheckboxes from '../../shared/components/AutocompleteWithCheckboxes';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PersonIcon from '@mui/icons-material/Person';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

export const BilingReportFilters = ({
    handleServiceDateChange,
    serviceDate,
    handleTempClientIdChange,
    tempClientIdList,
    handleTempServiceChange,
    tempService,
    handleTempLevelOfCareChange,
    tempLevelOfCare,
    handleTempBillingStatusChange,
    tempBillingStatus,
    handleTempAuthorizationListChange,
    tempAuthorizationList,
    clientsData,
    services,
    levelOfCareList,
    billingStatuses,
    authorizationListData,
    onApplyFilters
}) => {
    // Use memoization to prevent recalculating options unnecessarily
    const memoizedClients = useMemo(() => clientsData || [], [clientsData]);
    const memoizedBillingStatuses = useMemo(() => billingStatuses || [], [billingStatuses]);
    const memoizedServices = useMemo(() => services || [], [services]);
    const memoizedLevelOfCareList = useMemo(() => levelOfCareList || [], [levelOfCareList]);
    const memoizedAuthorizationListData = useMemo(() => authorizationListData || [], [authorizationListData]);

    const dropdownStyle = {
        width: '100%',
        minWidth: '150px', // Fixed width
        maxWidth: '275px',
        height: '32px', // Fixed height
        '& .MuiInputBase-root': {
            height: '100%',
        },
        '& .MuiChip-root': {
            maxHeight: '24px', // Set chip max height to keep the overall height stable
        },
    };

    const dateFieldStyle = {
        width: '100%',
        minWidth: '150px', // Match width with other fields
        maxWidth: '275px',
        boxSizing: 'border-box', // Ensures padding doesn’t add to the width
    };

    return (
        <div style={{ flex: 1 }}>
            <Grid container spacing={3} alignItems="center" sx={{ marginBottom: '20px' }}>
                <Grid item xs={4}>
                    <DatePickerField
                        label={
                            <>
                                <DateRangeIcon sx={{ verticalAlign: 'middle', marginRight: '8px' }} /> Service Date
                            </>
                        }
                        inputFormat="MM/dd/yyyy"
                        value={serviceDate}
                        setValue={handleServiceDateChange}
                        width="100%"
                        sx={dateFieldStyle}
                    />
                </Grid>
                <Grid item xs={4}>
                    <AutocompleteWithCheckboxes
                        multiple
                        name="Clients"
                        label={
                            <>
                                <PersonIcon sx={{ verticalAlign: 'middle', marginRight: '8px' }} /> Clients
                            </>
                        }
                        value={
                            Array.isArray(tempClientIdList)
                                ? tempClientIdList.map((id) => memoizedClients.find((client) => client.id === id)).filter(Boolean)
                                : []
                        }
                        data={memoizedClients}
                        setValue={handleTempClientIdChange}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        width="100%"
                        sx={dropdownStyle}
                    />
                </Grid>
                <Grid item xs={4}>
                    <AutocompleteWithCheckboxes
                        multiple
                        name="Billing Status"
                        label={
                            <>
                                <AssignmentIcon sx={{ verticalAlign: 'middle', marginRight: '8px' }} /> Billing Status
                            </>
                        }
                        value={
                            Array.isArray(tempBillingStatus)
                                ? tempBillingStatus.map((id) => memoizedBillingStatuses.find((status) => status.id === id)).filter(Boolean)
                                : []
                        }
                        data={memoizedBillingStatuses}
                        setValue={handleTempBillingStatusChange}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        width="100%"
                        sx={dropdownStyle}
                    />
                </Grid>
                <Grid item xs={4}>
                    <AutocompleteWithCheckboxes
                        multiple
                        name="Service"
                        label={
                            <>
                                <LocalHospitalIcon sx={{ verticalAlign: 'middle', marginRight: '8px' }} /> Service
                            </>
                        }
                        value={
                            Array.isArray(tempService)
                                ? tempService.map((id) => memoizedServices.find((svc) => svc.id === id)).filter(Boolean)
                                : []
                        }
                        data={memoizedServices}
                        setValue={handleTempServiceChange}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        width="100%"
                        sx={dropdownStyle}
                    />
                </Grid>
                <Grid item xs={4}>
                    <AutocompleteWithCheckboxes
                        multiple
                        name="Level Of Care"
                        label={
                            <>
                                <FilterAltIcon sx={{ verticalAlign: 'middle', marginRight: '8px' }} /> Level Of Care
                            </>
                        }
                        value={
                            Array.isArray(tempLevelOfCare)
                                ? tempLevelOfCare.map((id) => memoizedLevelOfCareList.find((care) => care.id === id)).filter(Boolean)
                                : []
                        }
                        data={memoizedLevelOfCareList}
                        setValue={handleTempLevelOfCareChange}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        width="100%"
                        sx={dropdownStyle}
                    />
                </Grid>
                <Grid item xs={4}>
                    <AutocompleteWithCheckboxes
                        multiple
                        name="Authorization"
                        label={
                            <>
                                <VerifiedUserIcon sx={{ verticalAlign: 'middle', marginRight: '8px' }} /> Authorization
                            </>
                        }
                        value={
                            Array.isArray(tempAuthorizationList)
                                ? tempAuthorizationList.map((id) => memoizedAuthorizationListData.find((auth) => auth.id === id)).filter(Boolean)
                                : []
                        }
                        data={memoizedAuthorizationListData}
                        setValue={handleTempAuthorizationListChange}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        width="100%"
                        sx={dropdownStyle}
                    />
                </Grid>               
            </Grid>
        </div>
    );
};
