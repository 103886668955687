import { useEffect } from "react";
import { loginRequest, protectedResources } from "../authConfig";
import { useMsal } from "@azure/msal-react";

export const AuthenticatedPage = (props) => {
  const { instance, accounts } = useMsal();

  const refreshToken = () => {
    instance
      .acquireTokenSilent({
        account: accounts[0],
        scopes: [...protectedResources.api.scopes.read],
      })
      .then((response) => {
        localStorage.setItem("token", response?.accessToken);
      })
      .catch((e) => {
        instance
          .loginRedirect(loginRequest)
          .catch((error) => console.log(error));
      });
  };
  useEffect(() => {
    const intervalId = setInterval(refreshToken, 3600 * 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return <>{props.children}</>;
};
