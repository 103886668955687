import Box from "@mui/material/Box";
import { useGetClientAssignmentHistoryQuery, useGetUsersQuery, useGetUsersRolesQuery } from "../../../services/ClientService";
import useFetchWithMsal from "../../../utils/useFetchWithMsal";
import { useEffect, useState } from "react";
import SelectField from "../../../shared/components/SelectField";
import { FieldTitle, SignatureIcon } from "../signatures/Signature.styled";
import historySvg from "../../../shared/icons/history.svg";
import crossSvg from "../../../shared/icons/cross.svg";
import rightArrow from "../../../shared/icons/rightArrow.svg";
import { LoadingPanel } from "../../../shared/components/LoadingPanel";
import moment from "moment";
import { AssignmentHistory, CloseButton, HistoryDate, HistoryName } from "./Assignments.styled";
import { Button } from "@mui/material";
import { useRef } from "react";

export const AssignmentRow = (props) => {
  const {assignment, changeAssignmentsState, deleteAssignment, assignmentHistoryHandler, openAssignmentHistoryId} = props;
  const { skip } = useFetchWithMsal();
  const [role, setRole] = useState(assignment.roleId);
  const [usersList, setUsersList] = useState([]);
  const [user, setUser] = useState();

  const clientId = assignment?.clientId;
  const roleId = assignment?.roleId


  const { data: userRoles, isLoading } = useGetUsersRolesQuery({ skip });
  const { data: users, isLoading: usersLoading } = useGetUsersQuery(role, { skip });
  const { data: assignmentHistory } = useGetClientAssignmentHistoryQuery(openAssignmentHistoryId === assignment.id && {
    clientId: clientId,
    roleId: roleId
  }, { skip });

  const onRoleChange = (event) => {
    setRole(event.target.value);
  };

  const onUserChange = (event) => {
    setUser(event.target.value);
  };

  const findIndexById = (id, array) => {
    const index = array.findIndex(element => element.id === id);
    return index !== -1 ? index : -1;
  }

  const findUserById = (id, array) => {
    const index = array.findIndex(element => element.arrayId === id.toString());
    return index !== -1 ? index : -1;
  }

  const deleteItem = () => {
    deleteAssignment(assignment.id)
  }

  useEffect(() => {
    if(userRoles){
      if (assignment.roleId) {
        const role = findIndexById(assignment.roleId, userRoles)
        setRole(userRoles[role].id)
      }
    }
  }, [userRoles?.length, assignment.roleId]);

  useEffect(() => {
    if(!usersLoading && users !== undefined){
      setUsersList(users.map((item, index) => ({
        id: index.toString(),
        arrayId: item.id,
        name: `${item?.firstName} ${item?.lastName}`
      })))
    }
  }, [users?.length, role]);

  useEffect(() => {
    if(usersList.length){
      if(assignment?.userId) {
        const userIndex = findUserById(assignment?.userId, usersList)
        setUser(userIndex > 0 ? usersList[userIndex].id : usersList[0].id)
      }
    }
  }, [usersList?.length, role]);

  useEffect(() => {
    if(!usersLoading && !isLoading) {
      changeAssignmentsState(assignment?.id, role, usersList[user]?.arrayId)
    }
  }, [user, role, usersList]);

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      flexWrap: "wrap",
      borderBottom: "1px dashed #e0ebf2",
      marginBottom: "20px",
    }}>
        {(usersLoading || isLoading) && <LoadingPanel />}
        <SelectField
          name={"Role"}
          label={"Role"}
          data={userRoles}
          onChange={onRoleChange}
          value={role}
          fullWidth
          sx={{
            marginBottom: "20px",
            minWidth: "290px",
          }}
        />
        <SelectField
          name={"Person"}
          label={"Person"}
          data={usersList}
          onChange={onUserChange}
          value={user}
          fullWidth
          sx={{
            marginBottom: "20px",
            minWidth: "290px",
          }}
          disabled={!users?.length}
        />
        <FieldTitle sx={{
            display: 'flex',
            alignItems: 'center',
            lineHeight: '12px',
            fontSize: '12px',
            cursor: 'pointer',
            position: 'relative'
          }}
          onClick={() => assignmentHistoryHandler(assignment?.id === openAssignmentHistoryId ? null : assignment?.id)}
        >
          <SignatureIcon component="img" src={historySvg} alt="" />          
          Assignment History
          {openAssignmentHistoryId === assignment?.id && <div style={{
            position: 'absolute',
            background: '#ffffff',
            top: '16px',
            height: '277px',
            width: '355px',
            left: '-125px',
            boxShadow: '0px 1px 4px 0px #00000040',
            zIndex: 1,
            padding: '23px',
            overflow: 'auto',
          }}>
            {assignmentHistory?.length ? assignmentHistory.map((assignment, index) => {
              return <AssignmentHistory key={assignment?.id}> 
                <HistoryDate>{assignment?.updateDate ? moment(assignment?.updateDate).format("MM/DD/YYYY"): ''}</HistoryDate>
                <HistoryName>{assignment.oldFirstName} {assignment.oldLastName}</HistoryName>
                <SignatureIcon component="img" src={rightArrow} alt=""/>
                <HistoryName>{assignment.firstName} {assignment.lastName}</HistoryName>
              </AssignmentHistory>
            }): "No assignment history to show"}
            <CloseButton variant="contained" onClick={() => assignmentHistoryHandler(null)}>
              Close
            </CloseButton>
          </div>}
        </FieldTitle>
        <FieldTitle  sx={{
            display: 'flex',
            alignItems: 'center',
            lineHeight: '12px',
            fontSize: '12px',
            cursor: 'pointer',
        }} onClick={() => deleteItem()}>
          <SignatureIcon component="img" src={crossSvg} alt=""/>
          Remove
        </FieldTitle>
    </Box>
  );
};
