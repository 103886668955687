import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

const RadioGroupField = (props) => {
  const { value, name, setValue, error,items, id, label, required, ...rest } = props;

  function onChange(e) {
    setValue(name,Number(e.target.value));
  }
  const renderHelperText = () => {
    if (error) {
      return <FormHelperText error>{error}</FormHelperText>;
    }
  };

  return (
    <>
      {label && (
        <InputLabel htmlFor="input" sx={{ height: "14px" }}>
          {label}{required && '*'}
        </InputLabel>
      )}
      <FormControl {...rest}>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue={1}
          name={`radio-buttons-group-${id}`}
          required={required}
        >
          {items?.map((item) => {
            return (
              <FormControlLabel
                value={item.id}
                control={<Radio onChange={onChange} sx={{height: '30px'}}/>}
                checked={value ===item.id}
                label={item.name}
                key={item.id}
                error ={error}
              />
            );
          })}
        </RadioGroup>
        {renderHelperText()}
      </FormControl>
    </>
  );
};
export default React.memo(RadioGroupField);
