import React from "react";
import styled from "styled-components";
import { PsychosocialAssessment } from "../../components/psychosocialAssessment/PsychosocialAssessment";

const PsychosocialAssessmentPageContainer = styled.div`
  display: flex;
  padding: 27px 20px;
  max-width: 1040px;
  margin: 0 auto;
`;

export const PsychosocialAssessmentPage = () => {
  return (
    <PsychosocialAssessmentPageContainer>
      <PsychosocialAssessment />
    </PsychosocialAssessmentPageContainer>
  );
};
