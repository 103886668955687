import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import DatePickerField from '../../../common/DatePickerField';
import InputField from '../../../common/InputField';
import SelectField from '../../../../shared/components/SelectField';

export const MedicationsFilters = ({ data, setFilteredMedications }) => {
    const [filteredData, setFilteredData] = useState(data);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [statusFilter, setStatusFilter] = useState(1);

    const activeFilterValues = [
        {id: 1, name: 'All'},
        {id: 2, name: 'Active'},
        {id: 3, name: 'Inactive'}
    ]

    useEffect(() => {
        const filteredByDate = data.filter(item => {
            const prescriptionDate = new Date(item.prescriptionDate);
            const from = fromDate ? new Date(fromDate) : null;
            const to = toDate ? new Date(toDate) : null;
            return (!from || prescriptionDate >= from) &&
                   (!to || prescriptionDate <= to);
        });

        const filteredByName = filteredByDate.filter(item =>
            item?.medicationName?.toLowerCase().includes(searchTerm.toLowerCase())
        );

        const filteredByStatus = filteredByName.filter(item => {
            const currentDate = new Date();
            const medicationEndDate = item.medicationEndDate ? new Date(item.medicationEndDate) : null;
            const discontinueDate = item.discontinueDate ? new Date(item.discontinueDate) : null;

            if (statusFilter === 1) {
                return true;
            } else if (statusFilter === 2) {
                return !medicationEndDate && !discontinueDate ||
                       (medicationEndDate && medicationEndDate > currentDate) ||
                       (discontinueDate && discontinueDate > currentDate);
            } else if (statusFilter === 3) {
                return (medicationEndDate && medicationEndDate <= currentDate) ||
                       (discontinueDate && discontinueDate <= currentDate);
            }
        });
        
        setFilteredData(filteredByStatus);
    }, [data, fromDate, toDate, searchTerm, statusFilter]);

    useEffect(() => {
        setFilteredMedications(filteredData);
    }, [filteredData]);

    const handleFromDateChange = (name, date) => {
        setFromDate(date);
    };

    const handleToDateChange = (name, date) => {
        setToDate(date);
    };

    const handleSearchTermChange = (name, value) => {
        setSearchTerm(value);
    };

    const handleStatusFilterChange = (e) => {
        setStatusFilter(e.target.value);
    };

    return (
        <div>
            <Grid container xs={12} spacing={2} alignItems="center" sx={{marginBottom: "20px"}}>
                <Grid item xs={3}>
                    <DatePickerField
                        label="From Date"
                        inputFormat="MM/dd/yyyy"
                        value={fromDate}
                        setValue={handleFromDateChange}
                    />
                </Grid>
                <Grid item xs={3}>
                    <DatePickerField
                        label="To Date"
                        inputFormat="MM/dd/yyyy"
                        value={toDate}
                        setValue={handleToDateChange}
                    />
                </Grid>
                <Grid item xs={3}>
                    <InputField
                        name="Search by Name"
                        label="Search by Name"
                        value={searchTerm}
                        setValue={handleSearchTermChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <SelectField
                        name="Actve/Inactive"
                        label="Actve/Inactive"
                        data={activeFilterValues}
                        value={statusFilter}
                        setValue={handleStatusFilterChange}
                        onChange={handleStatusFilterChange}
                        fullWidth
                    />
                </Grid>
            </Grid>
        </div>
    );
};
