import { Box, Button, IconButton, Typography, styled } from "@mui/material";

export const CalendarToolbar = styled(Box)({
  height: "100%",
  borderRight: "1px dashed rgba(101, 157, 189, 0.5)",
  minWidth: '247px',
  overflowY: 'auto',
  overflowX: 'hidden',
});
export const ToolbarButtonGroup = styled(Box)({
  padding: " 0 24px",
  display:'grid',
  gap:'15px'
});
export const CalendarContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  backgroundColor: "white",
  padding: "28px 0",
  height: 'calc(100vh - 220px)',
  "& .rbc-calendar": {
    width: "100%",
    
  },
  "& .rbc-event-label": {
    display: "none",
  },
  "& .rbc-label, .day-label, .date-label": {
    fontFamily: "Open Sans",
    fontSize: "10px",
    fontWeight: "600",
    lineHeight: "14px",
    color: "rgba(0, 0, 0, 0.5)",
  },
  "& .date-label": {
    fontSize: "18px",
    color: "rgba(0, 0, 0,1)",
  },

  "& .rbc-today .date-label": {
    width: "26px",
    height: "26px",
    borderRadius: "50%",
    backgroundColor: "rgba(142, 135, 65, 1)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
  },
  "&  .rbc-time-view":
    {
      borderLeft: "none",
      borderTop:'none',
    },

  "& .rbc-time-header-cell": {
    minHeight: "63px",
  },
  "& .rbc-time-content": {
    borderTop: "1px solid rgba(101, 157, 189, 0.2)",
    overflowX: 'hidden',
  },
 
  "& .rbc-timeslot-group":
    {
      borderColor: "rgba(101, 157, 189, 0.2)",
      borderTop:'none'
    },
  "&  .rbc-time-slot": {
    height: "50px",
    flex: "none",
  },

  "&  .rbc-header": {
    padding: "13px",
    borderLeft: "1px solid rgba(101, 157, 189, 0.2)",
  },
  // "&  .rbc-month-row": {
  //   minHeight: "96px",
  // },
  
  "& .rbc-today": {
    backgroundColor: "transparent",
  },
  "& .rbc-events-container": {
    marginRight: "0",
  },

  "& .rbc-event": {
    width: 'auto !important',
    backgroundColor: 'transparent',
    color: "black",
    border:'1px solid white',
    height:'47px',
    minHeight:'47px',
    padding: 0,
  },
  "& .rbc-current-time-indicator": {
    backgroundColor: "rgba(66, 129, 171, 1)",
    height: "2px",
    "&::before": {
      content: '""',
      position: "absolute",
      top: "1px",
      bottom: 0,
      height: "10px",
      width: "10px",
      borderRadius: "50%",
      backgroundColor: "rgba(66, 129, 171, 1)",
      left: 0,
      transform: "translate(-50%, -50%)",
    },
  },
  "& .MuiTextField-root": {
    minWidth:'170px !important'
  },
}));

export const BadgeContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginRight: "33px",
  marginLeft: "auto",
});
export const NavigationBarButton = styled(Box)({
  display: "flex",
  alignItems: "center",
  color: "#659dbd",
  fontFamily: "Open Sans",
  border: "none",
  cursor: "pointer",
  backgroundColor: "inherit",
  fontSize: "12px",
  fontWeight: "400",
});
export const EventContainer = styled(Box)({
  background:'#C7DBE8',
  color: "black",
  height:'47px',
  minHeight:'47px',
  borderRadius: '4px',
  padding: '5px',
});
export const EventName = styled(Typography)({
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  maxWidth: "100%",
  overflow: "hidden",
  fontSize:'10px',
  lineHeight:'12px'
});
export const EventHeader = styled(Typography)({
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  maxWidth: "100%",
  overflow: "hidden",
  fontSize:'10px',
  lineHeight:'12px',
  display: 'flex',
  justifyContent: 'space-between',
});
export const EventFooter = styled(Typography)({
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  maxWidth: "100%",
  overflow: "hidden",
  fontSize:'10px',
  lineHeight:'12px',
  display: 'flex',
  justifyContent: 'space-between',
});
export const EventContent = styled(Typography)({
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  maxWidth: "100%",
  overflow: "hidden",
  fontSize:'9px',
  fontWeight: 400,
  letterSpacing: '0em',
  lineHeight:'12px',
  display: 'flex',
  justifyContent: 'space-between',
});


export const EventMaterials = styled(Typography)({
  textOverflow: "ellipsis",
  maxWidth: "100%",
  overflow: "hidden",
  overflowWrap: "initial",
  fontSize:'10px',
  lineHeight:'12px'
});
export const EventClient = styled(Typography)({
  textOverflow: "ellipsis",
  maxWidth: "100%",
  overflow: "hidden",
  overflowWrap: "initial",
  fontSize:'9px',
  fontStyle: 'italic',
  fontWeight: 400,
  lineHeight:'12px'
});

export const EventRequestSatatus = styled(Typography)({
  textOverflow: "ellipsis",
  maxWidth: "100%",
  overflow: "hidden",
  overflowWrap: "initial",
  fontSize:'9px',
  fontStyle: 'italic',
  fontWeight: 400,
  lineHeight:'12px'
});

export const EventStatus = styled(Typography)({
  textOverflow: "ellipsis",
  maxWidth: "100%",
  overflow: "hidden",
  overflowWrap: "initial",
  backgroundColor:'rgba(251, 238, 193, 1)',
  padding:'0px 4.5px',
  width: 'max-content',
  fontSize:'10px',
  lineHeight:'12px',
  marginLeft: 'auto',
});


export const CalendarHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  alignItems: 'center',
  color: "#fff",
  padding: "20px 25px",
  backgroundColor: theme.palette.background.popupHeader,
}));
export const PopupButton = styled(IconButton)({
 minWidth:'24px',
 width:'24px',
 height:'24px',
 borderRadius:'50%',
 backgroundColor:'rgba(255, 255, 255, 0.5)',
 "& svg": {
  width:'12px',
 height:'12px',
},
});
export const PopupBody = styled(Box)({
  padding: " 20px 20px 20px 20px",
  width:'100%',
  height:'100%',
  
  "&:focus-visible ": {
    outline: "none",
  },
 
});

export const CalendarBody = styled(Box)({
  padding: " 94px 30px 62px 74px",
  
  "&:focus-visible ": {
    outline: "none",
  },
 
});

export const DatePickerContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '12px',
  
  "& span": {
    color: '#4281AB',
    fontSize: '12px',
    marginRight: '8px',
  },
});

export const CheckboxContainer = styled(Box)(({ isTitle }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  paddingBottom: '12px',
  
  "& span": {
    fontSize: '12px',
    fontWeight: isTitle ? '600' : '200',
  },
}));

export const CheckboxSubItems = styled(Box)({
  marginLeft: '28px',
  
  "& span": {
    fontSize: '12px',
    fontWeight: '800'
  },
});

export const ShowMoreButton = styled(Box)(() => ({
  cursor: 'pointer',
  fontSize: '11px',
  fontWeight: '600',
  display: 'flex',
  alignItems: 'center',
  color: '#BD986A',
  marginTop: '5px',
  marginLeft: '13px',
  marginBottom: '18px',
  "& span": {
    marginRight: '4px',
  },
}));

export const FilterGroup = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  borderBottom: '1px solid #e0ebf2',
  marginBottom: '8px',
  margin: '20px 24px',
  boxSizing: 'border-box'
});

export const RangePickerContainer = styled(Box)({
  display: 'flex',
  border: '1px solid #659DBD',
  boxSizing: 'border-box',
  color: '#659DBD',
  fontSize: '12px',
  fontWeight: 600,
  textTransform: 'uppercase'
});

export const RangePickerItem = styled(Box)({
  display: 'flex',
  border: '1px solid #659DBD',
  boxSizing: 'border-box',
  padding: '5px 14px',
  cursor: 'pointer',
});

export const CalendarToolsContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '16px',
});

export const DateRangeContainer = styled(Box)({
  display: 'flex',
  fontSize: '16px',
  fontWeight: 400,
  color: '#BD986A',
  textTransform: 'uppercase',
  "& span": {
    margin: '0 4px',
  },
});

export const AddItemContainer = styled(Box)({
  position: 'relative'
});

export const AddItemOptionsContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  fontSize: '11px',
  fontWeight: 400,
  color: '#000',
  boxShadow: '0px 1px 4px 0px #00000040',
  padding: '11px 0',
  minWidth: '140px',
  position: 'absolute',
  background: '#fff',
  zIndex: 5,
  marginTop: '2px',
});

export const AddItemOption = styled(Box)({
  cursor: 'pointer',
  padding: '5px 16px',
  '&:hover': {
    background: '#EFF5F8'
  },
});
