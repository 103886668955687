import checkoutFormModel from './ClientFormModel';
const {
  formField: {
    firstName,
    lastName,
    birthDate,
    address1,
    address2,
    city,
    state,
    zipcode,
    primaryPhone,
    cellPhone,
   email
  }
} = checkoutFormModel;

const formInitialValues ={
  [firstName.name]: '',
  [lastName.name]: '',
  [birthDate.name]: '',
  [address1.name]: '',
  [address2.name]: '',
  [city.name]: '',
  [zipcode.name]: '',
  [state.name]: '',
  [primaryPhone.name]: '',
  [cellPhone.name]: '',
  [email.name]: '',
};
export default formInitialValues 