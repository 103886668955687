import React from 'react';
import { Modal, Backdrop } from '@mui/material';
import { CalendarComponent } from './Calendar';
import {
  AddItemContainer,
  AddItemOption,
  AddItemOptionsContainer,
  CalendarContainer,
  CalendarHeader,
  CalendarToolbar,
  CalendarToolsContainer,
  CheckboxContainer,
  CheckboxSubItems,
  DatePickerContainer,
  DateRangeContainer,
  EventClient,
  EventContainer,
  EventContent,
  EventFooter,
  EventHeader,
  EventMaterials,
  EventName,
  EventRequestSatatus,
  EventStatus,
  FilterGroup,
  PopupBody,
  PopupButton,
  RangePickerContainer,
  RangePickerItem,
  ShowMoreButton,
} from "./Calendar.styled";

const CalendarModalWrapper = ({ onClose,
  open,
  type,
  clientIdForm,
  selectedUserName }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      type={type}
      clientIdForm={clientIdForm}
      selectedUserName={selectedUserName}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      slots={!onClose && {
        backdrop: Backdrop,
      }}
      slotProps={!onClose && {
        backdrop: {
          sx: {
            backgroundColor: 'transparent',
          },
        },
      }}
    >
      <div>
        <PopupBody>
          <CalendarComponent open={open} onClose={onClose} type={type} clientIdForm={clientIdForm} selectedUserName={selectedUserName} />
        </PopupBody>
      </div>
    </Modal>
  );
};

export default CalendarModalWrapper;