import React from "react";
import { FormHelperText, InputLabel, TextField } from "@mui/material";
import { ErrorMessage } from "formik";

const InputField = (props) => {
  const { value, name, setValue, error, label, height, width, type, multiline, required, maxLength, step, disabled, ...rest } =
    props;
 
  return (
    <>
      {label && (
        <InputLabel htmlFor="input" sx={{ height: "14px" }}>
          {label}{required && '*'}
        </InputLabel>
      )}
      <TextField
        {...rest}
        id={name}
        name={name}
        type={type || "text"}
        size="small"
        value={value || ""}
        error={!!error}
        onChange={(e) => setValue(name, e.target.value)}
        InputProps={{
          style: { height: height || "30px", maxWidth: width || "290px", width:'100%', border: error ? "1px solid red" : 'none' },
          inputProps: { maxLength: maxLength,  step: step || "1" },
        }}
        multiline={multiline || false}
        required={required}
        disabled={!!disabled}
      />
      <ErrorMessage component="div" name={name} className="input-feedback">
        {(msg) => <FormHelperText error>{msg}</FormHelperText>}
      </ErrorMessage>
    </>
  );
};

export default React.memo(InputField);
