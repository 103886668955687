import CloseIcon from "@mui/icons-material/Close";
import * as Yup from "yup";
import {
  ButtonGroup,
  CalendarHeader,
  FormContainer,
  PopupBody,
  PopupButton,
  PopupContainer,
} from "./AddServicesPopup.styled";
import {  Button, Grid, InputLabel, Modal, ToggleButton, ToggleButtonGroup, Typography, styled } from "@mui/material";
import { Field, Form, Formik } from "formik";
import servicesCreationModel from "../../../constants/ServicesCreationModel";
import DateTimePickerField from "../../common/DateTimePickerField";
import useFetchWithMsal from "../../../utils/useFetchWithMsal";
import { useGetClientServicesQuery, useGetTracksQuery } from "../../../services/UtilityService";
import SelectFieldForForm from "../../common/SelectFieldForForm";
import AutocompleteWithCheckboxes from "../../../shared/components/AutocompleteWithCheckboxes";
import { useGetGroupNameQuery, useGetRoomQuery, useGetTopicQuery } from "../../../services/ClientService";
import TextAreaField from "../../common/TextAreaField";
import { useNavigate } from "react-router-dom";

const StyledToggleButtonsGroup = styled(ToggleButtonGroup)({
  height: '30px',
  "& .MuiButtonBase-root": {
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '14px',
    fontFamily: 'Open Sans',
  },
});

export const AddServicesPopup = ({
  open,
  onClose,
  onClick,
  event,
  clients,
  clientId,
  onClientsTypeChange,
  clientsType,
  clientName
}) => {
  const { skip } = useFetchWithMsal();
  const { data } = useGetClientServicesQuery("", { skip });
  const { data: groupNames } = useGetGroupNameQuery("", { skip });
  const { data: rooms } = useGetRoomQuery("", { skip });
  const { data: topics } = useGetTopicQuery("", { skip });  
  const { data: tracks } = useGetTracksQuery("", { skip });
  
  const validationSchema = Yup.object().shape({
    serviceId: Yup.string().test(
      "serviceId",
      "Service is required",
      (serviceId) => {
        return serviceId > 0;
      }
    ),
    description: Yup.string().required("Description is required"),
    startDate: Yup.string().required("Start date is required"),
    endDate: Yup.string()
    .test("endDate", "End date must be after start date", (endDate, ctx) => {
      const startDate = ctx.parent.startDate;
      if (endDate) {
        return startDate <= endDate;
      }
      return false;
    })
    .required("End date is required"),
  });

  const reformatClients = () => {
    if(clients){
      return clients.map(item => ({
        name: `${item.firstName} ${item.lastName}`,
        id: item.id
      }));
    }
    else if (clientName && clientId){
      return [{
        name: clientName,
        id: clientId
      }];
    }
  }

  const getInputComponent = (item, width, height) => {
    switch (item?.type) {
      case "select":
        return (
          <Field name={item.name}>
            {({ field, form }) => (
              <SelectFieldForForm
                name={item.name}
                label={item.label}
                data={data}
                value={field.value}
                setValue={form.setFieldValue}
                error={form.errors[item.name]}
                width={'100%'}
                fullWidth
              />
            )}
          </Field>
        );
      case "groupSelect":
        return (
          <Field name={item.name}>
            {({ field, form }) => (
              <SelectFieldForForm
                name={item.name}
                label={item.label}
                data={groupNames}
                value={field.value}
                setValue={form.setFieldValue}
                error={form.errors[item.name]}
                width={'100%'}
                fullWidth
              />
            )}
          </Field>
        );
      case "topicSelect":
        return (
          <Field name={item.name}>
            {({ field, form }) => (
              <SelectFieldForForm
                name={item.name}
                label={item.label}
                data={topics}
                value={field.value}
                setValue={form.setFieldValue}
                error={form.errors[item.name]}
                width={'100%'}
                fullWidth
              />
            )}
          </Field>
        );
        case "roomSelect":
          return (
            <Field name={item.name}>
              {({ field, form }) => (
                <AutocompleteWithCheckboxes
                  name={item.name}
                  label={item.label}
                  value={field.value}
                  data={rooms}
                  setValue={form.setFieldValue}
                  error={form.errors[item.name]}
                  width={'100%'}
                  fullWidth
                  grouped
                />
              )}
            </Field>
          );
      case "clientSelect":
        return (
          <Field name={item.name}>
            {({ field, form }) => {
              return (
                form.values.serviceId !== 19 ? <AutocompleteWithCheckboxes
                  multiple={form.values.serviceId !== 19}
                  name={item.name}
                  label={item.label}
                  value={field.value}
                  data={reformatClients()}
                  setValue={form.setFieldValue}
                  error={form.errors[item.name]}
                  width={'100%'}
                  fullWidth
                /> : 
                <SelectFieldForForm
                  name={item.name}
                  label={item.label}
                  data={reformatClients()}
                  value={field.value}
                  setValue={form.setFieldValue}
                  error={form.errors[item.name]}
                  width={'100%'}
                  fullWidth
                />
              )}}
          </Field>
          
        );  
      case "date":
        return (
          <Field name={item.name}>
          {({ field, form }) => {
            const handleStartDateChange = (itemName, newDate) => {
              form.setFieldValue(itemName, new Date(newDate));
              form.setFieldValue('endDate', new Date(newDate));
              form.setTouched(itemName, true);
              form.setFieldError('endDate', '');
            };
            return (
              <DateTimePickerField
                name={item.name}
                label={item.label}
                value={field.value || form.values.startDate}
                setValue={handleStartDateChange}
                error={form.errors[item.name]}
                maxDate={new Date()}
              />
            );
          }}
        </Field>
        );
      case "trackSelect":
        return (
          <Field name={item.name}>
            {({ field, form }) => (
              <SelectFieldForForm
                name={item.name}
                label={item.label}
                data={tracks}
                value={field.value}
                setValue={form.setFieldValue}
                error={form.errors[item.name]}
                width={'100%'}
                fullWidth
              />
            )}
          </Field>
        );
      default:
        return (
          <Field name={item.name}>
            {({ field, form }) => (
              <TextAreaField
                name={item.name}
                label={item.label}
                value={field.value || ""}
                setValue={form.setFieldValue}
                error={form.errors[item.name]}
                fullWidth
                width={"100%"}
                height={height}
              />
            )}
          </Field>
        );
    }
  };
  const handleSubmit = (value) => {
    const serviceName= data.find(item=> item?.id === value?.serviceId)
    value.name=serviceName?.name

    onClick(value, event ? "change":'add');
  };

  const navigate = useNavigate();

  const handleClick = location => {
    navigate(location);
    window.location.reload();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-addServices"
      aria-describedby="modal-modal-addServices"
    >
      <PopupBody>
        <PopupContainer>
          <CalendarHeader>
            <Typography variant="h2" > {event ? 'Change Service': 'Add Service'}</Typography>
            {/* {clientName && <NameGroup><Client variant="body1" >Client</Client> */}
            <PopupButton aria-label="delete" onClick={onClose}>
              <CloseIcon />
            </PopupButton>
          </CalendarHeader>
          <FormContainer>
            <Formik
              initialValues={event || {
                description: "",
                endDate: new Date(),
                startDate: new Date(),
                serviceId: 0,
                clientId: clientId ? clientId : 0,
                serviceGroupNameId: 0,
                serviceTopicId: 0,
                serviceRoomId: 0,
                trackId: 0,
                note: "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {() => (
                <Form id={servicesCreationModel.formId}>
                  <Grid container rowSpacing={"15px"} columnSpacing={"49px"}>
                    <Grid item xs={12}>
                      {getInputComponent(
                        servicesCreationModel.formField.serviceId
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <StyledToggleButtonsGroup
                        color="primary"
                        value={clientsType}
                        exclusive
                        onChange={onClientsTypeChange}
                        aria-label="Platform"
                      >
                        <ToggleButton value="assigned">Assigned clients</ToggleButton>
                        <ToggleButton value="all">All clients</ToggleButton>
                      </StyledToggleButtonsGroup>
                    </Grid>
                    <Grid item xs={12}>
                      {getInputComponent(
                        servicesCreationModel.formField.clients
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      {getInputComponent(
                        servicesCreationModel.formField.startDate
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      {getInputComponent(
                        servicesCreationModel.formField.endDate
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {getInputComponent(
                        servicesCreationModel.formField.description
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {getInputComponent(
                        servicesCreationModel.formField.serviceGroupNameId
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {getInputComponent(
                        servicesCreationModel.formField.serviceTopicId
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {getInputComponent(
                        servicesCreationModel.formField.serviceRoomId
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {getInputComponent(
                        servicesCreationModel.formField.track
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {getInputComponent(
                        servicesCreationModel.formField.note
                      )}
                    </Grid>
                    {event?.attendanceStatus && <Grid item xs={8}>
                      <InputLabel>Status: {event?.attendanceStatus}</InputLabel>
                    </Grid>}
                    {event?.psychosocialAssessmentForm && <Grid item xs={4} sx={{marginLeft: 'auto'}}>
                      <InputLabel onClick={() => handleClick(`/${event?.psychosocialAssessmentForm}`)} sx={{cursor: 'pointer'}}>Go to form</InputLabel>
                    </Grid>}
                  </Grid>
                  <ButtonGroup>
                    <Button type="submit" variant="contained">
                      Save
                    </Button>
                    <Button variant="outlined" onClick={() => onClose()}>
                      Close
                    </Button>
                  </ButtonGroup>
                </Form>
              )}
            </Formik>
          </FormContainer>
        </PopupContainer>
      </PopupBody>
    </Modal>
  );
};
