import React, { useEffect, useState } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";

const CheckboxGroup = React.memo((props) => {
  const { value, name, setValue, error, fields } = props;
  const [checkedItems, setCheckedItems] = useState([]);

  useEffect(() => {
    if (value) {
      setCheckedItems(value);
    }
  }, [value]);

  const renderHelperText = () => {
    if (error) {
      return <FormHelperText error>{error}</FormHelperText>;
    }
  };

  const onChange = (id) => (e) => {
    if (e.target.checked) {
      const newValue = value?.length ? [...value, id] : [id];
      setValue(name, newValue);
    } else {
      const filreredValue = value?.filter((item) => item !== id);
      setValue(name, filreredValue);
    }
  };

  return (
    <>
      {fields?.map((item) => {
        return (
          <FormControl
            key={item.id}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <FormControlLabel
              control={<Checkbox onChange={onChange(item.id)} value={checkedItems?.includes(item.id) || false}/>}
              checked={checkedItems?.includes(item.id)}
              label={item.name}
            />
            {renderHelperText()}
          </FormControl>
        );
      })}
    </>
  );
});
export default React.memo(CheckboxGroup);
