import React from "react";
import SelectField from "../../shared/components/SelectField";
import { ErrorMessage } from "formik";
import { FormHelperText } from "@mui/material";

const SelectFieldForForm = (props) => {
  const {value, name, setValue, label, data, disabled, width, required, error } = props;
  const onChange = (event) => {
    setValue(name, event.target.value);
  };
  const requiredLabel = required ? label + '*' : label
  return (
    <>
      <SelectField
        label={requiredLabel}
        id={name}
        name={name}
        data={data}
        width={width}
        value={value}
        onChange={onChange}
        disabled={disabled}
        required={required}
        sx={{border: error ? "1px solid red" : 'none', borderRadius: '5px'}}
      />
         <ErrorMessage component="div" name={name} className="input-feedback">
        {(msg) => <FormHelperText error>{msg}</FormHelperText>}
      </ErrorMessage>
    </>
  );
};
export default React.memo(SelectFieldForForm);
