import { NavigationBar } from "./navigationBar/NavigationBar";
import styled from "styled-components";
import ErrorAlert from "../shared/components/ErrorAlert";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "../store/slices/ErrorSlice";
import { SidebarMenu } from "./sidebarMenu/SidebarMenu";

const ComponentWrapper = styled.div`
  margin-left: 54px;
  max-width: calc(100% - 54px);
  width:100%
`;

const ContentWrapper = styled.div`
  margin-top: 53px; 
  display: flex;
`;

export const PageLayout = (props) => {
  const error = useSelector((state) => state.error.message);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (error) setOpen(true);
  }, [error]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    dispatch(setError(""));
  };
  return (
    <div>
      <NavigationBar />
      <ContentWrapper>
        <SidebarMenu/>
        <ComponentWrapper>
          {props.children}
        </ComponentWrapper>
      </ContentWrapper>
      <ErrorAlert handleClose={handleClose} open={open} errorMessage={error} />
    </div>
  );
};
