import Box from "@mui/material/Box";
import { SignatureItem } from "./SignatureItem";
import { useGetUsersRolesQuery } from "../../../services/ClientService";
import useFetchWithMsal from "../../../utils/useFetchWithMsal";
import { useEffect, useState } from "react";

export const Signatures = (props) => {

  const { skip } = useFetchWithMsal();

  const { signatures, basicInfo, requiredSignatures, getSignatureId, getUnsignId } = props;
  const { data: userRoles, isLoading } = useGetUsersRolesQuery({ skip });
  const [sortedSignatures, setSortedSignatures] = useState([]);

  useEffect(() => {
    if (requiredSignatures) {
      const sortedData = [...requiredSignatures].sort((a, b) => a.priority - b.priority); 
      setSortedSignatures(sortedData);
    }
  }, [requiredSignatures]);
  
  return (
      <>
        <Box sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}>
          {sortedSignatures.map(item => (
            <SignatureItem
              info={basicInfo}
              self={item?.roleId === basicInfo?.roleId}
              userRoles={userRoles}
              signatures={signatures}
              signatureInfo={item}
              getSignatureId={getSignatureId}
              getUnsignId={getUnsignId}
            />
          ))}
        </Box>
      </>
  );
};
