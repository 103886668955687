import React, { useEffect, useState } from 'react';
import { Button, Grid, IconButton, InputLabel } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { TextareaFieldStyled } from '../../common/TextAreaField';
import { useEditUrineScreenSubstanceMutation, useGetUrineScreenSubstanceQuery, useGetUrineScreenTestResultQuery, useRemoveUrineScreenSubstanceMutation } from '../../../services/ClientService';
import useFetchWithMsal from '../../../utils/useFetchWithMsal';
import SelectField from '../../../shared/components/SelectField';
import InputField from '../../common/InputField';
import { LoadingPanel } from '../../../shared/components/LoadingPanel';
import { AddElementButton } from '../TableTab.styled';
import ErrorAlert from '../../../shared/components/ErrorAlert';
import { useDispatch } from "react-redux";
import { setError } from "../../../store/slices/ErrorSlice";


export const ThirdTypeElementComponent = ({ element, onChange, onDelete }) => {
  const [newElementFieldVisible, setNewElementFieldVisible] = useState(false);
  const [newElementItemValue, setNewElementItemValue] = useState('');
  const [itemId, setItemId] = useState(null);
  const [filteredSubstanceData, setFilteredSubstanceData] = useState(null);
  const dispatch = useDispatch();


  const handleInputChange = (key, value) => {
    const updatedElement = { ...element, [key]: value instanceof Date ? value.toISOString() : value };
    onChange(updatedElement);
  };
  
  const { skip } = useFetchWithMsal();
  const { data } = useGetUrineScreenTestResultQuery("", { skip });
  const [postUrineScreenSubstance, {data: urineScreenSubstanceData, isLoading: isSubstanceLoading, error}] = useEditUrineScreenSubstanceMutation();
  const [removeUrineSubstance] = useRemoveUrineScreenSubstanceMutation();

  const { data: substanceData, isLoading } = useGetUrineScreenSubstanceQuery({urineScreenSubstanceData, itemId}, { skip });

  const filterDeletedSubstanceItems = () => {
    setFilteredSubstanceData(substanceData?.filter(item => item.isDeleted !== true || item.id === element['Substance']));
  }

  useEffect(() => {
    filterDeletedSubstanceItems()
  }, [substanceData]);

  useEffect(() => {
    dispatch(setError(error?.message));
  }, [error]);
  
  useEffect(() => {
    if (itemId) {
      handleInputChange('Substance', itemId);
    }
  }, [itemId]);
  
  const addNewSubstanceElement = () => {
    setNewElementItemValue('');
    setNewElementFieldVisible(false);
    
    postUrineScreenSubstance({
      id: 0,
      name: newElementItemValue
    }).then((response) => {
      handleInputChange('Substance', response?.data?.id);
    });
  };
  

  const deleteItem = newItemId => {
    if(newItemId !== element['Substance']) {
      removeUrineSubstance(newItemId).then((response) => {
        console.log(response)
        setItemId(newItemId === itemId ? itemId+1 : itemId);
      });
    } else {
      dispatch(setError('You can not delete current value'))
    }
  }

  return (
    <Grid container sx={{padding: '10px', marginBottom: '20px', boxShadow: '0px 1px 2px 0px #00000040', background: '#fff'}} spacing={5} alignItems="center">
      
      <Grid item xs={6}>
        <InputLabel htmlFor="input" sx={{ height: "14px" }}>
          Substance
        </InputLabel>
        {(isLoading || isSubstanceLoading) && <LoadingPanel />}
        <Grid container xs={12} style={{display: 'flex', alignItems: 'center'}}>
          <Grid item xs={8}>
            <SelectField
              name={'Substance'}
              data={filteredSubstanceData}
              value={element['Substance']}
              setValue={(e) => handleInputChange('Substance', e.target.value)}
              onChange={(e) => handleInputChange('Substance', e.target.value)}
              deleteItem={(itemId) => deleteItem(itemId)}
              width="100%"
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <AddElementButton onClick={() => setNewElementFieldVisible(!newElementFieldVisible)}>
              Add value
            </AddElementButton>
          </Grid>
        </Grid>
        {newElementFieldVisible && <Grid container xs={12} style={{display: 'flex', alignItems: 'center'}}>
          <Grid item xs={8}>
            <TextareaFieldStyled
              name={'SubstanceNewValue'}
              value={newElementItemValue}
              setValue={(e) => setNewElementItemValue(e.target.value)}
              onChange={(e) => setNewElementItemValue(e.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <AddElementButton onClick={() => addNewSubstanceElement()}>
              Save value
            </AddElementButton>
          </Grid>
        </Grid>}
      </Grid>
      <Grid item xs={6}>
        <InputLabel htmlFor="input" sx={{ height: "14px" }}>
          Test Result
        </InputLabel>
        <SelectField
            name={'Test Result'}
            data={data}
            value={element['Test Result']}
            setValue={(e) => handleInputChange('Test Result', e.target.value)}
            onChange={(e) => handleInputChange('Test Result', e.target.value)}
            width="100%"
            fullWidth
          />
      </Grid>
      <Grid item xs={6}>
        <InputLabel htmlFor="input" sx={{ height: "14px" }}>
          Measured Result
        </InputLabel>
        <TextareaFieldStyled
          minRows={3}
          value={element['Measured Result']}
          onChange={(e) => handleInputChange('Measured Result', e.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <InputLabel htmlFor="input" sx={{ height: "14px" }}>
          Reference Range
        </InputLabel>
        <TextareaFieldStyled
          minRows={3}
          value={element['Reference Range']}
          onChange={(e) => handleInputChange('Reference Range', e.target.value)}
        />
      </Grid>
      <Grid item sx={{margin: '0 auto'}}>
        <IconButton onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};
