import styled from "styled-components";

export const GridContainer = styled.div`
  display: grid;
  gap: ${(props) => (props.gap ? props.gap : "28px")};
  grid-template-columns: ${(props) =>
    props.template ? props.template : "inherit"};
  align-items: end;
  margin-bottom: ${(props) => (props.mb ? props.mb : "0")};
`;
export const ImgContainer = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  align-items: end;
  justify-content: center;
`;
export const FlexContainer = styled.div`
  width: ${(props) => (props.width ? props.width : "auto")};
  display: flex;
  justify-content: ${(props) => (props.position ? props.position : "inherit")};
  align-items: end;
  margin-bottom: ${(props) => (props.mb ? props.mb : 0)};
  margin-top: ${(props) => (props.mt ? props.mt : 0)};
  gap: 20px
`;

export const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
export const Groups = styled.div`
  max-width: 290px;
  background-color: rgba(239, 245, 248, 0.5);
  width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
