import React from "react";
import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Popover,
  Select,
  Typography,
} from "@mui/material";

const SelectWithCheckbox = (props) => {
  const {value, name, setValue, error, errorText, label, data, width, ...rest } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedValues, setSelectedValues] = React.useState('');
  const [dropdownOpen, setDropdownOpen] = React.useState(false);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  
  const renderHelperText = () => {
    if (error) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  };

  const handleChange = (event) => {
    switch (event.target.value[event.target.value.length - 1]) {
      case -1:
        const IDs = data.map((track) => track.id);
        setValue(name, IDs);
        break;
      case -2:
        setValue([]);
        break;
      default:
        if (event.target.value[0] === 0) {
          const value = event.target.value.slice(1);
          setValue(name, value);
        } else {
          setValue(name, event.target.value);
        }
        break;
    }

    // Close the popover after a selection is made
    handlePopoverClose();
  };

  const handleDropdownOpen = () => {
    setDropdownOpen(true);
    handlePopoverClose(); // Close popover when dropdown opens
  };

    const handleDropdownClose = () => {
    setDropdownOpen(false);
  };

  return (
    <>
      <InputLabel id={label}>{label}</InputLabel>
      <FormControl error={!!error} style={{width:'100%'}}>
        <Select
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          onOpen={handleDropdownOpen}
          onClose={handleDropdownClose}
          multiple
          labelId={label}
          defaultValue={[]}
          value={value}
          onChange={handleChange}
          renderValue={(selected) => {
            const names = []
            data?.forEach(item =>{
              if( selected.includes(item.id)){
                names.push(item.name) 
              }
            })
            setSelectedValues(names.join(', '))
            return names.join(', ')

          }
          }
          sx={{ maxWidth: width || "290px", width:'100%' }}
        >
          <MenuItem value={-1}>Check all</MenuItem>
          <MenuItem value={-2}>Uncheck all</MenuItem>
          {data?.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              <Checkbox checked={value?.includes(item.id)} />
              <ListItemText primary={item.name} />
            </MenuItem>
          ))}
        </Select>
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography sx={{ p: 1, maxWidth: width || "290px", width:'100%' }}>{selectedValues}</Typography>
        </Popover>
        {renderHelperText()}
      </FormControl>
    </>
  );
};
export default React.memo(SelectWithCheckbox);
