import React from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';

const SelectField = (props) => {
  const {
    errorText,
    label,
    data,
    isError,
    width,
    value,
    onChange,
    disabled,
    labelType,
    marginBottom,
    deleteItem,
    ...rest
  } = props;

  const handleDeleteItemClick = (event, id) => {
    event.stopPropagation();
    deleteItem(id);
  };

  const handleMenuItemClick = (event) => {
    event.stopPropagation();
  };

  return (
    <Box sx={{textAlign:'start', marginBottom: marginBottom || 0}}>
      {label ? 
        <InputLabel id={label}>
          <Typography variant={labelType || "body1"}>{label}</Typography>
        </InputLabel>
      : null }
      <FormControl {...rest} error={isError} style={{width:'100%'}}>
        <Select
          disabled={disabled}
          labelId={label}
          defaultValue={ 0 || ''}
          value={value || (value === 0 ? 0 : "")}
          sx={{ maxWidth: width || "290px" , width:'100%'}}
          onChange={onChange}
        >
          {value === 0 && <MenuItem value={0} style={{ display: 'none' }}> </MenuItem>}
          {data?.map((item) => (
            <MenuItem
              key={item.id || item}
              value={item.id || (item.id === 0 ? 0 : item)}
              onClick={handleMenuItemClick}
            >
              {item.name || item}
              {item.isCreatedByUser && (
                <IconButton
                  sx={{height: 15, width: 15, marginLeft: 'auto'}}
                  onClick={(event) => handleDeleteItemClick(event, item.id)}
                  className='trash-icon'
                >
                  <DeleteIcon sx={{height: 15}} />
                </IconButton>
              )}
            </MenuItem>
          ))}
        </Select>
        {isError && <FormHelperText>{errorText}</FormHelperText>}
      </FormControl>
    </Box>
  );
};
export default SelectField;
