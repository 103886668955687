import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";

const StyledBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  // backgroundColor: "#ffffffbd",
  position: "absolute",
  zIndex: "1000",
  justifyContent: "center",
  height: "100%",
}));
const StyledProgress = styled(CircularProgress)(({ theme }) => ({
  position: "absolute",
  top: "50vh",
}));

export const LoadingPanel = () => {
  return (
    <StyledBox>
      <StyledProgress />
    </StyledBox>
  );
};
