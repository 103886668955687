import { Box, Button, styled } from "@mui/material";

export const SignatureDowloadTabContainer = styled(Box)({
  width: '700px',
  margin: '0 auto',
  height: '405px',
  background: '#ffffff',
  boxShadow: '0px 1px 3px 0px #00000040',
  padding: '40px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between'
});

export const TopPanel = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
});

export const DropzoneArea = styled(Box)({
  width: '100%',
  height: '220px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#f8efe7',
  flexDirection: 'column',
});

export const DropzoneTitle = styled(Box)({
  fontSize: '12px',
  fontWeight: '600',
  lineHeight: '20px',
  margin: '10px 0'
});

export const DropzoneSubTitle = styled(Box)({
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '20px',
  marginBottom: '10px'
});

export const StyledButton = styled(Button)({
  fontSize: '12px',
  background: '#fff'
});
