import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  AddItemContainer,
  AddItemOption,
  AddItemOptionsContainer,
  CalendarContainer,
  CalendarHeader,
  CalendarToolbar,
  CalendarToolsContainer,
  CheckboxContainer,
  CheckboxSubItems,
  DatePickerContainer,
  DateRangeContainer,
  EventClient,
  EventContainer,
  EventContent,
  EventFooter,
  EventHeader,
  EventMaterials,
  EventName,
  EventRequestSatatus,
  EventStatus,
  FilterGroup,
  PopupBody,
  PopupButton,
  RangePickerContainer,
  RangePickerItem,
  ShowMoreButton,
} from "./Calendar.styled";
import { Backdrop, Box, Button, Checkbox, Modal, Typography } from "@mui/material";
import useFetchWithMsal from "../../utils/useFetchWithMsal";
import {
  useCreateClientServiceMutation,
  useEditNoteMutation,
  useGetAssignmentClientsMutation,
  useGetEventsMutation,
  useGetNotesTypesQuery,
  useGetServicesQuery,
  useGetUserInfoQuery,
  usePostNoteMutation,
  useUpdateClientServiceMutation,
} from "../../services/ClientService";
import { AddServicesPopup } from "./addServicesPopup/AddServicesPopup";
import { AddNotePopup } from "./addServicesPopup/AddNotePopup";
import DatePickerField from "../common/DatePickerField";
import brownArrowDown from "../../shared/icons/brownArrowDown.svg";
import leftRoundedArrow from "../../shared/icons/leftRoundedArrow.svg";
import rightRoundedArrow from "../../shared/icons/rightRoundedArrow.svg";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { CalendarSearchInput, CalendarSearchInputContainer, ToolsImg } from "../table/TableTab.styled";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Search } from "@mui/icons-material";
import { LoadingPanel } from "../../shared/components/LoadingPanel";
import { ProgressNotes } from '../clientInfo/accordionItem/progressNotes/ProgressNotes';
import { ButtonGroup } from "./addServicesPopup/AddServicesPopup.styled";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const localizer = momentLocalizer(moment);

const Toolbar = (props) => <div></div>;

const ConfirmationModal = ({ open, message, onConfirm, onCancel }) => (
  <Dialog open={open} onClose={onCancel}>
    <DialogTitle>Confirmation</DialogTitle>
    <DialogContent>
      <DialogContentText dangerouslySetInnerHTML={{ __html: message }} />
    </DialogContent>
    <DialogActions style={{ justifyContent: 'center' }}>
      <ButtonGroup>
        <Button type="submit" variant="contained" onClick={onConfirm}>
          Save
        </Button>
        <Button variant="outlined" onClick={onCancel}>
          Close
        </Button>
      </ButtonGroup>
    </DialogActions>
  </Dialog>
);


export const CalendarComponent = ({
  onClose,
  open,
  type,
  clientIdForm,
  selectedUserName
}) => {
  const initialStartDate = dayjs((new Date().setMonth(new Date().getMonth())));
  const initialEndDate = dayjs((new Date().setMonth(new Date().getMonth())));

  const [selectedDate, setSelectedDate] = useState(dayjs(new Date()));
  const [calendarDate, setCalendarDate] = useState(dayjs(new Date()));
  const [calendarDateRange, setCalendarDateRange] = useState('');
  const [calendarView, setCalendarView] = useState("month");
  const [isShowAddServicePopup, setIsShowAddServicePopup] = useState(false);
  const [isShowEditNotePopup, setIsShowEditNotePopup] = useState(false);
  const [changebleEvent, setChangebleEvent] = useState(null);
  const [events, setEvents] = useState([]);

  const [clientsVisible, setClientsVisible] = useState(false);
  const [servicesVisible, setServicesVisible] = useState(false);
  const [notesVisible, setNotesVisible] = useState(false);

  const [addedNote, setAddedNote] = useState(null);
  const [addedService, setAddedService] = useState(null);

  const [searchValue, setSearchValue] = useState('');

  const [clientName, setClientName] = useState('');
  const [clientId, setClientId] = useState('');

  const [selectedClients, setSelectedClients] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedNotes, setSelectedNotes] = useState([]);

  const [allClientsView, setAllClientsView] = useState(true);
  const [allServicesView, setAllServicesView] = useState(type !== 'notes' ? true : false);
  const [allNotesView, setAllNotesView] = useState(type !== 'services' ? true : false);

  const [clientsType, setClientsType] = useState('assigned');


  const [dateFrom, setDateFrom] = useState(initialStartDate);
  const [dateTo, setDateTo] = useState(initialEndDate);

  const [isAddItemDropdownVisible, setIsAddItemDropdownVisible] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [pendingData, setPendingData] = useState(null);
  const [pendingType, setPendingType] = useState('');

  const { skip } = useFetchWithMsal();
  // const { data } = useGetClientServiceQuery(clientId, { skip });
  // const { data: assignmentClients } = useGetAssignmentClientsQuery({ skip });
  const [getClients, { data: assignmentClients }] = useGetAssignmentClientsMutation({
    startDate: dateFrom,
    endDate: dateTo,
    isShowAll: clientsType === 'all' ? true : false
  }, { skip })

  const [editNote, { isLoading: isEditNodeLoading }] = useEditNoteMutation();
  const [postNote, { isLoading: isPostNodeLoading }] = usePostNoteMutation();

  const { data: basicInfo } = useGetUserInfoQuery({ skip });
  const { data: services } = useGetServicesQuery({ skip });
  const { data: notesTypes } = useGetNotesTypesQuery({ skip });
  const [dateSelectionOpen, setDateSelectionOpen] = useState(false);


  // const [getNotes, { data: notesData, isLoading }] = useGetNotesForPeriodMutation({
  //   startDate: dateFrom,
  //   endDate: dateTo,
  //   clients: selectedClients.length ? selectedClients : null,
  //   serviceTypes: selectedServices.length ? selectedServices : null,
  //   noteTypes: selectedNotes.length ? selectedNotes : null,
  // }, {skip})

  const [getEvents, { data: eventsData, isLoading }] = useGetEventsMutation({
    clientId: clientId,
    startDate: dateFrom,
    endDate: dateTo,
  }, { skip })

  const [postClientService, { isLoading: isEditServiceLoading }] = useCreateClientServiceMutation();
  const [updateClientService, { isLoading: isPostServiceLoading }] = useUpdateClientServiceMutation();

  useEffect(() => {
    setCalendarDateRange(getDateRange(calendarDate));
  }, [calendarDate]);

  useEffect(() => {
    setClientId(clientIdForm);
    setClientName(selectedUserName)
  }, [clientIdForm, selectedUserName]);

  useEffect(() => {
    setIsAddItemDropdownVisible(false);
  }, [isShowAddServicePopup, isShowEditNotePopup]);

  const formatDate = (inputDate, rangeType) => {
    const months = [
      'January', 'February', 'March', 'April',
      'May', 'June', 'July', 'August',
      'September', 'October', 'November', 'December'
    ];

    const dateObj = new Date(inputDate);
    const month = months[dateObj.getUTCMonth()];
    const day = dateObj.getUTCDate();
    if (rangeType === 'month') {
      return month
    }
    if (rangeType === 'week') {
      return `${month} ${day}`
    }
    if (rangeType === 'day' || rangeType === 'agenda') {
      return `${month} ${day}`
    }
  }

  const handleClientsTypeChange = (event, type) => {
    setClientsType(type);
  };

  const handleConfirm = () => {
    if (pendingData && pendingType) {
      const data = { ...pendingData, isUserForcedSave: true };
      if (pendingType === "change") {
        updateClientService(data).then(() => {
          setAddedService(data);
          onPopupClose();
        });
      } else {
        postClientService({ clientId, data }).then(() => {
          setAddedService(data);
          onPopupClose();
        });
      }
      setModalOpen(false);
    }
  };

  const handleCancel = () => {
    setModalOpen(false);
  };

  const onCreateServiceWrapper = (data, type) => {
    setPendingData(data);
    setPendingType(type);
    onCreateService(data, type);
  };

  const getDateRange = (date, startDate, endDate, range) => {
    if (range === 'day' || range === 'agenda') {
      const formattedDate = formatDate(date, 'day')
      return formattedDate;
    }
    if (range === 'month') {
      const formattedDate = formatDate(date, 'month')
      return formattedDate;
    }
    if (calendarView === 'week') {

      // Check if startDate is not Sunday, adjust it to the previous Sunday
      let adjustedStartDate = new Date(startDate);
      const dayOfWeek = adjustedStartDate.getDay();

      if (dayOfWeek !== 0) {
        // Calculate how many days to subtract to get to the previous Sunday
        adjustedStartDate.setDate(adjustedStartDate.getDate() - dayOfWeek);
      }

      // Adjust endDate based on the new startDate (which is now Sunday)
      let adjustedEndDate = new Date(adjustedStartDate);
      adjustedEndDate.setDate(adjustedStartDate.getDate() + 6);

      const formattedDateStart = formatDate(adjustedStartDate, 'week');
      const formattedDateEnd = formatDate(adjustedEndDate, 'week');
      return `${formattedDateStart} - ${formattedDateEnd}`;
    }
  }


  const changeMonth = (next) => {
    console.log('calendarDate:', calendarDate);
    console.log('calendarView:', calendarView);
    const date = new Date(calendarDate);
    let newStartDate = new Date(date);
    let newEndDate = new Date(date);

    if (calendarView === 'month') {
      // Calculate the new month
      const currentMonth = date.getMonth();
      const newMonth = currentMonth + next;

      // Update the month and set the date to the first day of the new month
      date.setMonth(newMonth, 1);

      // Start date: First day of the new month
      newStartDate = new Date(date.getFullYear(), date.getMonth(), 1);

      // End date: Last day of the new month
      newEndDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    } else if (calendarView === 'week') {
      // Calculate the number of days to shift (7 days forward or backward)
      const dayShift = next * 7;

      if (dayShift === 0) {

        const currentDay = date.getDay(); // 0 is Sunday, 6 is Saturday
        const daysToSubtract = currentDay; // Number of days to go back to reach Sunday
        newStartDate = new Date(date);
        newStartDate.setDate(date.getDate() - daysToSubtract);

      } else {

        // Adjusting the start date correctly across month boundaries
        newStartDate.setDate(date.getDate() + dayShift);
        newStartDate = new Date(newStartDate); // Ensure new instance after modifying

      }


      // Set new end date to 6 days after the new start date
      newEndDate = new Date(newStartDate);
      newEndDate.setDate(newStartDate.getDate() + 6);

      // Update the calendarDate to reflect the new week start date
      date.setTime(newStartDate.getTime());
    } else if (calendarView === 'day' || calendarView === 'agenda') {
      // Shift by one day forward, backward, or no shift if next is 0
      const dayShift = next;
      date.setDate(date.getDate() + dayShift);

      // Start date and end date are the same in day view
      newStartDate = new Date(date);
      newEndDate = new Date(date);
    }

    // Update calendarDate
    setCalendarDate(moment(date).startOf('day').toDate());

    // Update the From and To dates
    newStartDate.setHours(0, 0, 0, 0);
    setDateFrom(newStartDate.toISOString());

    newEndDate.setHours(23, 59, 59, 999);
    setDateTo(newEndDate.toISOString());


    // Logging the date range
    console.log('Navigated Date Range:', {
      StartDate: newStartDate.toISOString(),
      EndDate: newEndDate.toISOString(),
      View: calendarView,
      CalendarDate: date,
      Next: next,
    });
  };




  useEffect(() => {
    if (calendarView === 'day' || calendarView === 'month' || calendarView === 'week' || calendarView === 'view' || calendarView === 'agenda') {
      console.log('setCalendarDateRange:', {
        StartDate: dateFrom,
        EndDate: dateTo,
        View: calendarView,
        CalendarDate: calendarDate,
      });
      setCalendarDateRange(getDateRange(calendarDate, dateFrom, dateTo, calendarView))
    }
  }, [calendarDate]);

  useEffect(() => {
    if (clientIdForm) {
      setSelectedClients([clientIdForm]);
    }
  }, [clientIdForm]);

  useEffect(() => {
    getEvents({
      startDate: dateFrom,
      endDate: dateTo,
      clients: selectedClients.length ? selectedClients : null,
      serviceTypes: selectedServices.length ? selectedServices : null,
      noteTypes: selectedNotes.length ? selectedNotes : null,
      searchText: searchValue || null,
      showService: allServicesView,
      showNote: allNotesView,
    });
  }, [
    selectedClients.length,
    selectedServices.length,
    selectedNotes.length,
    dateFrom,
    dateTo,
    searchValue,
    allServicesView,
    allNotesView,
    addedNote,
    addedService,
  ]);

  useEffect(() => {
    getClients({
      startDate: dateFrom,
      endDate: dateTo,
      isShowAll: clientsType === 'all' ? true : false
    });
  }, [dateFrom, dateTo, clientsType]);


  const getNameById = id => {
    const foundItem = notesTypes?.find(item => item.id === id);

    return foundItem ? foundItem.name : null;
  }

  useEffect(() => {
    if (eventsData) {
      const result = eventsData.map((item) => {
        if (item.type !== "note") {
          const startDate = new Date(item.startDate);
          const endDate = new Date(item.endDate);
          return {
            title: item.name,
            start: new Date(
              startDate.getTime() - startDate.getTimezoneOffset() * 60000
            ),
            end: new Date(
              endDate.getTime() - endDate.getTimezoneOffset() * 60000
            ),
            id: item.id,
            materials: item.materials,
            status: item.status,
            description: item.description,
            serviceId: item.serviceId,
            type: item.type,
            clientFirstName: item.clientFirstName,
            clientLastName: item.clientLastName,
            clientId: item.clientId,
            owner: item.owner,
            serviceGroupNameId: item.serviceGroupNameId,
            serviceTopicId: item.serviceTopicId,
            serviceRoomId: item.serviceRoomId,
            attendanceStatus: item.attendanceStatus,
            trackId: item.trackId,
            note: item.note,
            psychosocialAssessmentForm: item.psychosocialAssessmentForm,
            isEditable: item.isEditable
          };
        } else {
          const startDate = new Date(item.createDate);
          const endDate = new Date(item.createDate);
          return {
            owner: item.owner,
            title: item.text,
            start: new Date(
              startDate.getTime() - startDate.getTimezoneOffset() * 60000
            ),
            end: new Date(
              endDate.getTime() - endDate.getTimezoneOffset() * 60000
            ),
            id: item.id,
            status: getNameById(item.quickNoteTypeId),
            ownerId: item.ownerId,
            quickNoteTypeId: item.quickNoteTypeId,
            type: item.type,
            clientFirstName: item.client.firstName,
            clientLastName: item.client.lastName,
            clientId: item.clientId,
            requestStatus: item.quickNoteRequestSatatusId,
            requestStatusName: item.quickNoteRequestSatatus?.name
          };
        }
      });
      setEvents(result);
    }
  }, [eventsData?.length]);

  const CustomEvent = ({ event }) => {
    const materials = event.materials?.map((item) => item.name).join(", ");
    const time =
      calendarView === "month"
        ? `${moment(event.start).format("hh:mm")} - ${moment(event.end).format(
          "hh:mm"
        )}`
        : "";
    const onClick = () => {
      if (event.isEditable) {
        setClientName(`${event.clientFirstName} ${event.clientLastName}`)
        setClientId(event.clientId)
        if (event.type !== "note") {
          const data = {
            startDate: new Date(event.start).toISOString(),
            endDate: new Date(event.end).toISOString(),
            date: new Date(event.start).toISOString(),
            description: event.description,
            materials: event.materials,
            id: event.id,
            serviceId: event.serviceId,
            clientId: event.clientId,
            serviceGroupNameId: event.serviceGroupNameId,
            serviceTopicId: event.serviceTopicId,
            serviceRoomId: event.serviceRoomId,
            attendanceStatus: event.attendanceStatus,
            trackId: event.trackId,
            note: event.note,
            psychosocialAssessmentForm: event.psychosocialAssessmentForm
          };
          setChangebleEvent(data);
          setIsShowAddServicePopup(true);
        } else {
          const data = {
            date: new Date(event.start).toISOString(),
            noteId: event.id,
            ownerId: event.ownerId,
            quickNoteTypeId: event.quickNoteTypeId,
            text: event.title,
            requestStatus: event.requestStatus,
            requestStatusName: event.quickNoteRequestSatatus?.name
          };
          setChangebleEvent(data);
          setIsShowEditNotePopup(true);
        }
      }
    };
    return (
      event.type === 'note' ? <EventContainer onClick={onClick} height={"100%"} sx={{ background: 'rgba(233, 206, 182, 1)' }}>
        <EventHeader><EventName>{event.owner?.firstName} {event.owner?.lastName}</EventName> <EventStatus>{event.status}</EventStatus></EventHeader>
        <EventContent variant="h6">{event.title}</EventContent>
        <EventMaterials>{event.description}</EventMaterials>
        <EventFooter>
          <EventClient>{event.clientFirstName} {event.clientLastName}</EventClient>
          <EventRequestSatatus>{event.requestStatusName}</EventRequestSatatus>
        </EventFooter>
        {/* <EventMaterials>{materials}</EventMaterials> */}
      </EventContainer> :
        <EventContainer onClick={onClick} height={"100%"}>
          <EventHeader><EventName>{event.owner?.firstName} {event.owner?.lastName}</EventName> <EventStatus>{event.status}</EventStatus></EventHeader>
          <EventContent variant="h6">{event.title}</EventContent>
          {/* <EventMaterials>{event.description}</EventMaterials> */}
          <EventFooter>
            <EventClient>{event.clientFirstName} {event.clientLastName}</EventClient>
            <EventRequestSatatus>{event.attendanceStatus}</EventRequestSatatus>
          </EventFooter>
        </EventContainer>
    );
  };

  const CustomHeader = (event) => {
    const [date, day] = event.label.split(" ");
    return (
      <Box>
        <p className="day-label">{day}</p> <p className="date-label">{date}</p>{" "}
      </Box>
    );
  };

  const onPopupClose = () => {
    setIsShowAddServicePopup(false);
    setIsShowEditNotePopup(false)
    setChangebleEvent(null);
  };

  const extractIds = array => {
    return array.map(item => item.id);
  }

  const onCreateService = (data, type) => {
    data.clients = data?.clientId?.length > 1 ? extractIds(data.clientId) : null;
    data.clientId = clientId ? clientId : data.clientId[0]?.id || data.clientId;
    data.isGroup = data?.clients?.length > 1 ? true : false;
    data.serviceRoomId = data?.serviceRoomId?.id;

    const handleResponse = (response) => {
      if (response?.data?.isConflict) {
        setModalMessage(response.data?.message);
        setModalOpen(true);
      } else {
        setAddedService(data);
        onPopupClose();
      }
    };

    if (type === "change") {
      updateClientService(data).then(handleResponse);
    } else {
      postClientService({ clientId, data }).then(handleResponse);
    }
  };

  const onCreateNote = (data, type) => {
    if (type === "change") {
      editNote({
        data: {
          text: data.text,
          clientId: clientId,
          quickNoteTypeId: data.quickNoteTypeId,
          ownerId: data.ownerId,
          quickNoteRequestSatatusId: data.requestStatus || null,
        }, noteId: data.noteId
      }).then(() => setAddedNote(data));
    } else {
      postNote({
        text: data.text,
        clientId: clientId ? clientId : data.client,
        quickNoteTypeId: data.quickNoteTypeId,
        quickNoteRequestSatatusId: data.requestStatus || null,
      }).then(() => setAddedNote(data));
    }

    onPopupClose();
  };

  const onChange = (date) => {
    onNavigate(date, "calendar");
  };

  const onRangeChange = (range) => {
    setCalendarView(range);
  };

  useEffect(() => {
    if (calendarView) {
      changeMonth(0);  // Ensure the date is adjusted to the correct range after view changes
      setCalendarDateRange(getDateRange(calendarDate, dateFrom, dateTo, calendarView));
    }
  }, [calendarView]);

  const scrollToTestElement = () => {
    const container = document.querySelector('.rbc-time-content');

    if (container) {
      setTimeout(() => {
        const testElement = container.querySelector('.rbc-event');
        if (testElement) {
          container.scrollTop = testElement.offsetTop;
        }
      }, 100);
    } else {
      console.error('Container element not found.');
    }
  }

  useEffect(() => {
    scrollToTestElement();
  }, [selectedDate, calendarView, calendarDate]);

  const onShowMore = (e, calendarDate) => {
    const date = new Date(calendarDate);

    setCalendarView("day");
    setCalendarDate(date);
    setSelectedDate(date);
  };

  const onNavigate = (e, type) => {
    if (type !== "calendar") {
      setCalendarView("day");
    }
    setSelectedDate(e);
    setCalendarDate(new Date(e));
  };

  const setFiltersVisible = (type) => {
    if (type === 'clients') {
      setClientsVisible(!clientsVisible)
    }
    if (type === 'services') {
      setServicesVisible(!servicesVisible)
    }
    if (type === 'notes') {
      setNotesVisible(!notesVisible)
    }
    // if (type !== "calendar") {
    //   setCalendarView("day");
    // }
  };

  const onFilterChange = (id, type) => {
    if (type === 'clientsFilter') {
      const index = selectedClients.indexOf(id);
      if (index === -1) {
        setSelectedClients([...selectedClients, id])
      } else {
        setSelectedClients(selectedClients.filter((note, i) => i !== index));
      }
    }
    if (type === 'servicesFilter') {
      const index = selectedServices.indexOf(id);
      if (index === -1) {
        setSelectedServices([...selectedServices, id])
      } else {
        setSelectedServices(selectedServices.filter((note, i) => i !== index));
      }
    }
    if (type === 'notesFilter') {
      const index = selectedNotes.indexOf(id);
      if (index === -1) {
        setSelectedNotes([...selectedNotes, id])
      } else {
        setSelectedNotes(selectedNotes.filter((note, i) => i !== index));
      }
    }
  };

  const setAllContentFilter = type => {
    if (type === 'clientsFilter') {
      setAllClientsView(!allClientsView)
      setSelectedClients([])
    }
    if (type === 'servicesFilter') {
      setAllServicesView(!allServicesView)
      setSelectedServices([])
    }
    if (type === 'notesFilter') {
      setAllNotesView(!allNotesView)
      setSelectedNotes([])
    }
  };

  const setValue = (name, date) => {
    const originalDate = new Date(date);

    if (name === 'From') {
      originalDate.setHours(0, 0, 0, 0);
      setDateFrom(originalDate.toISOString())
    } else {
      originalDate.setHours(23, 59, 0, 0);
      setDateTo(originalDate.toISOString())
    }
  }

  const dayRangeHeaderFormat = ({ start, end }, culture, local, props) => {

    return (local.format(start, 'MMMM DD', culture) +
      ' – ' +
      // updated to use this localizer 'eq()' method
      local.format(end, local.eq(start, end, 'month') ? 'DD YYYY' : 'MMMM DD YYYY', culture))
  }

  const handleSelectSlot = (slotInfo) => {
    const selectedDate = slotInfo.start;

    // Set the selected date in the state if necessary
    setCalendarDate(selectedDate);

    // Open the Add New dropdown
    setIsAddItemDropdownVisible(true);
  };

  const handleDateChange = (newDate) => {
    if (newDate) {
      setCalendarDate(dayjs(newDate));  // Ensure Day.js format
      setDateSelectionOpen(false);
      getEvents();
    }
  };

  const onDateHeaderClick = () => {
    setDateSelectionOpen(true);
  };

  return (
    <>

      <PopupBody>
        <CalendarHeader>
          <Typography variant="h2">Events Calendar</Typography>
          <CalendarSearchInputContainer>
            <CalendarSearchInput
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
              placeholder='Search'
            />
            <Search />
          </CalendarSearchInputContainer>
          {onClose && <PopupButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </PopupButton>}
        </CalendarHeader>
        <CalendarContainer>
          {(
            isLoading
            || isEditNodeLoading
            || isPostNodeLoading
            || isEditServiceLoading
            || isPostServiceLoading
          ) && <LoadingPanel />}
          <CalendarToolbar>
            {/* <ToolbarButtonGroup>
                {type !== "notes" && <Button
                  variant="outlined"
                  startIcon={<ControlPointIcon />}
                  onClick={() => setIsShowAddServicePopup(true)}
                >
                  Add New
                </Button>}
                <SelectField
                  data={selectData}
                  onChange={onSelectChange}
                  value={calendarView}
                />
              </ToolbarButtonGroup>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar value={selectedDate} onChange={onChange} />
              </LocalizationProvider> */}
            {/* <SelectField
                  data={selectData}
                  onChange={onSelectChange}
                  value={calendarView}
                /> */}
            <FilterGroup>
              <DatePickerContainer>
                <span>
                  From
                </span>
                <DatePickerField
                  name={'From'}
                  width={'148px'}
                  value={dateFrom}
                  setValue={(name, date) => setValue(name, date)}
                />
              </DatePickerContainer>
              <DatePickerContainer>
                <span>
                  To
                </span>
                <DatePickerField
                  name={'To'}
                  width={'148px'}
                  value={dateTo}
                  setValue={(name, date) => setValue(name, date)}
                />
              </DatePickerContainer>
            </FilterGroup>
            <FilterGroup>
              <CheckboxContainer isTitle>
                <Checkbox
                  name={"Clients"}
                  width={'30px'}
                  checked={allClientsView}
                  onChange={() => setAllContentFilter('clientsFilter')}
                />
                <span>
                  Clients
                </span>
              </CheckboxContainer>
              <CheckboxSubItems>
                {selectedUserName ?
                  <CheckboxContainer>
                    <Checkbox
                      name={"Clients"}
                      width={'30px'}
                      checked={true}
                    />
                    <span>
                      {selectedUserName}
                    </span>
                  </CheckboxContainer> :
                  <>
                    {assignmentClients && assignmentClients.map((client, index) => {
                      if (index < 3 || clientsVisible) {
                        return <CheckboxContainer>
                          <Checkbox
                            name={"Clients"}
                            width={'30px'}
                            onChange={() => onFilterChange(client.id, 'clientsFilter')}
                          />
                          <span>
                            {client.firstName} {client.lastName}
                          </span>
                        </CheckboxContainer>
                      }
                    })}
                    <ShowMoreButton onClick={() => setFiltersVisible('clients')}>
                      {!clientsVisible ?
                        <><span>Show more</span><ToolsImg src={brownArrowDown} alt="" /></> :
                        <><span>Show less</span><ToolsImg rotate src={brownArrowDown} alt="" /></>
                      }
                    </ShowMoreButton>
                  </>
                }
              </CheckboxSubItems>
            </FilterGroup>
            <FilterGroup>
              <CheckboxContainer isTitle>
                <Checkbox
                  name={"Clients"}
                  width={'30px'}
                  checked={allServicesView}
                  onChange={() => setAllContentFilter('servicesFilter')}
                />
                <span>
                  Services
                </span>
              </CheckboxContainer>
              <CheckboxSubItems>
                {services && services.map((service, index) => {
                  if (index < 3 || servicesVisible) {
                    return <CheckboxContainer>
                      <Checkbox
                        name={"Clients"}
                        width={'30px'}
                        onChange={() => onFilterChange(service.id, 'servicesFilter')}
                      />
                      <span>
                        {service.name}
                      </span>
                    </CheckboxContainer>
                  }
                })}
                <ShowMoreButton onClick={() => setFiltersVisible('services')}>
                  {!servicesVisible ?
                    <><span>Show more</span><ToolsImg src={brownArrowDown} alt="" /></> :
                    <><span>Show less</span><ToolsImg rotate src={brownArrowDown} alt="" /></>
                  }
                </ShowMoreButton>
              </CheckboxSubItems>
            </FilterGroup>
            <FilterGroup>
              <CheckboxContainer isTitle>
                <Checkbox
                  name={"Clients"}
                  width={'30px'}
                  checked={allNotesView}
                  onChange={() => setAllContentFilter('notesFilter')}
                />
                <span>
                  Notes & alerts
                </span>
              </CheckboxContainer>
              <CheckboxSubItems>
                {notesTypes && notesTypes.map((noteType, index) => {
                  if (index < 3 || notesVisible) {
                    return <CheckboxContainer>
                      <Checkbox
                        name={"Clients"}
                        width={'30px'}
                        onChange={() => onFilterChange(noteType.id, 'notesFilter')}
                      />
                      <span>
                        {noteType.name}
                      </span>
                    </CheckboxContainer>
                  }
                })}
                <ShowMoreButton onClick={() => setFiltersVisible('notes')}>
                  {!notesVisible ?
                    <><span>Show more</span><ToolsImg src={brownArrowDown} alt="" /></> :
                    <><span>Show less</span><ToolsImg rotate src={brownArrowDown} alt="" /></>
                  }
                </ShowMoreButton>
              </CheckboxSubItems>
            </FilterGroup>

          </CalendarToolbar>

          <Box padding={"0 30px"} width={"calc(100% - 250px)"}>
            <CalendarToolsContainer>
              <AddItemContainer>
                <Button
                  variant="outlined"
                  startIcon={<ControlPointIcon />}
                  onClick={() => setIsAddItemDropdownVisible(!isAddItemDropdownVisible)}
                >
                  Add New
                </Button>
                {isAddItemDropdownVisible && <AddItemOptionsContainer>
                  <AddItemOption onClick={() => setIsShowAddServicePopup(true)}>Service</AddItemOption>
                  <AddItemOption onClick={() => setIsShowEditNotePopup(true)}>Note</AddItemOption>
                </AddItemOptionsContainer>}
              </AddItemContainer>

              <DateRangeContainer>
                <ToolsImg src={leftRoundedArrow} alt="" onClick={() => changeMonth(-1)} />
                <span onClick={onDateHeaderClick} style={{ cursor: 'pointer' }}>
                  {calendarDateRange}
                </span>
                <ToolsImg src={rightRoundedArrow} alt="" onClick={() => changeMonth(1)} />
              </DateRangeContainer>

              <RangePickerContainer>
                <RangePickerItem sx={{ background: calendarView === 'day' ? '#fdf7e0' : '#fff' }} onClick={() => onRangeChange('day')}>day</RangePickerItem>
                <RangePickerItem sx={{ background: calendarView === 'week' ? '#fdf7e0' : '#fff' }} onClick={() => onRangeChange('week')}>week</RangePickerItem>
                <RangePickerItem sx={{ background: calendarView === 'month' ? '#fdf7e0' : '#fff' }} onClick={() => onRangeChange('month')}>month</RangePickerItem>
                <RangePickerItem sx={{ background: calendarView === 'agenda' ? '#fdf7e0' : '#fff' }} onClick={() => onRangeChange('agenda')}>list</RangePickerItem>
              </RangePickerContainer>
            </CalendarToolsContainer>
            <Calendar
              localizer={localizer}
              scrollToTime={new Date(calendarDate)}
              events={events}
              date={calendarDate}
              onShowMore={(e, date) => onShowMore(e, date)}
              onNavigate={onNavigate}
              onSelectSlot={handleSelectSlot}
              components={{
                toolbar: Toolbar,
                event: CustomEvent,
                header: CustomHeader,
              }}
              views={["month", "week", "day", "agenda"]}
              defaultView={calendarView}
              startAccessor="start"
              endAccessor="end"
              formats={{ dayRangeHeaderFormat }}
              // formats={{
              //   timeGutterFormat: "h A",
              // }}
              key={calendarView}
              style={{ height: "calc(100% - 45px)" }}

            />
            {/* Conditionally render ProgressNotes in the Agenda view */}
            {/*             {calendarView === "agenda" && (
              <Box sx={{ mt: 3 }}>
                <ProgressNotes />
              </Box>
            )} */}
          </Box>
          {/* Date Selection Dialog */}
          <Dialog open={dateSelectionOpen} onClose={() => setDateSelectionOpen(false)}>
            <DialogTitle>Select Date</DialogTitle>
            <DialogContent>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  views={calendarView === 'month' ? ['year', 'month'] : ['year', 'month', 'day']}
                  label={calendarView === 'month' ? "Select Month" : "Select Date"}
                  value={calendarDate}
                  onChange={handleDateChange}
                />
              </LocalizationProvider>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDateSelectionOpen(false)}>Cancel</Button>
            </DialogActions>
          </Dialog>
        </CalendarContainer>
      </PopupBody>
      <AddServicesPopup
        event={changebleEvent || null}
        clientName={clientName}
        open={isShowAddServicePopup}
        onClose={onPopupClose}
        onClick={onCreateServiceWrapper}
        clientId={clientId}
        clients={assignmentClients}
        onClientsTypeChange={handleClientsTypeChange}
        clientsType={clientsType}
      />
      <AddNotePopup
        event={changebleEvent || null}
        clientName={clientName}
        open={isShowEditNotePopup}
        onClose={onPopupClose}
        onClick={onCreateNote}
        clientId={clientId}
        clients={assignmentClients}
      />
      <ConfirmationModal
        open={modalOpen}
        message={modalMessage}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
    </>
  );
};
