import React from 'react';
import { TextField, Grid, IconButton, InputLabel } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { TextareaFieldStyled } from '../../common/TextAreaField';
import DatePickerField from '../../common/DatePickerField';

export const SecondTypeElementComponent = ({ element, onChange, onDelete }) => {
  const handleInputChange = (key, value) => {
    const updatedElement = { ...element, [key]: value instanceof Date ? value.toISOString() : value };
    onChange(updatedElement);
  };

  return (
    <Grid container sx={{padding: '10px', marginBottom: '20px', boxShadow: '0px 1px 2px 0px #00000040', background: '#fff'}} spacing={5} alignItems="center">
      <Grid item xs={12}>
        <InputLabel htmlFor="input" sx={{ height: "14px" }}>
          Goal
        </InputLabel>
        <TextareaFieldStyled
          minRows={3}
          value={element.Goal}
          onChange={(e) => handleInputChange('Goal', e.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
      <DatePickerField
        label="Тarget Date"
        value={element['TargetDate']}
        setValue={(name, date) => handleInputChange("TargetDate", date)}
        renderInput={(params) => <TextField {...params} />}
      />
      </Grid>
      <Grid item xs={6}>
        <DatePickerField
          label={"Completed Date"}
          value={element['CompletedDate']}
          setValue={(name, date) => handleInputChange("CompletedDate", date)}
          renderInput={(params) => <TextField {...params} />}
        />
      </Grid>
      <Grid item sx={{margin: '0 auto'}}>
        <IconButton onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};
