import { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from '@mui/material';
import styled from '@emotion/styled';
import moment from 'moment';
import { LoadingPanel } from "../../../../shared/components/LoadingPanel";
import useFetchWithMsal from "../../../../utils/useFetchWithMsal";
import { useGetClientFormListQuery } from "../../../../services/ClientService";
import { useParams } from "react-router-dom";

const StyledPaper = styled(Paper)(() => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
}));

const StyledTableHead = styled(TableHead)(() => ({
  th: {
    whiteSpace: 'nowrap',
    background: '#fff',
    color: '#4281AB',
    paddingTop: '6px',
    paddingBottom: '8px',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: '600',
    borderBottom: "1px solid #b2cede",
  },
  '&::after': {
    height: '8px',
    display: 'table-row',
    content: '""'
  }
}));

const StyledTableBody = styled(TableBody)(({ theme }) => ({
  td: {
    color: '#000',
    paddingTop: '15px',
    paddingBottom: '15px',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: '400',
  },
  th: {
    paddingTop: '15px',
    paddingBottom: '15px',
    minWidth: '120px',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: '400',
    a: {
      color: theme.palette.background.main,
      textDecoration: 'none',
    }
  },
  tr: {
    background: '#fff',
    boxShadow: '0px 1px 2px 0px #00000040',
  }
}));

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
    enableSorting: false
  },
  {
    id: 'updateDate',
    numeric: false,
    disablePadding: false,
    label: 'Update Date',
    enableSorting: false,
  },
  {
    id: 'createDate',
    numeric: false,
    disablePadding: false,
    label: 'Create Date',
    enableSorting: false,
  },
  {
    id: 'startDate',
    numeric: false,
    disablePadding: false,
    label: 'Start Date',
    enableSorting: false,
  },
  {
    id: 'endDate',
    numeric: false,
    disablePadding: false,
    label: 'End Date',
    enableSorting: false,
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Attendance Status',
    enableSorting: false,
  },
];

function EnhancedTableHead() {
  return (
    <StyledTableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding='normal'
            sx={{
              minWidth: headCell.id === 'firstName' ? '250px' : 'auto',
            }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </StyledTableHead>
  );
}

export const ProgressNotes = () => {
  const { skip } = useFetchWithMsal();

  const { id } = useParams();
  const { data: clientFormList, isLoading } = useGetClientFormListQuery(id, { skip });

  const [rows, setRows] = useState([]);

  let originalRows = clientFormList

  useEffect(() => {
    if(originalRows) {
      setRows(originalRows)
    }
  },[clientFormList, originalRows] );

  return (
    <Box sx={{ width: '100%'}}>
      {isLoading && <LoadingPanel />}
      <StyledPaper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ 
              minWidth: 750,
              borderCollapse: 'separate',
              borderSpacing: '0 7px',
              padding: '5px',
            }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
              <EnhancedTableHead
                rowCount={rows.length}
                colSpan={6} 
              />
            <StyledTableBody>
              {rows?.length && rows.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.clientServiceId}
                    sx={{ cursor: 'pointer' }}
                    padding='normal'
                  >
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding='normal'
                    >
                      {row.isEditable ?
                        <Link href={`#/psychosocialAssessment/${id}/${row.id}/${row.clientServiceId}`}>{row.name}</Link>:
                        <div style={{color: 'rgba(189, 152, 106, 1)'}}>{row.name}</div>
                      }
                    </TableCell>
                    <TableCell align="left">{row.updateDate ? moment(row.updateDate).utc().format('MM/DD/YYYY') : ''}</TableCell>
                    <TableCell align="left">{row.createDate ? moment(row.createDate).utc().format('MM/DD/YYYY') : ''}</TableCell>
                    <TableCell align="left">{row.startDate ? moment(row.startDate).utc().format('MM/DD/YYYY') : ''}</TableCell>
                    <TableCell align="left">{row.endDate ? moment(row.endDate).utc().format('MM/DD/YYYY') : ''}</TableCell>
                    <TableCell align="left">{row.status}</TableCell>
                  </TableRow>
                );
              })}
            </StyledTableBody>
          </Table>
        </TableContainer>
      </StyledPaper>
    </Box>
  );
}