import styled from "styled-components";

export const NotesPanelContainer = styled.div`
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  background: #fff;
  z-index: 1000;
  height: ${(props) => props.type === 'client-form' ? 'calc(100vh - 70px)' : '100vh'};
  width: ${(props) => props.toggleOption !== 'show-all' ? '311px' : '66%'};
  top: ${(props) => props.type === 'client-form' ? '70px' : '0'};
  padding-top: 34px;
  box-shadow: 0px 1px 3px 0px #00000040;
  overflow-y: auto;
`;

export const NotesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 10px 20px 10px;
`;

export const CloseButton = styled.img`
  background: #e0ebf2;
  padding: 6px;
  border-radius: 50%;
  cursor: pointer;
`;

export const NotesToggle = styled.div`
  background: #e0ebf2;
  border-radius: 2px;
  width: 250px;
  display: flex;
  height: 100%;
  margin-right: 14px;
`;

export const ToggleOption = styled.div`
  background: ${(props) => props.active ? '#fff' : '#e0ebf2'};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  flex: 1;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 600;
  color: #BD986A;
  text-align: center;
  margin: 4px;
  padding: 2px;
  cursor: pointer;
`;

export const AddNoteButton = styled.div`
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  color: #4281AB;
  margin: 0 10px 10px 10px;
  cursor: pointer;
`;

export const AddNoteIcon = styled.img`
  margin-right: 7px;
`;

export const CardContainer = styled.div`
  background: ${(props) => !props.editNoteInfo ? '#fff;' : '#f7f9fb'};
  box-shadow: 0px 1px 3px 0px #00000040;
  width: 282px;
  padding: 17px 20px;
  color: #000;
  font-size: 11px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  margin: 10px;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 6px;
`;

export const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardName = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Open Sans;
  font-weight: 600;
`;

export const CardDate = styled.div`
  display: flex;
  flex-direction: column;
  color: grey;
`;

export const CardStatus = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 10px;
  background: #FBEEC1;
  padding: 2px 5px;
`;

export const CardText = styled.div`
  width: 100%;
`;

export const CardFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  color: #bd986a;
  cursor: pointer;
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const PanelCardsSearchInputContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  overflow: hidden;
  padding: 3px 20px;
  background: #F8EFE7;
  margin-right: 10px;
`;

export const PanelCardsSearchInput = styled.input`
  outline: none;
  border: none;
  width: 100%;
  background: #F8EFE7;
`;

export const AddNoteFormContainer = styled.div`
background: 
  width: 100%;
  min-height: 238px;
  background: ${(props) => !props.editNoteInfo ? '#f8fafb;' : 'none'};
  padding: ${(props) =>  !props.editNoteInfo ? '20px;' : '0px'};
  max-width: 282px;
  margin-left: 10px;
`;

export const FormTitle = styled.div`
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 16px;
`;

export const ButtonsBlock = styled.div`
  display: flex;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 600;
  margin-top: 16px;
  color: #4281AB;
`;

export const Button = styled.div`
  display: flex;
  align-items: center;
  margin-right: 19px;
  cursor: pointer;
`;

export const ButtonImage = styled.img`
  margin-right: 5px;
`;
