import { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styled from '@emotion/styled';
import moment from 'moment';
import { ElementInfoPopup } from "./ElementInfoPopup";
import { AddNewButton } from "./ElementDetailsForm.styled";
import roundedPlus from "../../../shared/icons/roundedPlus.svg";
import useFetchWithMsal from "../../../utils/useFetchWithMsal";
import { useGetClientMedicationFrequencyQuery, useGetClientMedicationMethodQuery, useGetDrugOfChoiceQuery } from "../../../services/ClientService";

const StyledPaper = styled(Paper)(() => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
}));

const StyledTableHead = styled(TableHead)(() => ({
  th: {
    whiteSpace: 'nowrap',
    background: '#fff',
    color: '#4281AB',
    paddingTop: '6px',
    paddingBottom: '8px',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: '600',
    borderBottom: "1px solid #b2cede",
  },
  '&::after': {
    height: '8px',
    display: 'table-row',
    content: '""'
  }
}));

const StyledTableBody = styled(TableBody)(({ theme }) => ({
  td: {
    color: '#000',
    paddingTop: '5px',
    paddingBottom: '5px',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: '400',
    borderBottom: "none",
  },
  th: {
    paddingTop: '5px',
    paddingBottom: '5px',
    minWidth: '120px',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: '400',
    a: {
      color: theme.palette.background.main,
      textDecoration: 'none',
    }
  },
  tr: {
    background: '#fff',
    height: '30px',
    '&:nth-of-type(odd)': {
      background: '#EFF5F8',
    },
  }
}));

const headCells = [
  {
    id: 'drugOfChoice',
    numeric: false,
    disablePadding: false,
    label: 'Drug of Choice',
    enableSorting: false,
  },
  {
    id: 'frequency',
    numeric: false,
    disablePadding: false,
    label: 'Frequency',
    enableSorting: false,
  },
  {
    id: 'qty',
    numeric: false,
    disablePadding: false,
    label: 'QTY',
    enableSorting: false,
  },
  {
    id: 'ageOfFirstUse',
    numeric: false,
    disablePadding: false,
    label: 'Age of First Use',
    enableSorting: false
  },
  {
    id: 'dateOfLastUse',
    numeric: false,
    disablePadding: false,
    label: 'Date of Last Use',
    enableSorting: false,
  },
  {
    id: 'routeOfIngestion',
    numeric: false,
    disablePadding: false,
    label: 'Route of Ingestion',
    enableSorting: false,
  },
];

function EnhancedTableHead() {
  return (
    <StyledTableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding='normal'
            sx={{
              minWidth: headCell.id === 'firstName' ? '250px' : 'auto',
            }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </StyledTableHead>
  );
}

export const GridTypeElementComponent = ({ name, setValue, value }) => {
  const initialValue = value?.items;

  const { skip } = useFetchWithMsal();

  const { data: frequency } = useGetClientMedicationFrequencyQuery("", { skip });
  const { data: routeOfIngestion } = useGetClientMedicationMethodQuery("", { skip });
  const { data: drugOfChoice } = useGetDrugOfChoiceQuery("", { skip });

  const [elementPopupOpen, setElementPopupOpen] = useState(false);
  const [selectedElement, setSelectedElement] = useState({});
  const [selectedElementIndex, setSelectedElementIndex] = useState(null);

  const [elements, setElements] = useState(value ? value : []);

  const transformData = (data) => {
    const transformedData = {
        type: 15,
        items: data.map(item => {
            const { newElement, ...rest } = item;
            return rest;
        })
    };

    return transformedData;
};

  useEffect(() => {
    setValue(name, transformData(elements))
  }, [elements]);

  const [rows, setRows] = useState([]);

  let originalRows = initialValue

  useEffect(() => {
    if(originalRows) {
      setRows(originalRows)
    }
  },[initialValue, originalRows] );

  const closeElementPopup = () => {
    setElementPopupOpen(!elementPopupOpen);
  }

  const openElementPopup = (medication, index) => {
    setSelectedElement(medication);
    setElementPopupOpen(!elementPopupOpen);
    setSelectedElementIndex(index);
  }

  const handleAddElement = () => {
    const newElement = {
        Id: elements.length + 1,
        ageOfFirstUse: '',
        dateOfLastUse: '',
        drugOfChoice: '',
        frequency: '',
        qty: '',
        routeOfIngestion: '',
        newElement: true,
    }
    setElements([...elements, newElement]);
    openElementPopup(newElement, elements.length)
  };

  const findNameById = (id, array) => {
    const foundItem = array?.find(item => item.id == id);
    return foundItem ? foundItem.name : null;
  };

  return (
    <Box sx={{ width: '100%'}}>
      <StyledPaper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ 
              minWidth: 750,
              padding: '5px',
              marginBottom: '15px',
            }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
              <EnhancedTableHead
                rowCount={rows.length}
                colSpan={6} 
              />
            <StyledTableBody>
              {rows?.length && rows.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.clientServiceId}
                    sx={{ 
                      cursor: 'pointer',
                    }}
                    padding='normal'
                  >
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding='normal'
                      onClick={() => openElementPopup(row, index)}
                      style={{
                        color: "#BD986A",
                        cursor: "pointer",
                        borderBottom: "none",
                      }}
                    >
                      
                      {findNameById(row.drugOfChoice, drugOfChoice)}
                    </TableCell>
                    <TableCell align="left">{findNameById(row.frequency, frequency)}</TableCell>
                    <TableCell align="left">{row.qty}</TableCell>
                    <TableCell align="left">{row.ageOfFirstUse}</TableCell>
                    <TableCell align="left">{row.dateOfLastUse ? moment(row.dateOfLastUse).utc().format('MM/DD/YYYY') : ''}</TableCell>
                    <TableCell align="left">{findNameById(row.routeOfIngestion, routeOfIngestion)}</TableCell>
                  </TableRow>
                );
              })}
            </StyledTableBody>
          </Table>
          <AddNewButton onClick={handleAddElement}>
              <img src={roundedPlus}/>add new
          </AddNewButton>
        </TableContainer>
      </StyledPaper>
        {selectedElementIndex !== null && <ElementInfoPopup
          open={elementPopupOpen}
          elements={elements}
          onClose={closeElementPopup}
          setElements={setElements}
          value={value}
          index={selectedElementIndex}
          medication={selectedElement}
          frequency={frequency}
          routeOfIngestion={routeOfIngestion}
          drugOfChoice={drugOfChoice}
        />}
    </Box>
  );
}