import React from "react";
import { Box, Button, Grid } from "@mui/material";
import InputField from "../../common/InputField";
import { Field } from "formik";

export const ContactInfoForm = (props) => {
  const {
    formField: { primaryPhone, cellPhone, email },
  } = props;
  return (
    <React.Fragment>
      <Grid container rowSpacing={"26px"} columnSpacing={"65px"}>
        <Grid item xs={6}>
          <Field name={primaryPhone.name}>
            {({ field, form }) => (
              <InputField
                name={primaryPhone.name}
                label={primaryPhone.label}
                value={field.value}
                setValue={form.setFieldValue}
                error={form.errors[primaryPhone.name]}
                fullWidth
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field name={cellPhone.name}>
            {({ field, form }) => (
              <InputField
                name={cellPhone.name}
                label={cellPhone.label}
                value={field.value}
                setValue={form.setFieldValue}
                error={form.errors[cellPhone.name]}
                fullWidth
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field name={email.name}>
            {({ field, form }) => (
              <InputField
                name={email.name}
                label={email.label}
                value={field.value}
                setValue={form.setFieldValue}
                error={form.errors[email.name]}
                fullWidth
              />
            )}
          </Field>
        </Grid>
      </Grid>
      <Box sx={{ marginTop: "28px", display: "flex", justifyContent: "start" }}>
        <Button type="submit" variant="contained">
          Save Client
        </Button>
      </Box>
    </React.Fragment>
  );
};
