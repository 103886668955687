import Box from "@mui/material/Box";
import { DropzoneArea, DropzoneSubTitle, DropzoneTitle, SignatureDowloadTabContainer, StyledButton, TopPanel } from "./SignatureDowloadTab.styled";
import SelectField from "../../shared/components/SelectField";
import { useState, useEffect } from "react";
import { Button } from "@mui/material";
import Dropzone from 'react-dropzone'
import dropzoneSvg from "../../shared/icons/dropzone.svg";
import { useGetUserInfoQuery, useGetUsersRolesQuery, usePutUserValueMutation } from "../../services/ClientService";
import useFetchWithMsal from "../../utils/useFetchWithMsal";
import { LoadingPanel } from "../../shared/components/LoadingPanel";
import ImageIcon from '@mui/icons-material/Image';

export const SignatureDowloadTab = () => {
  const { skip } = useFetchWithMsal();


  const [role, setRole] = useState();
  const [signatureImage, setSignatureImage] = useState();
  const [fileName, setFileName] = useState();

  const { data: userRoles, isLoading } = useGetUsersRolesQuery({ skip });
  const [putUserValue, { error }] = usePutUserValueMutation();
  const { data: basicInfo } = useGetUserInfoQuery({ skip });

  useEffect(() => {
    if(userRoles){
      setRole(userRoles[0].id)
    }
  }, [userRoles?.length]);

  useEffect(() => {
    if(userRoles) {
      const foundRole = userRoles.find(role => role.id === basicInfo?.roleId);
      if (foundRole) {
        setRole(foundRole.id);
      }
    }
  }, [userRoles?.length, basicInfo]);

  const onStatusChange = (event) => {
    setRole(event.target.value);
  };

  const handleImageUpload = (data) => {
    const file = data[0];
    setFileName(file?.name)
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64String = e.target.result;
        setSignatureImage({
          id: 1,
          base64Data: base64String.split(",")[1],
          contentType: file.type,
        });
      };

      reader.readAsDataURL(file);
    }
  };

  const saveSignature = () => {
    putUserValue({ roleId: role, signature: signatureImage });
  };

  return (
      <>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          {isLoading && <LoadingPanel />}
          <SignatureDowloadTabContainer>
            <TopPanel>
              <SelectField
                name={"Role"}
                label={"Role"}
                data={userRoles}
                onChange={onStatusChange}
                value={role}
                fullWidth
                disabled={basicInfo?.roleId}
              />
              <Button onClick={saveSignature} variant="contained" size="small"  type="submit">
                Download
              </Button>
            </TopPanel>

            <Dropzone onDrop={handleImageUpload}>
              {({getRootProps, getInputProps}) => (
                  <DropzoneArea {...getRootProps()}>
                    <input {...getInputProps()} />
                    {!fileName ? 
                      <>
                        <img src={dropzoneSvg} alt="dropzone"/>
                        <DropzoneTitle>Drag & drop</DropzoneTitle>
                        <DropzoneSubTitle>or</DropzoneSubTitle>
                        <StyledButton component="label" variant="outlined" size="small">
                          Browse Signature Image
                        </StyledButton>
                      </> : 
                      <>
                        <ImageIcon/>
                        {fileName}
                      </>

                    }

                  </DropzoneArea>
              )}
            </Dropzone>
          </SignatureDowloadTabContainer>
        </Box>
      </>
  );
};
