import { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styled from '@emotion/styled';
import { Delete, Edit } from "@mui/icons-material";

const StyledPaper = styled(Paper)(() => ({
    backgroundColor: 'transparent',
    boxShadow: 'none',
  }));
  
  const StyledTableHead = styled(TableHead)(() => ({
    th: {
      whiteSpace: 'nowrap',
      //background: '#fff',
      color: '#4281AB',
      paddingTop: '6px',
      paddingBottom: '8px',
      fontFamily: 'Open Sans',
      fontSize: '12px',
      fontWeight: '600',
      borderBottom: "1px solid #b2cede",
    },
    '&::after': {
      height: '8px',
      display: 'table-row',
      content: '""'
    }
  }));
  
  const StyledTableBody = styled(TableBody)(({ theme }) => ({
    td: {
      color: '#000',
      paddingTop: '5px',
      paddingBottom: '5px',
      fontFamily: 'Open Sans',
      fontSize: '12px',
      fontWeight: '400',
      borderBottom: "none",
    },
    th: {
      paddingTop: '5px',
      paddingBottom: '5px',
      minWidth: '120px',
      fontFamily: 'Open Sans',
      fontSize: '12px',
      fontWeight: '400',
      borderBottom: "none",
      a: {
        color: theme.palette.background.main,
        textDecoration: 'none',
      }
    },
    tr: {
      background: '#fff',
      height: '30px',
      '&:nth-of-type(odd)': {
        background: '#EFF5F8',
      },
    }
  }));

const headCells = [
  {
    id: 'medicationName',
    numeric: false,
    disablePadding: false,
    label: 'Method',
    enableSorting: false
  },
  {
    id: 'medicationDose',
    numeric: false,
    disablePadding: false,
    label: 'Quantity',
    enableSorting: false,
  },
  {
    id: 'medicationEndDate',
    numeric: false,
    disablePadding: false,
    label: 'Dose Type',
    enableSorting: false,
  },
  {
    id: 'medicationEndDate',
    numeric: false,
    disablePadding: false,
    label: 'Time of Day or other directions',
    enableSorting: false,
  },
  {
    id: 'medicationEndDate',
    numeric: false,
    disablePadding: false,
    label: 'Frequency',
    enableSorting: false,
  },
  {
    id: 'controlsPanel',
    numeric: false,
    disablePadding: false,
    enableSorting: false,
  },
];

function EnhancedTableHead() {
  return (
    <StyledTableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding='normal'
            sx={{
              minWidth: headCell.id === 'firstName' ? '250px' : 'auto',
            }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </StyledTableHead>
  );
}

export const AdministrationInstructionsGrid = ({
    values,
    setSelectedAdministrationInstructionsIndex,
    doseType,
    frequency,
    method,
    onDelete,
    medicationIndex,
    medication
}) => {

  const [rows, setRows] = useState([]);

  let originalRows = values

  useEffect(() => {
    if(originalRows) {
      setRows(originalRows)
    }
  },[values, originalRows] );

  const findNameById = (id, array) => {
    const foundItem = array.find(item => item.id === id);
    return foundItem ? foundItem.name : null;
};

  return (
    <Box sx={{ width: '100%'}}>
      <StyledPaper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ 
              minWidth: 750,
              padding: '5px',
            }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
              <EnhancedTableHead
                rowCount={rows.length}
                colSpan={6} 
              />
            <StyledTableBody>
              {rows?.length && rows.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.clientServiceId}
                    sx={{ 
                      cursor: 'pointer',
                      borderBottom: "none",
                    }}
                    padding='normal'
                    border='none'
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      padding='normal'
                      borderBottom='none'
                    >
                      {findNameById(row.clientMedicationMethodId, method)}
                    </TableCell>
                    <TableCell align="left">{row.quantity}</TableCell>
                    <TableCell align="left">{findNameById(row.clientMedicationDoseTypeId, doseType)}</TableCell>
                    <TableCell align="left">{row.timeOfDayOrOtherDirections}</TableCell>
                    <TableCell align="left">{findNameById(row.clientMedicationFrequencyId, frequency)}</TableCell>
                    <TableCell align="left">
                      <Edit
                        sx={{width:'11px', height:'11px', marginRight: '10px'}}
                        onClick={() => setSelectedAdministrationInstructionsIndex(index)}
                      /> 
                      <Delete
                        sx={{width:'11px', height:'11px'}}
                        onClick={() => onDelete(medicationIndex, index, medication)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </StyledTableBody>
          </Table>
        </TableContainer>
      </StyledPaper>
    </Box>
  );
}