import { Box, styled } from "@mui/material";

export const ImgContainer = styled(Box)({
  display: "flex",
  width: "100px",
  height: "100px",
  "& img": {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
});

export const InfoContainer = styled(Box)({
  display: "grid",
  gridTemplateColumns: "auto 1fr",
  gap: "10px",
});

export const Role = styled(Box)({
  fontFamily: 'Open Sans',
  fontSize: '12px',
  fontWeight: '600',
  marginBottom: '15px',
});

export const FieldContainer = styled(Box)({
  fontFamily: 'Open Sans',
  fontSize: '12px',
  fontWeight: '400',
  marginBottom: '15px',
});

export const FieldTitle = styled(Box)({
  color: '#4281AB',
  marginBottom: '4px',
});

export const FieldValue = styled(Box)({
  color: '#000',
  height: '15px',
});

export const SignatureIcon = styled(Box)({
  marginRight: "6px",
})

export const SignatureField = styled(Box)({
  width: "290px",
  height: "120px",
  background: "",
  borderRadius: "4px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
})

export const SignatureControls = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "6px 13px",
  background: "#EFF5F8",
  fontWeight: 600,
  height: "25px",
  width: "100%",
  color: "#BD986A",
})

export const SignatureButton = styled(Box)({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
})

export const SignatureContainer = styled(Box)({
  maxWidth: "288px",
  maxHeight: "90px",
  padding: "10px",
  flex: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
})

export const SignatureImage = styled(Box)({
  width: "100%",
  height: "100%",
})


