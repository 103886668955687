import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    message: '',
}

const userSlice = createSlice({
    name: "errorSlice",
    initialState,
    reducers: {
      setError: (state, action) => {state.message = action.payload},
    }
  });
  
  
  export const { setError } =  userSlice.actions;
  export default userSlice.reducer