import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
import SelectField from "../../../common/SelectFieldForForm";
import InputField from "../../../common/InputField";
import { Delete } from "@mui/icons-material";
import useFetchWithMsal from "../../../../utils/useFetchWithMsal";

export const AdministrationInstructionsField = ({ 
  instructionIndex,
  onDelete,
  index,
  medication,
  setMedicationDetails,
  isLastItem,
  setSelectedAdministrationInstructionsIndex,
  doseType,
  frequency,
  method
}) => {
  const [initialValues, setInitialValues] = useState(null); 

  useEffect(() => {
    if (!initialValues) {
      setInitialValues(medication.administrationInstructions[instructionIndex]);
    }
  }, []);

  const { skip } = useFetchWithMsal();

  const handleCancel = () => {
    if (initialValues) {
      setMedicationDetails(prevDetails => {
        const updatedDetails = JSON.parse(JSON.stringify(prevDetails));
        if (initialValues.newElement === true) {
          updatedDetails[index].administrationInstructions.splice(instructionIndex, 1);
        } else {
          updatedDetails[index].administrationInstructions[instructionIndex] = initialValues;
        }
        return updatedDetails;
      });
    }
    setSelectedAdministrationInstructionsIndex(null);
  };  

  // Остальной код без изменений
  const getSelectData = (name) => {
    switch (name) {
      case "clientMedicationMethodId":
        return method;
      case "clientMedicationDoseTypeId":
        return doseType;
      case "clientMedicationFrequencyId":
        return frequency;
      default:
        return [];
    }
  };

  const handleFieldChange = (fieldName, value) => {
    setMedicationDetails(prevDetails => {
      const updatedDetails = JSON.parse(JSON.stringify(prevDetails));
      updatedDetails[index].administrationInstructions[instructionIndex] = {
        ...updatedDetails[index].administrationInstructions[instructionIndex],
        [fieldName]: value
      };
      return updatedDetails;
    });
  };
  
  const getInputComponent = (item, index, width, height) => {
    if(medication.administrationInstructions[instructionIndex] !== undefined){
    switch (item?.type) {
      case "select":
        return (
          <SelectField
            name={item.name}
            label={item.label}
            data={getSelectData(item.name)}
            value={medication.administrationInstructions[instructionIndex][item.name]}
            setValue={(name, value) => handleFieldChange(name, value)}
            required={item.required}
            width='100%'
            fullWidth
          />
        );
      case "number":
        return (
          <InputField
            name={item?.name}
            label={item?.label}
            value={medication.administrationInstructions[instructionIndex][item.name]}
            setValue={(name, value) => item.maxLength ? 
              value.length <= item.maxLength && handleFieldChange(name, value) :
              handleFieldChange(name, value)
            }
            fullWidth
            width={width}
            height={height}
            required={item.required}
            type='number'
            maxLength={item.maxLength}
            step={item.step}
          />
        );
      default:
        return (
          <InputField
            name={item?.name}
            label={item?.label}
            value={medication.administrationInstructions[instructionIndex][item.name]}
            setValue={(name, value) => handleFieldChange(name, value)}
            fullWidth
            width='100%'
            height={height}
            required={item.required}
            maxLength={item.maxLength}
          />
        );
    }
  }
  };
  

  return (
    <Grid item xs={12}>
      <Grid container xs={12} sx={{
        backgroundColor: "#f8fafb",
        borderRadius: '3px',
        padding: '15px',
      }} gap="10px 20px">
        {/* <Grid item xs={12} sx={{marginBottom: '10px'}}>
          <Box
          ><Typography variant="h6">Administration Instruction {instructionIndex+1}</Typography></Box>
        </Grid> */}
        <Grid container xs={3} gap="10px 30px">
          <Grid item xs={12}>
            {getInputComponent({ name: "clientMedicationMethodId", label: "Method", type: "select", required: true }, index)}
          </Grid>
          <Grid item xs={5}>
            {getInputComponent({ name: "quantity", label: "Quantity", type: "number", step: "0.01", maxLength: 8 }, index)}
          </Grid>
          <Grid item xs={5}>
            {getInputComponent({ name: "clientMedicationDoseTypeId", label: "Dose Type", type: "select", required: true }, index)}
          </Grid>
        </Grid>
        <Grid container xs={8} gap="10px">
          <Grid item xs={12}>
            {getInputComponent({ name: "timeOfDayOrOtherDirections", label: "Time of Day or other directions", type: "text", maxLength: 128 }, index)}
          </Grid>
          <Grid item xs={12}>
            {getInputComponent({ name: "clientMedicationFrequencyId", label: "Frequency", type: "select", required: true }, index, '400px')}
          </Grid>
        </Grid>
        <Grid item xs={12} gap="20px">
          <span
            style={{
              color: '#2E7BA3',
              cursor: 'pointer',
              fontSize: '12px',
              marginRight: '26px',
            }}
            onClick={() => setSelectedAdministrationInstructionsIndex(null)}
          >
            Save
          </span>
          <span
            style={{
              color: '#2E7BA3',
              cursor: 'pointer',
              fontSize: '12px',
            }}
            onClick={handleCancel}
          >
            Cancel
          </span>
        </Grid>
      </Grid>

    </Grid>
  );
};
