import React from "react";
import { ClientInfo } from "../../components/clientInfo/ClientInfo";
import styled from "styled-components";

const ClientInfoContainer = styled.div`
  display: flex;
  padding: 27px 20px;
  max-width: 1400px;
  margin: 0 auto;
`;

export const ClientInfoPage = () => {
  return (
    <ClientInfoContainer>
      <ClientInfo />
    </ClientInfoContainer>
  );
};
