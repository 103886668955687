import React, { useEffect } from "react";
import "./Table.css";
import { TableTab } from "../../components/table/TableTab";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import SplashPage from "../../components/SplashPage";
import { useNavigate } from "react-router-dom";

export const Table = () => {
  const { instance,accounts } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const isAuthenticated = !!accounts.length;
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/home");
    }
  }, [isAuthenticated]);

  return (
    <>
      <AuthenticatedTemplate>
        {activeAccount ? (
          <div className="clientContainer container">
            <TableTab />
          </div>
        ) : null}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <SplashPage />
      </UnauthenticatedTemplate>
    </>
  );
};