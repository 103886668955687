const noteModel = {
  formId: 'noteModel',
  formField: {
    date: {
      name: 'date',
      label: 'Date',
      requiredErrorMsg: 'Date is required',
      type:'date'
    },
    text: {
      name: 'text',
      label: 'Note',
    },
    quickNoteTypeId: {
      name: 'quickNoteTypeId',
      label: 'Type',
      type: 'select'
    },
    client: {
      name: 'client',
      label: 'Client',
      type: 'clientSelect'
    },
    requestStatus: {
      name: 'requestStatus',
      label: 'Request Status',
      type: 'statusSelect'
    },
  }
};
export default noteModel