import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { Badge, Box, Menu, MenuItem, Typography } from "@mui/material";
import { styled } from "@mui/system";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  BadgeContainer,
  NavBar,
  NavigationBarButton,
  NavigationBarContainer,
} from "./NavigationBar.styled";
import { Link } from "react-router-dom";
import { useState } from "react";
import UserIcon from "../../shared/components/UserIcon";
import logo from "../../shared/icons/logo.png";
import { AuthenticatedPage } from "../AuthenticatedPage";
import CalendarModalWrapper from "../calendar/CalendarModalWrapper";
import { b2cPolicies } from "../../authConfig";  // Import the b2cPolicies

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    height: "15px",
    minWidth: "15px",
    padding: "0",
  },
}));
const StyledLink = styled(Link)(({ theme }) => ({
  font: theme.typography.link.font,
  color: theme.palette.primary.contrastText,
  textDecoration: "none",
  alignItems: "center",
  display: "flex",
}));

export const NavigationBar = () => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openCalendar, setOpenCalendar] = useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  };

  const handleLogoutRedirect = () => {
    instance.logoutRedirect();
  };

  const handleProfileEditRedirect = () => {
    // Redirect to the profile editing flow
    instance.loginRedirect({
      authority: b2cPolicies.authorities.editProfile.authority,
    });
  };

  // const openCalendar = () => {
  //   setNotesCalendarOpen(!notesCalendarOpen)
  // }

  const closeCalendar = () => {
    setOpenCalendar(false)
  }

  return (
    <>
      <NavBar>
        <AuthenticatedTemplate>
        <AuthenticatedPage>
          <NavigationBarContainer>
            <StyledLink to="/">
              <img src={logo} alt={"logo"} />
            </StyledLink>

            <BadgeContainer onClick={() => setOpenCalendar(true)}>
              <StyledBadge badgeContent={1} overlap="circular" color="info">
                <NotificationsIcon sx={{ color: "#fff" }} fontSize="small" />
              </StyledBadge>
            </BadgeContainer>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginRight: "33px",
              }}
            >             
            </Box>
              <NavigationBarButton onClick={handleClick}>
                <UserIcon />
                <Typography>
                  Welcome{" "}
                  <strong>
                    {activeAccount?.idTokenClaims?.family_name}{" "}
                    {activeAccount?.idTokenClaims?.given_name}
                  </strong>
                </Typography>
              </NavigationBarButton>
              <Menu
                id="menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                sx={{ width: "100%" }}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                  sx: { width: anchorEl && anchorEl.offsetWidth },
                }}
              >
                <MenuItem onClick={handleClose} component={"a"} href={'#/signature'}>
                  Profile
                </MenuItem>
                <MenuItem onClick={handleLogoutRedirect}>Sign out</MenuItem>
              </Menu>
          </NavigationBarContainer>
        </AuthenticatedPage>

        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <NavigationBarContainer>
            <StyledLink to="/">
              <img src={logo} alt={"logo"} />
            </StyledLink>
            <NavigationBarButton
              onClick={handleLoginRedirect}
              variant="outlined"
            >
              Sign in
            </NavigationBarButton>
          </NavigationBarContainer>
        </UnauthenticatedTemplate>
      </NavBar>
      {(openCalendar) && 
        <CalendarModalWrapper
          open={setOpenCalendar}
          onClose={closeCalendar}
        />
      }
    </>
  );
};
