import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  status: 0,
  counselor: 0,
  housing: 0,
}

const filterSlice = createSlice({
    name: "filterSlice",
    initialState,
    reducers: {
      setStatus: (state, action) => {state.status = action.payload},
      setCounselor: (state, action) => {state.counselor = action.payload},
      setHousing: (state, action) => {state.housing = action.payload},
    }
  });
  
  
  export const { setStatus, setCounselor, setHousing} =  filterSlice.actions;
  export default filterSlice.reducer