import React from "react";
import "./UserSchedulePage.css";
import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import UserWorkScheduleGrid from "../../components/userInfo/UserWorkSchedule";

export const UserSchedulePage = () => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  return (
    <AuthenticatedTemplate>
      {activeAccount ? (
        <div className="clientContainer container">
          <UserWorkScheduleGrid />
        </div>
      ) : null}
    </AuthenticatedTemplate>
  );
};
