import React, { useState, useEffect } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { InputLabel } from "@mui/material";
import dayjs from "dayjs";

const DatePickerField = (props) => {
  const { value, name, setValue, error,label, maxDate, height, width, required, ...rest } = props;

  const [selectedDate, setSelectedDate] = useState(null);

  const [cleared, setCleared] = React.useState(false);

  useEffect(() => {
    if (cleared) {
      const timeout = setTimeout(() => {
        setCleared(false);
      }, 1500);

      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [cleared]);

  useEffect(() => {
    if (value) {
      const date = new Date(value);
      setSelectedDate(date);
    }
  }, [value]);

  const onChange = (date) => {
    if (date) {
      setSelectedDate(date);
      try {
        const ISODateString = date.toISOString();
        setValue(name,ISODateString);
      } catch (error) {
        setValue(name,date);
      }
    } else {
      setValue(name,date);
    }
  };

  const renderHelperText = () => {
    if (error ) {
      return error;
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
     {label && <InputLabel htmlFor="DatePicker-input">{label}{required && '*'}</InputLabel>}
      <DemoContainer
        components={["DatePicker"]}
        sx={{ padding: "0", overflow: "visible" }}
      >
        <DatePicker
          {...rest}
          id={label}
          maxDate={maxDate && dayjs(maxDate)}
          value={selectedDate ? dayjs(selectedDate) : null}
          onChange={onChange}
          sx={{ height: "auto", maxWidth: width || "290px", width:'100%', minWidth: '10px', "& .MuiInputBase-root": { border: error ? "1px solid red" : 'none' }}}
          slotProps={{
            textField: {
              size: "small",
              error: !!error ,
              helperText: renderHelperText(),
              minWidth: '10px',
              required: required
            },
            field: { clearable: true, onClear: () => setCleared(true) },
          }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};
export default React.memo(DatePickerField);
