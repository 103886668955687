import React, { useState, useEffect } from "react";
import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { InputLabel } from "@mui/material";
import dayjs from "dayjs";
import moment from "moment";

const DateTimePickerField = (props) => {
  const { value, name, setValue, error,label, maxDate, height, width, ...rest } = props;

  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    if (value) {
      const date = new Date(value);
      setSelectedDate(date);
    }
  }, [value]);

  const onChange = (date) => {
    if (date) {
      setSelectedDate(date);
      try {
        const ISODateString =moment(new Date(date)).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
        setValue(name,ISODateString);
      } catch (error) {
        setValue(name,date);
      }
    } else {
      setValue(name,date);
    }
  };

  const renderHelperText = () => {
    if (error ) {
      return error;
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
     {label && <InputLabel htmlFor="DatePicker-input">{label}</InputLabel>}
      <DemoContainer
        components={["DatePicker"]}
        sx={{ padding: "0", overflow: "visible" }}
      >
        <DateTimePicker
          {...rest}
          id={label}
          value={selectedDate ? dayjs(selectedDate) : null}
          onChange={onChange}
          sx={{ height: "auto", maxWidth: width || "290px", width:'100%', minWidth:'190px !important' }}
          slotProps={{
            textField: {
              size: "small",
              error: !!error ,
              helperText: renderHelperText(),
            },
          }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};
export default React.memo(DateTimePickerField);
